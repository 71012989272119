import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';

import { CancelIcon, EditIcon, SaveIcon } from '@common/ui/icons';
import { useProductStore } from '@features/ProductView/context';
import { TextField } from '@mui/material';

import { KeyValue } from '../KeyValue';
import { ProductDimensions } from '../ProductDimensions';
import { Section } from '../Section';
import classes from './FeaturesBlock.module.css';
import { useQuantity } from './hooks';

interface EditButtonsProps {
  isEditMode: boolean;
  isBlocked?: boolean;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickEdit: () => void;
}

const EditButtons: FC<EditButtonsProps> = ({
  isEditMode,
  isBlocked = false,
  onClickCancel,
  onClickEdit,
  onClickSave,
}) => {
  return isEditMode ? (
    <React.Fragment>
      <SaveIcon
        title='Сохранить изменения'
        onClick={onClickSave}
        disabled={isBlocked}
      />
      <CancelIcon
        title='Отменить изменения'
        onClick={onClickCancel}
        disabled={isBlocked}
      />
    </React.Fragment>
  ) : (
    <EditIcon
      title='Изменить описание'
      onClick={onClickEdit}
      disabled={isBlocked}
    />
  );
};

export const FeaturesBlock = observer(() => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const quantityInput = useQuantity();

  const store = useProductStore();

  const handleEdit = () => {
    if (isBlocked) return;

    setIsEditMode(true);
  };

  const handleSave = async () => {
    if (isBlocked) return;

    try {
      setIsBlocked(true);
      await store.saveChanges();
    } catch {
    } finally {
      setIsEditMode(false);
      setIsBlocked(false);
    }
  };

  const handleCancel = async () => {
    if (isBlocked) return;

    try {
      setIsBlocked(true);
      await store.revertChanges();
    } catch {
    } finally {
      setIsEditMode(false);
      setIsBlocked(false);
    }
  };

  if (!store.product) return null;

  return (
    <Section
      title={`Характеристики ${store.product.name}`}
      titleMenu={
        <EditButtons
          isEditMode={isEditMode}
          isBlocked={isBlocked}
          onClickSave={handleSave}
          onClickCancel={handleCancel}
          onClickEdit={handleEdit}
        />
      }
    >
      <div className={classes.container}>
        <div className={classes.blockTitle}>Общие параметры</div>
        {store.product.attributes && store.product.attributes.length ? (
          store.product.attributes.map((feature, index) => (
            <KeyValue key={index} name={feature.key}>
              {feature.value}
            </KeyValue>
          ))
        ) : (
          <div className={classes.emptyFeature}>&#8211;</div>
        )}
        <div className={classes.blockTitle}>Размеры товара</div>
        <ProductDimensions isEditMode={isEditMode} />
        <div className={classes.blockTitle}>Количество товара</div>
        <KeyValue name='шт.'>
          {isEditMode ? (
            <TextField
              {...quantityInput.bind}
              size='small'
              inputProps={{ inputMode: 'decimal', step: 'any' }}
            />
          ) : (
            store.product.quantity
          )}
        </KeyValue>
      </div>
    </Section>
  );
});

import { observer } from 'mobx-react';
import React from 'react';

import TextField from '@mui/material/TextField';

import { useFilterStore } from '../context';

export const SearchSupplierSku = observer(() => {
  const store = useFilterStore();

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    store.setSupplierSku(e.target.value);
  };

  return (
    <TextField
      variant='standard'
      value={store.supplierSku}
      onChange={handleChange}
      fullWidth
      autoComplete='off'
    />
  );
});

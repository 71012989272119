import { AxiosResponse } from 'axios';

import { $authApi } from '@common/http';
import {
  ProductConfigurationDto,
  ProductDto,
  ProductStatusEnum,
} from '@common/models/Shop/Product';

import {
  ProductFilterDto,
  ProductListDto,
  ProductPriceFilterDto,
  ProductPriceListDto,
  ProductStatusCountDto,
  UpdateDescriptionDto,
  UpdateDimensionsDto,
  UpdateNameDto,
  UpdateQuantityDto,
  UpdateStatusDto,
  UpdateWarehouseStocksDto,
} from './dto';
import { ProductRoutes } from './routes';

export class ProductApiService {
  static async getProductById(id: number) {
    return $authApi.get<ProductDto>(`${ProductRoutes.ROOT}/${id}`);
  }

  static async getProductList(params: ProductFilterDto) {
    return $authApi.get<
      ProductListDto,
      AxiosResponse<ProductListDto, ProductFilterDto>,
      ProductFilterDto
    >(`${ProductRoutes.ROOT}`, {
      params: {
        ...params,
        categoryIds:
          params.categoryIds && params.categoryIds.length
            ? params.categoryIds?.join(',')
            : undefined,
      },
    });
  }

  static async getProductPrices(body: ProductPriceFilterDto) {
    return $authApi.post<
      ProductPriceListDto,
      AxiosResponse<ProductPriceListDto, ProductPriceFilterDto>,
      ProductPriceFilterDto
    >(`${ProductRoutes.PRICE}`, body);
  }

  static async getProductCountByStatus(body: ProductPriceFilterDto) {
    return $authApi.post<
      ProductStatusCountDto,
      AxiosResponse<ProductStatusCountDto, ProductPriceFilterDto>,
      ProductPriceFilterDto
    >(`${ProductRoutes.PRICE}/count`, body);
  }

  static async updateName(dto: UpdateNameDto) {
    return $authApi.post(`${ProductRoutes.NAME}`, dto);
  }

  static async updateDesc(dto: UpdateDescriptionDto) {
    return $authApi.post(`${ProductRoutes.DESCRIPTION}`, dto);
  }

  static async updateDimensions(dto: UpdateDimensionsDto) {
    return $authApi.post(`${ProductRoutes.DIMENSIONS}`, dto);
  }

  static async updateWarehouseStocks(dto: UpdateWarehouseStocksDto) {
    return $authApi.post(`${ProductRoutes.UPDATE_WAREHOUSE_STOCKS}`, dto);
  }

  static async getConfiguration(configuration_id: number) {
    return $authApi.get<ProductConfigurationDto>(
      `${ProductRoutes.CONFIGURATION}/${configuration_id}`
    );
  }

  static async getDefaultConfiguration(product_id: number) {
    return $authApi.get<ProductConfigurationDto>(
      `${ProductRoutes.CONFIGURATION}/${product_id}/default`
    );
  }

  static async getStatus(configuration_id: number) {
    return $authApi.get<ProductStatusEnum>(
      `${ProductRoutes.STATUS}/${configuration_id}`
    );
  }

  static async setStatus(dto: UpdateStatusDto) {
    return $authApi.post(`${ProductRoutes.STATUS}`, dto);
  }

  static async getQuantity(product_id: number) {
    return $authApi.get<number>(`${ProductRoutes.QUANTITY}/${product_id}`);
  }

  static async setQuantity(dto: UpdateQuantityDto) {
    return $authApi.post(`${ProductRoutes.QUANTITY}`, dto);
  }

  static async acceptPrices(items: number[]) {
    return $authApi.post(`${ProductRoutes.PRICE}/accept`, { items });
  }

  static async rejectPrices(items: number[]) {
    return $authApi.post(`${ProductRoutes.PRICE}/reject`, { items });
  }
}

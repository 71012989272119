import { observer } from 'mobx-react';
import React, { ChangeEvent, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { GLOBAL_TITLE } from '@common/constants';
import DoneIcon from '@mui/icons-material/Done';
import { CircularProgress, IconButton } from '@mui/material';

import { ContextProvider, UploadDataContext } from './context';
import classes from './UploadData.module.css';
import UploadIcon from '@mui/icons-material/Upload';
const Container = observer(() => {
  const store = useContext(UploadDataContext);

  const pageTitle = useMemo(() => `Загрузить остатки | ${GLOBAL_TITLE}`, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    store.setFile(e.target.files?.item(0) ?? null);
  };

  const handleClick = async () => {
    await store.uploadIgrotradeStocks();
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <h2>Загрузить остатки</h2>
      <div className={classes.category}>
        <div>Игротрейд:</div>
        <input
          className={classes.input}
          accept='.xls,.xlsx'
          type='file'
          onChange={handleChange}
          disabled={store.loading}
        />
        {store.loading ? (
          <CircularProgress />
        ) : store.loaded ? (
          <DoneIcon />
        ) : (
          <IconButton onClick={handleClick}>
            <UploadIcon />
          </IconButton>
        )}
        {store.error ? (
          <div className={classes.error}>{store.error}</div>
        ) : null}
      </div>
    </div>
  );
});

export const UploadData = observer(() => (
  <ContextProvider>
    <Container />
  </ContextProvider>
));

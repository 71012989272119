import { AxiosResponse } from 'axios';

import { $authApi } from '@common/http';

import {
  ChangeNameDto,
  CreateShopRequest,
  CreateShopResponse,
  SetLastSelectedShopDto,
  SetMarketplaceStatusDto,
  ShopDto,
} from './dto';

export class ShopApiService {
  static async createShop(dto: CreateShopRequest) {
    return $authApi.post<
      CreateShopResponse,
      AxiosResponse<CreateShopResponse>,
      CreateShopRequest
    >(`/shop`, dto);
  }

  static async getShopList() {
    return $authApi.get<ShopDto[]>(`/shop`);
  }

  static async getShopById(id: number) {
    return $authApi.get<ShopDto>(`/shop/${id}`);
  }

  static async deleteShop(id: number) {
    return $authApi.delete(`/shop/${id}`);
  }

  static async changeShopName(dto: ChangeNameDto) {
    return $authApi.post<void, AxiosResponse<void>, ChangeNameDto>(
      `/shop/change-name`,
      dto
    );
  }

  static async getLastSelectedShop(userId: number) {
    return $authApi.get<number | null>(`/shop/remember/${userId}`);
  }

  static async setLastSelectedShop(dto: SetLastSelectedShopDto) {
    return $authApi.post<void, AxiosResponse<void>, SetLastSelectedShopDto>(
      `/shop/remember`,
      dto
    );
  }

  static async setUpdateStocksState(shopId: number, value: boolean) {
    return $authApi.post(`/shop/stocks`, {
      shopId,
      value,
    });
  }

  static async updateStocks() {
    return $authApi.post(`/shop/stocks/update`);
  }

  static async nullStocks() {
    return $authApi.post(`/shop/stocks/null`);
  }

  static async setUpdatePricesState(shopId: number, value: boolean) {
    return $authApi.post(`/shop/prices`, {
      shopId,
      value,
    });
  }

  static async updatePrices() {
    return $authApi.post(`/shop/prices/update`);
  }

  static async getMarketplaceStatus(shopId: number, marketplaceId: number) {
    return $authApi.get<boolean>(
      `/shop/${shopId}/marketplace/${marketplaceId}`
    );
  }

  static async setMarketplaceStatus(dto: SetMarketplaceStatusDto) {
    return $authApi.post<void, AxiosResponse<void>, SetMarketplaceStatusDto>(
      `/shop/marketplace`,
      dto
    );
  }
}

export enum ProductRoutes {
  ROOT = '/product',
  NAME = '/product/name',
  CONFIGURATION = '/product/configuration',
  PRICE = '/product/prices',
  DESCRIPTION = '/product/description',
  QUANTITY = '/product/quantity',
  STATUS = '/product/status',
  DIMENSIONS = '/product/dimensions',
  UPDATE_WAREHOUSE_STOCKS = '/product/update-warehouse-stocks',
}

import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthRoutes } from '@common/api/Auth';
import { NotFound } from '@common/components/NotFound';
import { ProtectedRoute } from '@common/components/ProtectedRoute';
import { AddProductForm } from '@features/AddProductForm';
import { PickList } from '@features/PickList';
import { PostingList } from '@features/PostingList';
import { PriceList } from '@features/PriceList';
import { PriceReviewList } from '@features/PriceReviewList';
import { ProductList } from '@features/ProductList';
import { ProductView } from '@features/ProductView';
import { ShopList } from '@features/ShopList';
import { ShopView } from '@features/ShopView';
import { UploadData } from '@features/UploadData';
import { WBCardForm } from '@features/WBCardForm';
import { YandexIntegration } from '@features/YandexIntegration';
import { AuthPage } from '@pages/AuthPage';
import { MainPage } from '@pages/MainPage';
import { ClientPickLists } from '@features/ClientPickLists';

const AppRouter = () => {
  return (
    <Routes>
      <Route path={`${AuthRoutes.AUTH_ROUTE}/*`} element={<AuthPage />} />
      <Route path='/' element={<ProtectedRoute />}>
        <Route path='/' element={<MainPage />}>
          <Route index element={<Navigate to={'/products'} replace />} />
          <Route path='products'>
            <Route index element={<ProductList />} />
            <Route path=':productId'>
              <Route index element={<ProductView isEditMode={false} />} />
              <Route path='edit' element={<ProductView isEditMode={true} />} />
            </Route>
          </Route>
          <Route path='postings'>
            <Route index element={<PostingList />} />
            <Route path='list' element={<PickList />} />
          </Route>
          <Route path='prices'>
            <Route index element={<PriceList />} />
          </Route>
          <Route path='shops'>
            <Route index element={<ShopList />} />
            <Route path=':shopId'>
              <Route index element={<ShopView />} />
            </Route>
          </Route>
          <Route path='ozon'>
            <Route index element={<AddProductForm />} />
          </Route>
          <Route path='import'>
            <Route index element={<UploadData />} />
          </Route>
          <Route path='yandex-market'>
            <Route index element={<YandexIntegration />} />
          </Route>
          {/* <Route path='wb'>
            <Route path='card'>
              <Route path='create' element={<WBCardForm />} />
            </Route>
          </Route> */}
          {/* <Route path='price-review'>
            <Route index element={<PriceReviewList />} />
          </Route> */}
          <Route path='client'>
            <Route path='postings' element={<ClientPickLists />} />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default observer(AppRouter);

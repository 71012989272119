import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Autocomplete, TextField } from '@mui/material';

import { SmartInputProps } from '../../SmartInput';
import { useOptions } from './hooks';

export const AutocompleteInput: FC<Required<SmartInputProps>> = observer(
  ({ schema }) => {
    const options = useOptions(schema);

    return (
      <Autocomplete
        {...options.bind}
        autoSelect
        autoHighlight
        clearOnEscape
        multiple={schema.isCollection}
        fullWidth
        getOptionLabel={(option) => option.value}
        renderInput={(params) => <TextField {...params} label={schema.name} />}
      />
    );
  }
);

import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { ShopListContext } from '@features/ShopList/context';
import { Paper } from '@mui/material';

import { ShopItem } from '../ShopItem';
import classes from './ShopList.module.css';

export const ShopList = observer(() => {
  const store = useContext(ShopListContext);

  return (
    <Paper className={classes.container}>
      {store.shops.length
        ? store.shops.map((item) => <ShopItem key={item.id} item={item} />)
        : 'Ни одного магазина не создано.'}
    </Paper>
  );
});

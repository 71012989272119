import { useProductStore } from '@features/ProductView/context';

export const useNameInput = () => {
  const store = useProductStore();

  return {
    value: store.copy?.name || '',
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      store.changeName(e.target.value),
  };
};

import React from 'react';

import { AddProductStore } from '../store';

const store = new AddProductStore();

export const AddProductFormContext =
  React.createContext<AddProductStore>(store);

type Props = {
  children?: React.ReactNode;
};

export const ContextProvider: React.FC<Props> = ({ children }) => (
  <AddProductFormContext.Provider value={store}>
    {children}
  </AddProductFormContext.Provider>
);

import { makeAutoObservable } from 'mobx';

import { ProductListItemDto } from '@common/api/Shop/Product';

import { CategoriesStore } from './CategoriesStore';
import { FilterStore } from './FilterStore';
import { SuppliersStore } from './SuppliersStore';

export class ProductListStore {
  private _suppliersStore: SuppliersStore;
  private _categoriesStore: CategoriesStore;
  private _filterStore: FilterStore;

  private _products: ProductListItemDto[] = [];

  private _selectedProductIds: number[] = [];

  get products(): ProductListItemDto[] {
    return this._products;
  }

  get selectedProductIds(): number[] {
    return this._selectedProductIds;
  }

  get suppliersStore(): SuppliersStore {
    return this._suppliersStore;
  }

  get categoriesStore(): CategoriesStore {
    return this._categoriesStore;
  }

  get filterStore(): FilterStore {
    return this._filterStore;
  }

  constructor() {
    this._suppliersStore = new SuppliersStore();
    this._categoriesStore = new CategoriesStore(this._suppliersStore);
    this._filterStore = new FilterStore();

    makeAutoObservable(this);
  }

  updateProduct(value: ProductListItemDto) {
    const idx = this._products.findIndex(
      (x) => x.configuration_id === value.configuration_id
    );
    this._products[idx] = value;
  }

  setProducts(value: ProductListItemDto[]) {
    this._products = value;
  }

  setSelectedProductIds(value: number[]) {
    this._selectedProductIds = value;
  }
}

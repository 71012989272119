// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Shop_container__L91Fr {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n  gap: 1rem;\n}\n\n.Shop_container__L91Fr h2 {\n  margin: 0;\n  padding-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ShopList/Shop.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,SAAS;EACT,oBAAoB;AACtB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n  gap: 1rem;\n}\n\n.container h2 {\n  margin: 0;\n  padding-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Shop_container__L91Fr"
};
export default ___CSS_LOADER_EXPORT___;

import { makeAutoObservable } from 'mobx';

import { PickListStoreOzon } from './PickListStoreOzon';
import { PickListStoreWB } from './PickListStoreWB';
import { PickListStoreYandex } from './PickListStoreYandex';

export type PickListStatus = 'all' | 'today' | 'tomorrow' | 'later';
export type MarketPlaceType = 'yandex' | 'ozon' | 'wb';

export class PickListStore {
  private _ozon: PickListStoreOzon;
  private _yandex: PickListStoreYandex;
  private _wb: PickListStoreWB;

  private _selectedMarketPlace: MarketPlaceType = 'ozon';
  private _shopId: number | null = null;

  get shopId() {
    return this._shopId;
  }

  get selectedMarketPlace() {
    return this._selectedMarketPlace;
  }

  get ozon() {
    return this._ozon;
  }
  get yandex() {
    return this._yandex;
  }
  get wb() {
    return this._wb;
  }

  constructor() {
    this._ozon = new PickListStoreOzon(this);
    this._yandex = new PickListStoreYandex(this);
    this._wb = new PickListStoreWB(this);

    makeAutoObservable(this);
  }

  setShopId(value: number | null) {
    this._shopId = value;
  }

  setSelectedMarketPlace(value: MarketPlaceType) {
    this._selectedMarketPlace = value;
  }
}

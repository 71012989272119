import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { GLOBAL_TITLE } from '@common/constants';
import { StoreContext } from '@common/context';

import { DescriptionBlock } from './components/DescriptionBlock';
import { FeaturesBlock } from './components/FeaturesBlock';
import { HeaderBlock } from './components/HeaderBlock';
import { PriceHistoryBlock } from './components/PriceHistoryBlock';
import { SupplierDataBlock } from './components/SupplierDataBlock';
import { TabData, TabPanel, TabsBlock } from './components/TabsBlock';
import classes from './Container.module.css';
import { useProductStore } from './context';

type ProductProps = {
  isEditMode: boolean;
};

const tabs: TabData[] = [
  {
    label: 'Характеристики',
    component: (
      <React.Fragment>
        <FeaturesBlock />
        <DescriptionBlock />
      </React.Fragment>
    ),
  },
  {
    label: 'Данные от поставщика',
    component: <SupplierDataBlock />,
  },
];

const Container: React.FC<ProductProps> = observer(({ isEditMode }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const rootStore = useContext(StoreContext).shops;
  const store = useProductStore();
  const params = useParams();

  const pageTitle = useMemo(() => {
    if (!store.product?.name) return `${GLOBAL_TITLE}`;

    return `${store.product.name} | ${GLOBAL_TITLE}`;
  }, [store.product?.name]);

  const fetchProduct = async () => {
    const productId = Number(params.productId);

    if (isNaN(productId)) return;

    await store.fetchProduct(productId);
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  useEffect(() => {
    if (!store.product) return;
    if (rootStore.selectedShop === null) return;
  }, [rootStore.selectedShop, store.product?.id]);

  if (!store.product) return null;

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.headerBlock}>
          <HeaderBlock />
        </div>
        <div className={classes.sidebar}>
          <TabsBlock value={currentTab} onChange={setCurrentTab} tabs={tabs} />
          <PriceHistoryBlock />
        </div>
        <div className={classes.contentBlock}>
          {tabs.map(({ component }, index) => (
            <TabPanel key={index} index={index} value={currentTab}>
              {component}
            </TabPanel>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
});

export default Container;

import { observer } from 'mobx-react';
import React, { FC, useContext } from 'react';

import { ShopDto } from '@common/api/Shop';
import { MarketplaceIcons } from '@common/constants';
import { ShopListContext } from '@features/ShopList/context';
import { ListItemButton } from '@mui/material';

import classes from './ShopItem.module.css';
import { useNavigate } from 'react-router-dom';

type ShopItemProps = {
  item: ShopDto;
};

export const ShopItem: FC<ShopItemProps> = observer(({ item }) => {
  const navigate = useNavigate();
  const store = useContext(ShopListContext);

  const handleClick = () => {
    navigate({ pathname: `/shops/${item.id}` });
  };

  return (
    <div className={classes.container}>
      <ListItemButton onClick={handleClick}>
        <div className={classes['item-container']}>
          <div>{item.name}</div>
          {item.marketplaces
            .filter((x) => x.is_enabled)
            .map((x) => MarketplaceIcons.find((y) => y.id === x.id)!)
            .filter((x) => x)
            .map((x) => (
              <img
                key={x.id}
                className={classes.badge}
                src={x.icon}
                title={x.label}
              />
            ))}
        </div>
      </ListItemButton>
    </div>
  );
});

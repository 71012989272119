import { observer } from 'mobx-react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from './useAuth';

const ProtectedRoute = () => {
  const { auth } = useAuth();

  if (!auth.isAuth && !auth.user) return null;

  return <Outlet />;
};

export default observer(ProtectedRoute);

import { makeAutoObservable } from 'mobx';

import {
  OzonCredentialsDto,
  OzonShopApiService,
  OzonShopDto,
  OzonUnlinkDto,
} from '@common/api/Ozon/Shop';
import { ShopApiService } from '@common/api/Shop';
import { MarketplaceEnum } from '@common/constants';

export class OzonStore {
  private _data: OzonShopDto | null = null;

  get data() {
    return this._data;
  }

  constructor() {
    makeAutoObservable(this);
  }

  setData(value: OzonShopDto) {
    this._data = value;
  }

  async fetchShopData(shopId: number) {
    try {
      const { data } = await OzonShopApiService.getShopInfo(shopId);

      this.setData(data);
    } catch (error) {}
  }

  async linkToShop(dto: OzonCredentialsDto) {
    try {
      await OzonShopApiService.linkToShop(dto);

      await this.fetchShopData(dto.shopId);
    } catch (error) {}
  }

  async unlinkFromShop(dto: OzonUnlinkDto) {
    try {
      await OzonShopApiService.unlinkFromShop(dto);

      await this.fetchShopData(dto.shopId);
    } catch (error) {}
  }

  async changeStatus(shop_id: number, is_enabled: boolean) {
    try {
      await ShopApiService.setMarketplaceStatus({
        shop_id,
        marketplace_id: MarketplaceEnum.OZON,
        is_enabled,
      });

      await this.fetchShopData(shop_id);
    } catch (error) {}
  }
}

import React, { useContext } from 'react';

import { ProductListStore } from './store';

const store = new ProductListStore();

const ProductListContext = React.createContext<ProductListStore>(store);

type Props = {
  children?: React.ReactNode;
};

export const ContextProvider: React.FC<Props> = ({ children }) => (
  <ProductListContext.Provider value={store}>
    {children}
  </ProductListContext.Provider>
);

export const useProductListStore = () => useContext(ProductListContext);
export const useSuppliersStore = () =>
  useContext(ProductListContext).suppliersStore;
export const useCategoriesStore = () =>
  useContext(ProductListContext).categoriesStore;
export const useFilterStore = () => useContext(ProductListContext).filterStore;

import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { ProductFilterDto } from '@common/api/Shop/Product';

import { useProductListStore } from '../context';

export const useFilterHistory = () => {
  const store = useProductListStore();

  const navigate = useNavigate();
  const location = useLocation();

  const parse = (): ProductFilterDto => {
    const params = new URLSearchParams(location.search);

    return {
      supplierId:
        params.get('supplierId') && !isNaN(Number(params.get('supplierId')))
          ? Number(params.get('supplierId'))
          : undefined,
      categoryIds: params.get('categoryIds')
        ? params
            .get('categoryIds')
            ?.split(',')
            .map((x) => Number(x))
        : undefined,
      status:
        params.get('status') && !isNaN(Number(params.get('status')))
          ? Number(params.get('status'))
          : undefined,
      vendorIds: undefined,
      sku: params.get('sku') ?? undefined,
      name: params.get('name') ?? undefined,
      supplierSku: params.get('supplierSku') ?? undefined,
      priceFrom:
        params.get('priceFrom') && !isNaN(Number(params.get('priceFrom')))
          ? Number(params.get('priceFrom'))
          : undefined,
      priceTo:
        params.get('priceTo') && !isNaN(Number(params.get('priceTo')))
          ? Number(params.get('priceTo'))
          : undefined,
      isAvailable: params.get('isAvailable') === 'true' || undefined,
      orderBy: (params.get('orderBy') || 'id') as any,
      order: (params.get('order') || 'asc') as any,
      page: Number(params.get('page') || '1'),
    };
  };

  const filterNonNull = (obj: Object) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([k, v]) => v && v !== 'false')
    );
  };

  const updateParams = (filter: ProductFilterDto) => {
    const params = createSearchParams(
      filterNonNull({
        page: filter.page?.toString(),
        orderBy: filter.orderBy,
        order: filter.order,
        supplierId: filter.supplierId?.toString(),
        categoryIds: filter.categoryIds?.join(','),
        sku: filter.sku,
        name: filter.name,
        supplierSku: filter.supplierSku,
        status: filter.status?.toString(),
        isAvailable: filter.isAvailable?.toString(),
        priceFrom: filter.priceFrom?.toString(),
        priceTo: filter.priceTo?.toString(),
      })
    );

    navigate({ search: params.toString() });
  };

  useEffect(() => {
    const params = parse();

    if (params.supplierId)
      store.suppliersStore.selectSupplierId(params.supplierId);
    if (params.categoryIds)
      store.categoriesStore.setSelectedCategoryIds(params.categoryIds);
    if (params.isAvailable)
      store.filterStore.setIsAvailable(params.isAvailable);
    if (params.sku) store.filterStore.setSku(params.sku);
    if (params.name) store.filterStore.setName(params.name);
    if (params.supplierSku)
      store.filterStore.setSupplierSku(params.supplierSku);
    if (params.priceFrom !== undefined)
      store.filterStore.setPriceFrom(params.priceFrom);
    if (params.priceTo !== undefined)
      store.filterStore.setPriceTo(params.priceTo);
    if (params.status) store.filterStore.setStatus(params.status);
  }, []);

  return {
    parse,
    updateParams,
  };
};

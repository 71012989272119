import React, { useContext } from 'react';
import { ProductStore } from '../store';

const store = new ProductStore();

const ProductListContext = React.createContext<{
  store: ProductStore;
}>({ store });

type Props = {
  children?: React.ReactNode;
};

export const ContextProvider: React.FC<Props> = ({ children }) => (
  <ProductListContext.Provider value={{ store }}>
    {children}
  </ProductListContext.Provider>
);

export const useProductStore = () => useContext(ProductListContext).store;

import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { GLOBAL_TITLE } from '@common/constants';

import { CreateShop } from './components/CreateShop';
import { ShopList as ShopListComponent } from './components/ShopList';
import { ContextProvider, ShopListContext } from './context';
import classes from './Shop.module.css';

const Container = observer(() => {
  const store = useContext(ShopListContext);

  const pageTitle = useMemo(() => `Магазины | ${GLOBAL_TITLE}`, []);

  useEffect(() => {
    const wrapper = async () => await store.loadShopList();
    wrapper();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className={classes.container}>
        <h2>Список магазинов</h2>
        <ShopListComponent />
        <CreateShop />
      </div>
    </div>
  );
});

export const ShopList = observer(() => (
  <ContextProvider>
    <Container />
  </ContextProvider>
));

import { makeAutoObservable } from 'mobx';

import { PickListItemDto } from '@common/api/ClientFeatures';

import PickListService from '../service';
import { PickListStatus, PickListStore } from './PickListStore';

export class PickListStoreOzon {
  protected _root: PickListStore;
  private _postings: PickListItemDto[] = [];
  private _status: PickListStatus = 'today';

  get postings(): PickListItemDto[] {
    return this._postings;
  }
  get status(): PickListStatus {
    return this._status;
  }

  setPostings(values: PickListItemDto[]): void {
    this._postings = values;
  }
  setStatus(status: PickListStatus): void {
    this._status = status;
  }

  constructor(root: PickListStore) {
    this._root = root;

    makeAutoObservable(this);
  }

  async fetchPickList() {
    if (!this._root.shopId) return;

    let hasNext = false;
    let page = 1;
    this.setPostings([]);

    try {
      do {
        const { data } = await PickListService.getPickListOzon({
          shopId: this._root.shopId,
          page,
        });

        hasNext = data.hasMore;
        page += 1;
        this.setPostings([...this.postings, ...data.items]);
      } while (hasNext);
    } catch (error) {}
  }
}

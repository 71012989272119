// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YandexBlock_container__NcSN5 {\n  display: grid;\n  grid-template-columns: minmax(max-content, 35%) 1fr;\n  grid-auto-flow: row dense;\n\n  gap: 0.5rem 0.75rem;\n}\n\n.YandexBlock_block-title__WlSX3 {\n  grid-column: 1 / 3;\n  padding: 0.5rem 0 0;\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n\n  text-transform: capitalize;\n  font-size: 16;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ShopView/components/YandexBlock/YandexBlock.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mDAAmD;EACnD,yBAAyB;;EAEzB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;;EAEnB,0BAA0B;EAC1B,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: minmax(max-content, 35%) 1fr;\n  grid-auto-flow: row dense;\n\n  gap: 0.5rem 0.75rem;\n}\n\n.block-title {\n  grid-column: 1 / 3;\n  padding: 0.5rem 0 0;\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n\n  text-transform: capitalize;\n  font-size: 16;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "YandexBlock_container__NcSN5",
	"block-title": "YandexBlock_block-title__WlSX3"
};
export default ___CSS_LOADER_EXPORT___;

import { AxiosResponse } from 'axios';

import { $authApi } from '@common/http';

import {
  ClientShopDto,
  GetPickListRequest,
  OzonWarehouseDto,
  PickListDto,
} from './dto';

export class ClientFeaturesApiService {
  static async getClientShops() {
    return $authApi.get<ClientShopDto[]>(`/client-features/shops`);
  }

  static async getPickList(dto: GetPickListRequest) {
    return $authApi.post<
      PickListDto,
      AxiosResponse<PickListDto>,
      GetPickListRequest
    >(`/client-features/pick-list`, dto);
  }

  static async getWarehouses(id: number) {
    return $authApi.get<OzonWarehouseDto[]>(
      `/client-features/warehouses/${id}`
    );
  }
}

import { makeAutoObservable } from 'mobx';

import { CategoryApiService } from '@common/api/Shop/Category';
import { ProductApiService } from '@common/api/Shop/Product';
import { CategoryDto } from '@common/models/Shop/Category';
import { DimensionsDto, ProductDto } from '@common/models/Shop/Product';
import { ProductConfigurationDto } from '@common/models/Shop/Product/ConfigurationDto';

export class ProductStore {
  private _product: ProductDto | null = null;
  private _copy: ProductDto | null = null;
  private _category: CategoryDto | null = null;

  get product(): ProductDto | null {
    return this._product;
  }

  get copy(): ProductDto | null {
    return this._copy;
  }

  get category(): CategoryDto | null {
    return this._category;
  }

  get defaultConfiguration(): ProductConfigurationDto | null {
    return (
      this._product?.configurations?.find(
        (x) => !x.shop_id && !x.marketplace_id
      ) ?? null
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  setProduct(value: ProductDto) {
    this._product = value;
  }

  setCopy(value: ProductDto) {
    this._copy = value;
  }

  setCategory(value: CategoryDto) {
    this._category = value;
  }

  changeName(name: string) {
    if (!this._copy) return;

    this._copy.name = name;
  }

  changeDescription(desc: string) {
    if (!this._copy) return;

    this._copy.description = desc;
  }

  async revertChanges() {
    if (!this.product || !this.copy) return;

    this.setCopy({ ...this.product });
  }

  async saveChanges() {
    if (!this.product || !this.copy) return;

    if (this.product.name !== this.copy.name) {
      await ProductApiService.updateName({
        productId: this.product.id,
        name: this.copy.name,
      });
    }

    if (this.product.description !== this.copy.description) {
      await ProductApiService.updateDesc({
        productId: this.product.id,
        description: this.copy.description ?? '',
      });
    }

    if (this.product.quantity !== this.copy.quantity) {
      await ProductApiService.setQuantity({
        productId: this.product.id,
        quantity: this.copy.quantity,
      });
    }

    if (!this.isDimensionsEqual()) {
      await ProductApiService.updateDimensions({
        productId: this.product.id,
        ...this.copy.dimensions!,
      });
    }

    if (
      this.product.stocks.warehouse_stocks !== this.copy.stocks.warehouse_stocks
    ) {
      await ProductApiService.updateWarehouseStocks({
        productId: this.product.id,
        count: this.copy.stocks.warehouse_stocks,
      });
    }

    this.setProduct(JSON.parse(JSON.stringify(this.copy)));
  }

  async fetchProduct(id: number) {
    try {
      const { data } = await ProductApiService.getProductById(id);
      this.setProduct(data);
      this.setCopy(data);

      await this.fetchCategory(data.categoryId!);
    } catch (err) {}
  }

  private async fetchCategory(categoryId: number) {
    try {
      const { data } = await CategoryApiService.getCategoryById(categoryId);
      this.setCategory(data);
    } catch (err) {}
  }

  private isDimensionsEqual() {
    if (!this.copy?.dimensions) return true;
    if (!this.product?.dimensions) return false;

    const fields: (keyof DimensionsDto)[] = [
      'depth',
      'width',
      'height',
      'weight',
      'dimensionUnit',
      'weightUnit',
    ];

    for (const field of fields) {
      if (this.product.dimensions[field] !== this.copy.dimensions[field])
        return false;
    }

    return true;
  }
}

import React from 'react';

import { UploadDataStore } from '../store';

const store = new UploadDataStore();

export const UploadDataContext = React.createContext<UploadDataStore>(store);

type Props = {
  children?: React.ReactNode;
};

export const ContextProvider: React.FC<Props> = ({ children }) => (
  <UploadDataContext.Provider value={store}>
    {children}
  </UploadDataContext.Provider>
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KeyValue_key__W-xQJ {\n  padding-bottom: 1px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n  height: min-content;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ShopView/components/KeyValue/KeyValue.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2CAA2C;EAC3C,mBAAmB;AACrB","sourcesContent":[".key {\n  padding-bottom: 1px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n  height: min-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"key": "KeyValue_key__W-xQJ"
};
export default ___CSS_LOADER_EXPORT___;

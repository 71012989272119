import React from 'react';
import MuiCancelIcon from '@mui/icons-material/Cancel';

import { IconButton, IconButtonProps } from '@mui/material';
import { red } from '@mui/material/colors';
import { styled } from '@mui/system';

export const CancelIcon = styled((props: IconButtonProps) => (
  <IconButton size='small' aria-label='cancel' {...props}>
    <MuiCancelIcon />
  </IconButton>
))({
  color: red[300],
});

import { AxiosResponse } from 'axios';

import { $api, $authApi } from '@common/http';
import { AuthResponse } from '@common/models/Response';

import { AuthDto } from './dto';
import { AuthRoutes } from './routes';

export class AuthApiService {
  static async login(authData: AuthDto) {
    return $api.post<
      AuthResponse,
      AxiosResponse<AuthResponse, AuthDto>,
      AuthDto
    >(`${AuthRoutes.LOGIN_ROUTE}`, authData);
  }

  static async registration(authData: AuthDto) {
    return $api.post<
      AuthResponse,
      AxiosResponse<AuthResponse, AuthDto>,
      AuthDto
    >(`${AuthRoutes.REGISTRATION_ROUTE}`, authData);
  }

  static async logout() {
    return $api.post(`${AuthRoutes.LOGOUT_ROUTE}`);
  }

  static async refresh() {
    return $api.get<AuthResponse>(`${AuthRoutes.REFRESH_ROUTE}`);
  }

  static async check() {
    return $authApi.get(`${AuthRoutes.CHECK_ROUTE}`);
  }
}

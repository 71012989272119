import { $authApi } from '@common/http';

import { OzonCredentialsDto, OzonShopDto, OzonUnlinkDto } from './dto';

export class OzonShopApiService {
  static async getShopInfo(shopId: number) {
    return $authApi.get<OzonShopDto>(`/ozon/shop/${shopId}`);
  }

  static async linkToShop(dto: OzonCredentialsDto) {
    return $authApi.post(`/ozon/shop/link`, dto);
  }

  static async unlinkFromShop(dto: OzonUnlinkDto) {
    return $authApi.post(`/ozon/shop/unlink`, dto);
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SupplierDataBlock_blockTitle__sdVxE {\n  font-weight: 500;\n  padding-bottom: 1rem;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n}\n\n.SupplierDataBlock_block__ABRnZ {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  gap: 0.25rem;\n\n  border-top: 1px solid rgba(0, 0, 0, 0.1);\n  padding-top: 0.25rem;\n}\n\n.SupplierDataBlock_headerDates__bveCF .SupplierDataBlock_block__ABRnZ:first-child {\n  border-top: none;\n  padding-top: 0;\n}\n\n.SupplierDataBlock_title__q6aH8 {\n  font-weight: 500;\n}\n\n.SupplierDataBlock_description__MW14O {\n  white-space: pre-wrap;\n}\n\n.SupplierDataBlock_categoryLink__wJ-Pl {\n  text-decoration: none;\n  color: black;\n}\n\n.SupplierDataBlock_delimiter__t8skn {\n  -webkit-user-select: none;\n          user-select: none;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ProductView/components/SupplierDataBlock/SupplierDataBlock.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,YAAY;;EAEZ,wCAAwC;EACxC,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB","sourcesContent":[".blockTitle {\n  font-weight: 500;\n  padding-bottom: 1rem;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n}\n\n.block {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  gap: 0.25rem;\n\n  border-top: 1px solid rgba(0, 0, 0, 0.1);\n  padding-top: 0.25rem;\n}\n\n.headerDates .block:first-child {\n  border-top: none;\n  padding-top: 0;\n}\n\n.title {\n  font-weight: 500;\n}\n\n.description {\n  white-space: pre-wrap;\n}\n\n.categoryLink {\n  text-decoration: none;\n  color: black;\n}\n\n.delimiter {\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockTitle": "SupplierDataBlock_blockTitle__sdVxE",
	"block": "SupplierDataBlock_block__ABRnZ",
	"headerDates": "SupplierDataBlock_headerDates__bveCF",
	"title": "SupplierDataBlock_title__q6aH8",
	"description": "SupplierDataBlock_description__MW14O",
	"categoryLink": "SupplierDataBlock_categoryLink__wJ-Pl",
	"delimiter": "SupplierDataBlock_delimiter__t8skn"
};
export default ___CSS_LOADER_EXPORT___;

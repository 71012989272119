import { makeAutoObservable } from 'mobx';

import { ShopApiService } from '@common/api/Shop';
import {
  YandexCredentialsDto,
  YandexShopApiService,
  YandexShopDto,
  YandexUnlinkDto,
} from '@common/api/Yandex/Shop';
import { MarketplaceEnum } from '@common/constants';

export class YandexStore {
  private _data: YandexShopDto | null = null;

  get data() {
    return this._data;
  }

  constructor() {
    makeAutoObservable(this);
  }

  setData(value: YandexShopDto) {
    this._data = value;
  }

  async fetchShopData(shopId: number) {
    try {
      const { data } = await YandexShopApiService.getShopInfo(shopId);

      this.setData(data);
    } catch (error) {}
  }

  async linkToShop(dto: YandexCredentialsDto) {
    try {
      await YandexShopApiService.linkToShop(dto);

      await this.fetchShopData(dto.shopId);
    } catch (error) {}
  }

  async unlinkFromShop(dto: YandexUnlinkDto) {
    try {
      await YandexShopApiService.unlinkFromShop(dto);

      await this.fetchShopData(dto.shopId);
    } catch (error) {}
  }

  async changeStatus(shop_id: number, is_enabled: boolean) {
    try {
      await ShopApiService.setMarketplaceStatus({
        shop_id,
        marketplace_id: MarketplaceEnum.YANDEX,
        is_enabled,
      });

      await this.fetchShopData(shop_id);
    } catch (error) {}
  }
}

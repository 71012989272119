// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShopView_container__kj6I7 {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n  gap: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ShopView/ShopView.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;AACX","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ShopView_container__kj6I7"
};
export default ___CSS_LOADER_EXPORT___;

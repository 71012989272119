import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePagination = () => {
  const [searchParams] = useSearchParams();

  const [page, setPage] = useState(() => {
    const param = searchParams.get('page');
    if (!param) return 1;

    const num = Number(param);
    if (isNaN(num)) return 1;

    return num;
  });

  const [pagesCount, setPagesCount] = useState(() => page);

  return {
    page,
    setPage,
    pagesCount,
    setPagesCount,
    bind: {
      count: pagesCount,
      page: page,
    },
  };
};

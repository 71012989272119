import { isAxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import { makeAutoObservable } from 'mobx';

import { AuthApiService } from '@common/api/Auth';
import { UserDto } from '@common/models/User';
import { getToken, resetToken, setToken } from '@common/util';

import { AuthDto } from './dto';

export default class AuthStore {
  private _user: UserDto | null;
  private _isAuth: boolean;
  private _error: string;

  constructor() {
    this._user = null;
    this._isAuth = false;
    this._error = '';

    makeAutoObservable(this);
  }

  get user() {
    return this._user;
  }

  get isAuth() {
    return this._isAuth;
  }

  get error() {
    return this._error;
  }

  setAuth(value: boolean) {
    this._isAuth = value;
  }

  setError(value: string) {
    this._error = value;
  }

  setUser(user: UserDto | null) {
    this._user = user;
  }

  async login(authData: AuthDto) {
    this.setError('');

    try {
      const { data } = await AuthApiService.login(authData);
      setToken(data.accessToken);

      await this.validateToken();
    } catch (error) {
      this.setAuth(false);
      this.setUser(null);
      this.handleError(error);
    }
  }

  async registration(authData: AuthDto) {
    this.setError('');

    try {
      const { data } = await AuthApiService.registration(authData);
      setToken(data.accessToken);

      await this.validateToken();
    } catch (error) {
      this.setAuth(false);
      this.setUser(null);
      this.handleError(error);
    }
  }

  async logout() {
    try {
      await AuthApiService.logout();
    } catch (error) {
    } finally {
      resetToken();
      this.setAuth(false);
      this.setUser(null);
      this.setError('');
    }
  }

  async validateToken() {
    this.setError('');

    try {
      await AuthApiService.check();

      const token = getToken()!;

      this.setAuth(true);
      this.setUser(jwtDecode<UserDto>(token));
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 403) {
          const token = getToken()!;
          this.setUser(jwtDecode<UserDto>(token));
        } else {
          this.setUser(null);
        }

        this.setAuth(false);
      } else {
        this.setAuth(false);
        this.setUser(null);
      }
    }
  }

  private handleError(error: unknown) {
    if (!isAxiosError(error)) {
      this.setError(
        error instanceof Object ? JSON.stringify(error) : `${error}`
      );
    } else {
      const status = error.response?.status;

      if (status === 404) {
        this.setError('Backend server is not available.');
      } else if (status === 500) {
        this.setError('Internal server error.');
      } else {
        this.setError(error.response?.data.message);
      }
    }
  }
}

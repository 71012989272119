import { isAxiosError } from 'axios';
import FileSaver from 'file-saver';
import { makeAutoObservable, reaction } from 'mobx';

import { ShopImportApiService } from '@common/api/Shop/Import';

export class YandexIntegrationStore {
  private _file: File | null = null;
  private _loading: boolean = false;
  private _loaded: boolean = false;
  private _error: string = '';

  get file() {
    return this._file;
  }

  get loading() {
    return this._loading;
  }

  get loaded() {
    return this._loaded;
  }

  get error() {
    return this._error;
  }

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.file,
      () => this.setLoaded(false)
    );
  }

  setFile(value: File | null) {
    this._file = value;
  }

  setLoading(value: boolean) {
    this._loading = value;
  }

  setLoaded(value: boolean) {
    this._loaded = value;
  }

  setError(value: string) {
    this._error = value;
  }

  async uploadPrices(shopId: number) {
    if (!this.file) return;

    try {
      this.setError('');
      this.setLoading(true);
      this.setLoaded(false);

      const res = await ShopImportApiService.uploadPrices(shopId, this.file);

      const fileName = res.headers['content-disposition']?.replace(
        'attachment; filename=',
        ''
      );

      const blob = new Blob([res.data]);
      FileSaver.saveAs(blob, fileName);

      this.setLoaded(true);
    } catch (error) {
      if (isAxiosError(error)) {
        console.log(error);
        this.setError(error.response?.data.message);
        return;
      }

      this.setError(`${error}`);
    } finally {
      this.setLoading(false);
    }
  }

  async uploadStocks(shopId: number) {
    if (!this.file) return;

    try {
      this.setError('');
      this.setLoading(true);
      this.setLoaded(false);

      const res = await ShopImportApiService.uploadStocks(shopId, this.file);

      const fileName = res.headers['content-disposition']?.replace(
        'attachment; filename=',
        ''
      );

      const blob = new Blob([res.data]);
      FileSaver.saveAs(blob, fileName);

      this.setLoaded(true);
    } catch (error) {
      if (isAxiosError(error)) {
        console.log(error);
        this.setError(error.response?.data.message);
        return;
      }

      this.setError(`${error}`);
    } finally {
      this.setLoading(false);
    }
  }
}

import { useState } from 'react';

const defaultFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

export const useFloatInput = (formatter = defaultFormatter) => {
  const [value, setValue] = useState('0');

  const handleBlur = () => {
    if (isNaN(parseFloat(value))) setValue('0');
    else {
      const float = parseFloat(value);
      setValue(float < 0 ? '0' : formatter.format(float).replace(/,/g, ''));
    }
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    bind: {
      value,
      onChange: handleChange,
      onBlur: handleBlur,
      error: isNaN(Number(value)),
    },
  };
};

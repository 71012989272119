import { computed, makeAutoObservable } from 'mobx';

import { CategoryDto } from '@common/models/Ozon/Category';

import OzonCategoryService from '../service';

export class CategoryStore {
  firstLevel: CategoryDto[] = [];
  secondLevel: CategoryDto[] = [];
  thirdLevel: CategoryDto[] = [];

  selectedFirstLevel: number | null = null;
  selectedSecondLevel: number | null = null;
  selectedThirdLevel: number | null = null;

  get fullTitle() {
    return [
      this.firstLevel.find((x) => x.id === this.selectedFirstLevel),
      this.secondLevel.find((x) => x.id === this.selectedSecondLevel),
      this.thirdLevel.find((x) => x.id === this.selectedThirdLevel),
    ]
      .filter((x) => x)
      .map((x) => x?.title)
      .join(' > ');
  }

  constructor() {
    makeAutoObservable(this, { fullTitle: computed });
  }

  setFirstLevel(value: CategoryDto[]) {
    this.firstLevel = value;
  }
  setSecondLevel(value: CategoryDto[]) {
    this.secondLevel = value;
  }
  setThirdLevel(value: CategoryDto[]) {
    this.thirdLevel = value;
  }

  setSelectedFirstLevel(value: number | null) {
    this.selectedFirstLevel = value;
  }
  setSelectedSecondLevel(value: number | null) {
    this.selectedSecondLevel = value;
  }
  setSelectedThirdLevel(value: number | null) {
    this.selectedThirdLevel = value;
  }

  async fetchCategory(id: number) {
    try {
      const { data } = await OzonCategoryService.getCategoryById(id);

      return data;
    } catch (error) {
      return null;
    }
  }

  async fetchCategoryWithParents(id: number) {
    try {
      const { data } = await OzonCategoryService.getCategoryByIdWithParents(id);

      return data;
    } catch (error) {
      return null;
    }
  }

  async findCategories(query: string) {
    try {
      const { data } = await OzonCategoryService.findCategories({ query });

      return data.categories;
    } catch (error) {
      return [];
    }
  }

  async init() {
    try {
      const { data } = await OzonCategoryService.getRootCategories();

      this.setFirstLevel(data);
    } catch (error) {}
  }
}

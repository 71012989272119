import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { IconButton, IconButtonProps } from '@mui/material';
import { green } from '@mui/material/colors';
import { styled } from '@mui/system';

export const SaveIcon = styled((props: IconButtonProps) => (
  <IconButton size='small' aria-label='save' {...props}>
    <CheckIcon />
  </IconButton>
))({
  color: green[500],
});

import { observer } from 'mobx-react';
import React, { FC, useContext, useEffect } from 'react';

import { ShopViewContext } from '@features/ShopView/context';
import { useOzonCreds } from '@features/ShopView/hooks';
import { Button, Switch, TextField } from '@mui/material';

import { KeyValue } from '../KeyValue';
import { Section } from '../Section';
import classes from './OzonBlock.module.css';

interface OzonBlockProps {
  shopId: number;
}

const apiKeySkeleton = '11111111-2222-3333-4444-555555555555';

export const OzonBlock: FC<OzonBlockProps> = observer(({ shopId }) => {
  const store = useContext(ShopViewContext);
  const creds = useOzonCreds(shopId);

  useEffect(() => {
    store.ozon.fetchShopData(shopId);
  }, []);

  if (!store.shop) return null;
  if (!store.ozon.data) return null;

  return (
    <Section title='Ozon Seller'>
      <div className={classes.container}>
        <KeyValue name={'Включить Ozon Seller'}>
          <Switch
            checked={store.ozon.data.isEnabled}
            onChange={(_, checked) => store.ozon.changeStatus(shopId, checked)}
          />
        </KeyValue>
        <div className={classes['block-title']}>API Ключ</div>
        <KeyValue name={'ID Клиента'}>
          <TextField
            value={
              creds.isEdit
                ? creds.clientId.value
                : store.ozon.data?.clientId ?? ''
            }
            onChange={creds.clientId.bind.onChange}
            InputProps={{
              readOnly: !creds.isEdit,
              autoComplete: 'off',
            }}
          />
        </KeyValue>
        <KeyValue name={'API Ключ'}>
          <TextField
            value={
              creds.isEdit
                ? creds.apiKey.value
                : store.ozon.data?.clientId
                ? apiKeySkeleton
                : ''
            }
            onChange={creds.apiKey.bind.onChange}
            type={creds.isEdit ? 'text' : 'password'}
            InputProps={{
              readOnly: !creds.isEdit,
              autoComplete: 'off',
            }}
          />
        </KeyValue>
      </div>
    </Section>
  );
});

import { useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { AuthRoutes } from '@common/api/Auth';
import { useAuthStore } from '@common/hooks';
import { getToken } from '@common/util';

export const useAuth = () => {
  const [init, setInit] = useState(false);

  const auth = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  const reditectToLoginPage = () => {
    navigate({
      pathname: AuthRoutes.LOGIN_ROUTE,
      search: createSearchParams({
        redirect: location.pathname + location.search,
      }).toString(),
    });
  };

  useEffect(() => {
    const token = getToken();

    if (!token) {
      return reditectToLoginPage();
    }

    auth.validateToken().then(() => {
      if (!auth.isAuth && !auth.user) return reditectToLoginPage();

      setInit(true);
    });
  }, []);

  useEffect(() => {
    if (!init) return;

    if (!auth.isAuth && !auth.user) return reditectToLoginPage();
  }, [auth.isAuth, auth.user, init]);

  return { auth };
};

import { makeAutoObservable } from 'mobx';

import { ShopApiService, ShopDto } from '@common/api/Shop';

export class ShopListStore {
  private _shops: ShopDto[] = [];

  get shops() {
    return this._shops;
  }

  constructor() {
    makeAutoObservable(this);
  }

  setShops(values: ShopDto[]) {
    this._shops = values;
  }

  async loadShopList() {
    try {
      const { data } = await ShopApiService.getShopList();

      this.setShops(data);
    } catch (error) {}
  }

  async createShop(name: string) {
    try {
      await ShopApiService.createShop({ name });
      await this.loadShopList();
    } catch (error) {}
  }

  async fetchLastSelectedShop(userId: number): Promise<number | null> {
    try {
      const { data: shopId } = await ShopApiService.getLastSelectedShop(userId);

      return shopId;
    } catch (error) {
      return null;
    }
  }

  async setLastSelectedShop(userId: number, shopId: number) {
    try {
      await ShopApiService.setLastSelectedShop({ shopId, userId });
    } catch (error) {}
  }
}

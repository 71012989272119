import { makeAutoObservable, reaction } from 'mobx';

import {
  WBOrdersApiService,
  WBPickListItemDto,
  WBSupplyDto,
} from '@common/api/WB/Orders';

import { PickListStore } from './PickListStore';

export class PickListStoreWB {
  protected _root: PickListStore;
  private _supplies: WBSupplyDto[] = [];
  private _orders: WBPickListItemDto[] = [];

  private _selectedSupply: string | null = null;

  get supplies() {
    return this._supplies;
  }
  get selectedSupply() {
    return this._selectedSupply;
  }

  get orders() {
    return this._orders;
  }

  setSupplies(values: WBSupplyDto[]): void {
    this._supplies = values;
  }
  setSelectedSupply(value: string | null): void {
    this._selectedSupply = value;
  }
  setOrders(status: WBPickListItemDto[]): void {
    this._orders = status;
  }

  constructor(root: PickListStore) {
    this._root = root;

    makeAutoObservable(this);

    reaction(
      () => this.selectedSupply,
      async (supplyId) => await this.fetchPickList(supplyId)
    );
  }

  async fetchSupplies() {
    const shopId = this._root.shopId;
    if (!shopId) return;

    this.setSelectedSupply(null);
    this.setSupplies([]);

    try {
      let total = 0;
      let page = 0;

      do {
        const { data } = await WBOrdersApiService.getSupplies(shopId, {
          page: page++,
          done: false,
        });

        total = data.length;

        this.setSupplies([...this.supplies, ...data]);
      } while (total === 100);
    } catch (error) {}
  }

  async fetchPickList(supplyId: string | null) {
    const shopId = this._root.shopId;
    if (!shopId) return;

    this.setOrders([]);
    if (!supplyId) return;

    try {
      const { data } = await WBOrdersApiService.getPickList(supplyId, shopId);

      this.setOrders(data);
    } catch (error) {}
  }
}

import React from 'react';

import { YandexIntegrationStore } from '../store';

const store = {
  prices: new YandexIntegrationStore(),
  stocks: new YandexIntegrationStore(),
};

export const YandexIntegrationContext =
  React.createContext<typeof store>(store);

type Props = {
  children?: React.ReactNode;
};

export const ContextProvider: React.FC<Props> = ({ children }) => (
  <YandexIntegrationContext.Provider value={store}>
    {children}
  </YandexIntegrationContext.Provider>
);

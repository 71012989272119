import { observer } from 'mobx-react';
import React from 'react';

import { FormControl, MenuItem, Select, TextField } from '@mui/material';

import { useFilterStore } from '../context';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Options: React.FC = observer(() => {
  const store = useFilterStore();

  const handlePriceFrom = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = e.target.value;

    if (!value) return store.setPriceFrom(null);

    if (isNaN(Number(value))) return;

    store.setPriceFrom(Number(value));
  };

  const handlePriceTo = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = e.target.value;

    if (!value) return store.setPriceTo(null);

    if (isNaN(Number(value))) return;

    store.setPriceTo(Number(value));
  };

  const validateRange = () => {
    if (store.priceFrom === null || store.priceTo === null) return;

    if (store.priceTo < store.priceFrom) store.setPriceTo(store.priceFrom);
  };

  return (
    <React.Fragment>
      <MenuItem>
        <TextField
          placeholder='От'
          fullWidth
          onChange={handlePriceFrom}
          value={store.priceFrom ?? ''}
          onBlur={validateRange}
          autoComplete='off'
        />
      </MenuItem>
      <MenuItem>
        <TextField
          placeholder='До'
          fullWidth
          onChange={handlePriceTo}
          value={store.priceTo ?? ''}
          onBlur={validateRange}
          autoComplete='off'
        />
      </MenuItem>
    </React.Fragment>
  );
});

export const PriceFilter = observer(() => {
  const store = useFilterStore();

  const renderSelected = () => {
    if (store.priceFrom !== null && store.priceTo !== null)
      return `${store.priceFrom} ₽ - ${store.priceTo} ₽`;

    if (store.priceFrom !== null) return `От ${store.priceFrom} ₽`;
    if (store.priceTo !== null) return `До ${store.priceTo} ₽`;
  };

  return (
    <FormControl sx={{ width: 170 }}>
      <Select
        id='supplier-input'
        value={['']}
        MenuProps={MenuProps}
        sx={{ overflowWrap: 'break-word', height: ITEM_HEIGHT }}
        renderValue={renderSelected}
        native={false}
        multiple
      >
        <Options />
      </Select>
    </FormControl>
  );
});

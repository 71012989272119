import React, { createContext, FC, ReactNode } from 'react';

import RootStore from '@common/store/RootStore';

const store = new RootStore();

export const StoreContext = createContext<RootStore>(store);

type Props = {
  children?: ReactNode;
};

export const StoreContextProvider: FC<Props> = ({ children }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);

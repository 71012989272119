// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container_container__XtP84 {\n  width: 100%;\n  display: grid;\n  gap: 1rem;\n  padding: 1rem;\n  grid-template:\n    'header header' min-content\n    'sidebar content' 1fr\n    / 1fr 3fr;\n}\n\n.Container_headerBlock__TVulL {\n  grid-area: header;\n}\n\n.Container_sidebar__qf6a1 {\n  grid-area: sidebar;\n  position: sticky;\n  top: 1rem;\n  height: min-content;\n\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.Container_contentBlock__SMJSu {\n  grid-area: content;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ProductView/Container.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,SAAS;EACT,aAAa;EACb;;;aAGW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;EACT,mBAAmB;;EAEnB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".container {\n  width: 100%;\n  display: grid;\n  gap: 1rem;\n  padding: 1rem;\n  grid-template:\n    'header header' min-content\n    'sidebar content' 1fr\n    / 1fr 3fr;\n}\n\n.headerBlock {\n  grid-area: header;\n}\n\n.sidebar {\n  grid-area: sidebar;\n  position: sticky;\n  top: 1rem;\n  height: min-content;\n\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.contentBlock {\n  grid-area: content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container_container__XtP84",
	"headerBlock": "Container_headerBlock__TVulL",
	"sidebar": "Container_sidebar__qf6a1",
	"contentBlock": "Container_contentBlock__SMJSu"
};
export default ___CSS_LOADER_EXPORT___;

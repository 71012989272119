import { makeAutoObservable } from 'mobx';

import { ProductStatusEnum } from '@common/models/Shop/Product';

export class FilterStore {
  private _isAvailable: boolean = false;
  private _sku: string = '';
  private _name: string = '';
  private _supplierSku: string = '';
  private _priceFrom: number | null = null;
  private _priceTo: number | null = null;
  private _status: ProductStatusEnum | null = null;

  get isAvailable() {
    return this._isAvailable;
  }
  get sku() {
    return this._sku;
  }
  get name() {
    return this._name;
  }
  get supplierSku() {
    return this._supplierSku;
  }
  get priceFrom() {
    return this._priceFrom;
  }
  get priceTo() {
    return this._priceTo;
  }
  get status() {
    return this._status;
  }

  constructor() {
    makeAutoObservable(this);
  }

  setIsAvailable(value: boolean) {
    this._isAvailable = value;
  }
  setSku(value: string) {
    this._sku = value;
  }
  setName(value: string) {
    this._name = value;
  }
  setSupplierSku(value: string) {
    this._supplierSku = value;
  }
  setPriceFrom(value: number | null) {
    this._priceFrom = value;
  }
  setPriceTo(value: number | null) {
    this._priceTo = value;
  }
  setStatus(value: ProductStatusEnum | null) {
    this._status = value;
  }
}

import { AxiosResponse } from 'axios';

import { GetPickListRequest, PickListDto } from '@common/api/ClientFeatures';
import { OzonSellerRoutes, YandexMarketRoutes } from '@common/constants';
import { $authApi } from '@common/http';

export default class PickListService {
  static async getPickListOzon(dto: GetPickListRequest) {
    return $authApi.post<
      PickListDto,
      AxiosResponse<PickListDto>,
      GetPickListRequest
    >(`${OzonSellerRoutes.FBS_ROUTE}/pick-list`, dto);
  }

  static async getPickListYandex(dto: GetPickListRequest) {
    return $authApi.post<
      PickListDto,
      AxiosResponse<PickListDto>,
      GetPickListRequest
    >(`${YandexMarketRoutes.PICK_LIST_ROUTE}`, dto);
  }
}

import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { CancelIcon, EditIcon, SaveIcon } from '@common/ui/icons';
import { useProductStore } from '@features/ProductView/context';
import { TextField } from '@mui/material';

import { useNameInput } from './hooks';
import classes from './TitleElement.module.css';

export const TitleElement = observer(() => {
  const store = useProductStore();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const nameInput = useNameInput();

  const handleEdit = () => {
    if (isBlocked) return;
    setIsEditMode(true);
  };

  const handleSave = async () => {
    if (isBlocked) return;
    try {
      setIsBlocked(true);
      await store.saveChanges();
    } catch {
    } finally {
      setIsEditMode(false);
      setIsBlocked(false);
    }
  };

  const handleCancel = async () => {
    if (isBlocked) return;
    try {
      setIsBlocked(true);
      await store.revertChanges();
    } catch {
    } finally {
      setIsEditMode(false);
      setIsBlocked(false);
    }
  };

  if (!store.product) return null;

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {isEditMode ? (
          <TextField fullWidth {...nameInput} />
        ) : (
          store.product.name
        )}
      </div>
      <div className={classes.buttons}>
        {isEditMode ? (
          <React.Fragment>
            <SaveIcon
              title='Сохранить изменения'
              onClick={handleSave}
              disabled={isBlocked}
            />
            <CancelIcon
              title='Отменить изменения'
              onClick={handleCancel}
              disabled={isBlocked}
            />
          </React.Fragment>
        ) : (
          <EditIcon
            title='Изменить название'
            onClick={handleEdit}
            disabled={isBlocked}
          />
        )}
      </div>
      <div className={classes.content}>
        <div>Артикул: {store.product.sku}</div>
      </div>
    </div>
  );
});

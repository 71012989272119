import { observer } from 'mobx-react';
import React, { FC, useContext, useEffect } from 'react';

import { ShopViewContext } from '@features/ShopView/context';
import { Button, Switch, TextField } from '@mui/material';

import { KeyValue } from '../KeyValue';
import { Section } from '../Section';
import classes from './WildberriesBlock.module.css';

interface WildberriesBlockProps {
  shopId: number;
}

const apiKeySkeleton = '11111111-2222-3333-4444-555555555555';

export const WildberriesBlock: FC<WildberriesBlockProps> = observer(
  ({ shopId }) => {
    const store = useContext(ShopViewContext);

    useEffect(() => {
      store.ozon.fetchShopData(shopId);
    }, []);

    if (!store.shop) return null;
    if (!store.ozon.data) return null;

    return (
      <Section title='Wildberries'>
        <div className={classes.container}>В разработке</div>
      </Section>
    );
  }
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SmartInput_container__F77oN {\n  flex: 1 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.SmartInput_tooltip__jtifm {\n  white-space: pre-wrap;\n}\n\n.SmartInput_icon__jdt5f {\n  opacity: 0.5;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/features/AddProductForm/SmartInput/SmartInput.module.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".container {\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.tooltip {\n  white-space: pre-wrap;\n}\n\n.icon {\n  opacity: 0.5;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SmartInput_container__F77oN",
	"tooltip": "SmartInput_tooltip__jtifm",
	"icon": "SmartInput_icon__jdt5f"
};
export default ___CSS_LOADER_EXPORT___;

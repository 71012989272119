import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';

import { Tab, Tabs } from '@mui/material';

import { PickListContext } from '../context';
import { MarketPlaceType } from '../store';

interface TabData {
  status: MarketPlaceType;
  label: string;
}

const tabs: TabData[] = [
  {
    status: 'ozon',
    label: 'Ozon Seller',
  },
  {
    status: 'yandex',
    label: 'Yandex Market',
  },
  {
    status: 'wb',
    label: 'Wildberries',
  },
];

export const MarketPlaceTabBlock = observer(() => {
  const store = useContext(PickListContext);
  const [value, setValue] = useState(0);

  const handleChange = (_: any, newValue: any) => {
    setValue(newValue);

    store.setSelectedMarketPlace(tabs[newValue].status);
  };

  return (
    <Tabs value={value} onChange={handleChange}>
      {tabs.map((x) => (
        <Tab key={x.status} label={x.label} />
      ))}
    </Tabs>
  );
});

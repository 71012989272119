import { observer } from 'mobx-react';
import React from 'react';
import { Helmet } from 'react-helmet';

import { GLOBAL_TITLE } from '@common/constants';

import { MarketplaceTabs } from './components/MarketplaceTabs';
import { PriceListTable } from './components/PriceListTable';
import { SearchComponent } from './components/SearchComponent';
import { StatusTabs } from './components/StatusTabs';
import { ContextProvider } from './context';
import classes from './PriceList.module.css';

const pageTitle = `Цены | ${GLOBAL_TITLE}`;

const Container = observer(() => {
  return (
    <div className={classes.container}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <h2>Цены</h2>
      <MarketplaceTabs />
      <StatusTabs />
      <SearchComponent />
      <PriceListTable />
    </div>
  );
});

export const PriceList = observer(() => (
  <ContextProvider>
    <Container />
  </ContextProvider>
));

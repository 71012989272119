import { computed, makeAutoObservable } from 'mobx';

import { FindCategoryResponseItemDto } from '@common/models/Ozon/Category';

export class ProductInfoStore {
  private _selectedCategory: FindCategoryResponseItemDto | null = null;

  get selectedCategory() {
    return this._selectedCategory;
  }

  get selectedCategoryTitle() {
    let category = this.selectedCategory;

    if (!category) return '';

    let title = category.title;

    while (category.parent) {
      category = category.parent;
      title = `${category.title} > ${title}`;
    }

    return title;
  }

  constructor() {
    makeAutoObservable(this, {
      selectedCategoryTitle: computed,
    });
  }

  setSelectedCategory(value: FindCategoryResponseItemDto | null) {
    this._selectedCategory = value;
  }
}

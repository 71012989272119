import { observer } from 'mobx-react';
import React from 'react';

import { ProductStatusEnum } from '@common/models/Shop/Product';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';

import { useFilterStore, useSuppliersStore } from '../context';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options: { title: string; value: ProductStatusEnum | null }[] = [
  { title: 'Все', value: null },
  { title: 'Готовы к продаже', value: ProductStatusEnum.READY_FOR_SALE },
  { title: 'Не готовы к продаже', value: ProductStatusEnum.NOT_READY_FOR_SALE },
];

const Options: React.FC = observer(() => {
  const store = useFilterStore();

  const isChecked = (value: ProductStatusEnum | null) => {
    return store.status === value;
  };

  const selectStatus = (value: ProductStatusEnum | null) => {
    store.setStatus(value);
  };

  return (
    <React.Fragment>
      {options.map((option, idx) => (
        <MenuItem
          key={idx}
          value={option.value ?? -1}
          sx={{ height: ITEM_HEIGHT }}
          onClick={() => selectStatus(option.value)}
        >
          <Checkbox checked={isChecked(option.value)} />
          <ListItemText primary={option.title} />
        </MenuItem>
      ))}
    </React.Fragment>
  );
});

export const StatusFilter = observer(() => {
  const store = useFilterStore();

  const renderSelected = (value: unknown) => {
    return options.find((x) => x.value === value)?.title;
  };

  return (
    <FormControl sx={{ width: 120 }}>
      <Select
        id='supplier-input'
        value={store.status ?? -1}
        MenuProps={MenuProps}
        sx={{ overflowWrap: 'break-word', height: ITEM_HEIGHT }}
        renderValue={renderSelected}
      >
        <Options />
      </Select>
    </FormControl>
  );
});

import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { PickListContext } from '../../context';
import classes from './ShopList.module.css';

export const ShopList = observer(() => {
  const store = useContext(PickListContext);

  const handleClick = (id: number) => {
    store.setShopId(id);
  };

  return (
    <div className={classes.root}>
      {store.shops.map((shop) => (
        <div
          className={clsx(
            classes.item,
            store.shopId === shop.id && classes.selected
          )}
          key={shop.id}
          onClick={() => handleClick(shop.id)}
        >
          {shop.company_name}
          <sub>{shop.organization_name}</sub>
        </div>
      ))}
    </div>
  );
});

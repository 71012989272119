import React from 'react';

import { PostingListStore } from '../store';

const store = new PostingListStore();

export const PostingListContext = React.createContext<PostingListStore>(store);

type Props = {
  children?: React.ReactNode;
};

export const ContextProvider: React.FC<Props> = ({ children }) => (
  <PostingListContext.Provider value={store}>
    {children}
  </PostingListContext.Provider>
);

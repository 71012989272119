// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PostingList_secondaryText__cXoYU {\n  font-size: 12px;\n  color: #878c98;\n}\n\n.PostingList_importantCell__SKRhY {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/features/PostingList/PostingList.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".secondaryText {\n  font-size: 12px;\n  color: #878c98;\n}\n\n.importantCell {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secondaryText": "PostingList_secondaryText__cXoYU",
	"importantCell": "PostingList_importantCell__SKRhY"
};
export default ___CSS_LOADER_EXPORT___;

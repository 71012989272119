import { AxiosResponse } from 'axios';

import { OzonSellerRoutes } from '@common/constants';
import { $authApi } from '@common/http';

import { GetPostingListRequest, GetPostingListResponse } from '../dto';

export default class PostingListService {
  static async getPostingList(dto: GetPostingListRequest) {
    return $authApi.post<
      GetPostingListResponse,
      AxiosResponse<GetPostingListResponse>,
      GetPostingListRequest
    >(`${OzonSellerRoutes.FBS_ROUTE}/postings`, dto);
  }
}

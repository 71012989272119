import React, { useContext, useEffect, useState } from 'react';

import { PriceListContext } from '@features/PriceList/context';
import { Tab, Tabs } from '@mui/material';
import { ProductStatusEnum } from '@common/models/Shop/Product';
import { observer } from 'mobx-react';
import { ProductStatusCountDto } from '@common/api/Shop/Product';

interface TabData {
  status?: ProductStatusEnum;
  label: string;
  count_property: keyof ProductStatusCountDto;
}

const tabs: TabData[] = [
  {
    status: undefined,
    label: 'Все',
    count_property: 'all',
  },
  {
    status: ProductStatusEnum.NOT_READY_FOR_SALE,
    label: 'Не готовы к продаже',
    count_property: 'not_in_sale',
  },
  {
    status: ProductStatusEnum.READY_FOR_SALE,
    label: 'Готовы к продаже',
    count_property: 'in_sale',
  },
  {
    status: ProductStatusEnum.QUARANTINE,
    label: 'В карантине',
    count_property: 'in_quarantine',
  },
];

export const StatusTabs = observer(() => {
  const store = useContext(PriceListContext);

  const [value, setValue] = useState(0);

  const handleChange = (_: any, newValue: any) => {
    setValue(newValue);

    store.setFilter({
      ...store.filter,
      product_status: tabs[newValue].status,
    });
  };

  useEffect(() => {
    setValue(tabs.findIndex((x) => x.status === store.filter.product_status));
  }, [store.filter.product_status]);

  return (
    <Tabs value={value} onChange={handleChange}>
      {tabs.map((x) => (
        <Tab
          key={x.label}
          label={
            <div>
              {x.label}{' '}
              {store.productCount ? (
                <span>
                  {'('}
                  {store.productCount[x.count_property]}
                  {')'}
                </span>
              ) : null}
            </div>
          }
        />
      ))}
    </Tabs>
  );
});

import React from 'react';

import { OrderByRows } from '@common/api/Shop/Product';
import {
  Box,
  styled,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  fontWeight: 'bold',
}));

type OrderProps = {
  order: 'asc' | 'desc';
  orderBy: OrderByRows;
  onRequestSort: (event: React.MouseEvent, property: OrderByRows) => void;
};

export type TableHeaderColumnProps = {
  id: string;
  label: string;
  width?: number | string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right';
  filter?: React.ReactNode;
};

const TableHeaderColumn: React.FC<TableHeaderColumnProps & OrderProps> = (
  props
) => {
  const isSortable = (value: string): value is OrderByRows => {
    return Object.values(OrderByRows).includes(value as OrderByRows);
  };

  const createSortRequest =
    (property: OrderByRows) => (event: React.MouseEvent) => {
      props.onRequestSort(event, property);
    };

  return (
    <StyledTableCell
      key={props.id}
      align='left'
      style={{
        maxWidth: props.maxWidth,
        minWidth: props.minWidth,
        width: props.width,
      }}
      sortDirection={props.orderBy === props.id ? props.order : false}
    >
      {isSortable(props.id) ? (
        <TableSortLabel
          active={props.orderBy === props.id}
          direction={props.orderBy === props.id ? props.order : 'asc'}
          onClick={createSortRequest(props.id as any)}
        >
          {props.label}
          {props.orderBy === props.id ? (
            <Box component='span' sx={visuallyHidden}>
              {props.order === 'desc'
                ? 'sorted descending'
                : 'sorted ascending'}
            </Box>
          ) : null}
        </TableSortLabel>
      ) : (
        props.label
      )}
    </StyledTableCell>
  );
};

type TableHeaderProps = {
  columns: TableHeaderColumnProps[];
} & OrderProps;

export const TableHeader: React.FC<TableHeaderProps> = ({
  columns,
  ...orderProps
}) => {
  return (
    <TableHead style={{ position: 'sticky', top: 0, zIndex: 100 }}>
      <TableRow style={{ height: 50 }}>
        {columns.map(({ filter, ...rest }) => (
          <TableHeaderColumn key={rest.id} {...rest} {...orderProps} />
        ))}
      </TableRow>
      <TableRow style={{ height: 50 }}>
        {columns.map(({ filter, id, ...rest }) => (
          <TableCell
            key={id}
            style={{
              maxWidth: rest.maxWidth,
              minWidth: rest.minWidth,
              width: rest.width,
            }}
          >
            {filter ? filter : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { forwardRef, useContext, useEffect } from 'react';

import {
  WBPickListItemDto,
  WBPickListItemProductDto,
} from '@common/api/WB/Orders';
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { PickListContext } from '../context';
import classes from '../PickList.module.css';

interface ColumnData {
  id: string;
  label: string;
  value: (
    order: WBPickListItemDto,
    product: WBPickListItemProductDto
  ) => string | React.ReactNode;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
}

const renderOrderId = (
  order: WBPickListItemDto,
  product: WBPickListItemProductDto
) => (
  <div className={clsx(order.products.length > 1 && classes.importantCell)}>
    {product.order_id}
  </div>
);

const renderImage = (
  _: WBPickListItemDto,
  product: WBPickListItemProductDto
) => <img className={classes.image} src={product.image}></img>;

const renderProductName = (
  order: WBPickListItemDto,
  product: WBPickListItemProductDto
) => (
  <div className={clsx(order.products.length > 1 && classes.importantCell)}>
    {product.name}
  </div>
);

const renderSupplierSku = (
  _: WBPickListItemDto,
  product: WBPickListItemProductDto
) => <div>{product.supplier_sku ?? product.offer_id}</div>;

const renderIsAvailableOnWarehouse = (
  _: WBPickListItemDto,
  product: WBPickListItemProductDto
) => <div>{product.has_warehouse_stocks ? '(✓)' : null}</div>;

const renderQuantity = (
  _: WBPickListItemDto,
  product: WBPickListItemProductDto
) => <div>{product.quantity}</div>;

const renderOrderDate = (
  _: WBPickListItemDto,
  product: WBPickListItemProductDto
) => {
  const diff = new Date().getTime() - new Date(product.created_at).getTime();

  const hours = Math.floor(diff / (1000 * 3600));
  const minutes = Math.floor((diff - 1000 * 3600 * hours) / (1000 * 60));

  const getColor = () => {
    if (hours <= 20) return 'green';
    if (hours > 20 && hours <= 40) return 'darkkhaki';
    if (hours > 40 && hours <= 60) return 'darkorange';
    if (hours > 60) return 'red';
  };

  return (
    <div>
      <div style={{ color: getColor(), fontWeight: 'bold' }}>
        {`${hours} ч. ${minutes} мин.`}
      </div>
    </div>
  );
};

const columns: ColumnData[] = [
  {
    id: 'order_id',
    label: 'ID заказа',
    value: renderOrderId,
    minWidth: 190,
  },
  {
    id: 'image',
    label: 'Изображение',
    value: renderImage,
  },
  {
    id: 'name',
    label: 'Название',
    value: renderProductName,
    minWidth: 200,
  },
  {
    id: 'name',
    label: 'Поставщик',
    value: (_, product) => product.supplier_name,
  },
  {
    id: 'name',
    label: 'Артикул поставщика',
    value: renderSupplierSku,
  },
  {
    id: 'is_available',
    label: 'Имеется на складе',
    value: renderIsAvailableOnWarehouse,
  },
  {
    id: 'quantity',
    label: 'Количество',
    value: renderQuantity,
  },
];

function createdAtSort(a: WBPickListItemDto, b: WBPickListItemDto) {
  return (
    new Date(a.products[0].created_at).getTime() -
    new Date(b.products[0].created_at).getTime()
  );
}

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 12,
  fontWeight: 'bold',
  lineHeight: 1.5,
}));

type TableHeaderColumnProps = {
  label: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right';
};

const TableHeaderColumn: React.FC<TableHeaderColumnProps> = (props) => {
  return (
    <StyledTableCell
      align='left'
      style={{
        maxWidth: props.maxWidth,
        minWidth: props.minWidth,
        width: props.width,
      }}
    >
      {props.label}
    </StyledTableCell>
  );
};

export const OrderTable = observer(
  forwardRef<null>(({}, ref) => {
    const store = useContext(PickListContext);

    return (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <TableContainer>
          <Table stickyHeader size='small' ref={ref}>
            <TableHead>
              <TableRow style={{ height: 50 }}>
                {columns.map((column) => (
                  <TableHeaderColumn key={column.label} {...column} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflowY: 'scroll' }}>
              {store.wb.orders.length ? (
                store.wb.orders
                  .slice()
                  .sort(createdAtSort)
                  .map((order) =>
                    order.products.map((product) => (
                      <TableRow
                        hover
                        key={order.order_group_id + product.order_id}
                      >
                        {columns.map((column) => (
                          <TableCell key={column.label} align='left'>
                            {column.value(order, product)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  )
              ) : (
                <TableRow>
                  <TableCell colSpan={100} align='center'>
                    Нет записей
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  })
);

export const WBPickList = observer(
  forwardRef<null>(({}, ref) => {
    const store = useContext(PickListContext);

    const fetchSupplies = async () => {
      await store.wb.fetchSupplies();
    };

    const handleClick = (supplyId: string) => {
      store.wb.setSelectedSupply(supplyId);
    };

    useEffect(() => {
      fetchSupplies();
    }, [store.shopId]);

    return (
      <div className={classes['wb-container']}>
        <Paper className={classes['wb-supplies']}>
          <h3>Поставки</h3>
          {!store.wb.supplies.length ? <div>Нет открытых поставок</div> : null}

          {store.wb.supplies.map((supply) => (
            <div
              className={clsx(
                classes['wb-supplies-item'],
                store.wb.selectedSupply === supply.id && classes.selected
              )}
              onClick={() => handleClick(supply.id)}
              key={supply.id}
            >
              <span className={classes['wb-supplies-item-name']}>
                {supply.name}
              </span>
              <sub>{supply.id}</sub>
              {supply.count > 0 ? (
                <sub>{supply.count} заказов в поставке</sub>
              ) : null}
            </div>
          ))}
        </Paper>
        <OrderTable ref={ref} />
      </div>
    );
  })
);

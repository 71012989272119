import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { useProductStore } from '@features/ProductView/context';
import { beautifyDate } from '@features/ProductView/util';
import {
  Button,
  ButtonGroup,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { PicturesGallery } from '../PicturesGallery';
import { TitleElement } from '../TitleElement';
import classes from './HeaderBlock.module.css';
import { useWarehouseStocks } from './hooks';
import { KeyValue } from '../KeyValue';

export const HeaderBlock = observer(() => {
  const store = useProductStore();

  const warehouseStocks = useWarehouseStocks();

  const defaultConfiguration = useMemo(
    () =>
      store.product?.configurations?.find(
        (x) => !x.shop_id && !x.marketplace_id
      ),
    [store.product?.configurations]
  );

  if (!store.product) return null;

  return (
    <Paper>
      <div className={classes.container}>
        <div className={classes.pictures}>
          <PicturesGallery urls={store.product.images || []} maxWidth='350px' />
        </div>
        <div className={classes.title}>
          <TitleElement />
        </div>
        <div className={classes.priceAndStocks}>
          <div className={classes.blockTitle}>Цена и остатки</div>
          {defaultConfiguration && defaultConfiguration.price ? (
            <KeyValue name='Закупочная цена'>
              <span
                title={`Обновлено ${defaultConfiguration.price.is_updated}`}
              >
                {defaultConfiguration.price.value} &#8381;
              </span>
            </KeyValue>
          ) : null}

          <KeyValue name='В наличии у поставщика, шт.'>
            <span
              title={`Обновлено ${beautifyDate(
                store.product.stocks?.updatedAt
              )}`}
            >
              {store.product.stocks?.supplier_stocks}
            </span>
          </KeyValue>
          <KeyValue name='На складе, шт.'>
            <ButtonGroup aria-label='small outlined button group'>
              <Button
                variant='contained'
                disabled={!warehouseStocks.value}
                onClick={warehouseStocks.decrement}
              >
                -
              </Button>
              <Button>{warehouseStocks.value}</Button>
              <Button variant='contained' onClick={warehouseStocks.increment}>
                +
              </Button>
            </ButtonGroup>
          </KeyValue>
        </div>
      </div>
    </Paper>
  );
});

import { makeAutoObservable, observable, ObservableMap } from 'mobx';

import {
  AttributeValueDto,
  GetAttributeValuesDto,
} from '@common/models/Ozon/Category';

import OzonCategoryService from '../service';

export class AttributeDictionaryStore {
  readonly values = observable.map<number, AttributeValueDto[]>({});

  constructor() {
    makeAutoObservable(this);
  }

  async fetchDictionary(dto: GetAttributeValuesDto) {
    try {
      const { data } = await OzonCategoryService.getAttributeValues(dto);

      return data.sort((a, b) => a.value.localeCompare(b.value));
    } catch (error) {
      return null;
    }
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FeaturesStep_container__hQf19 {\n  flex: 1 1;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n  gap: 1rem;\n}\n\n.FeaturesStep_container__hQf19 h4 {\n  padding: 0;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/features/AddProductForm/components/FeaturesStep/FeaturesStep.module.css"],"names":[],"mappings":"AAAA;EACE,SAAO;;EAEP,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,UAAU;EACV,SAAS;AACX","sourcesContent":[".container {\n  flex: 1;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n  gap: 1rem;\n}\n\n.container h4 {\n  padding: 0;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FeaturesStep_container__hQf19"
};
export default ___CSS_LOADER_EXPORT___;

import { matchPath, useLocation } from 'react-router-dom';

export const useRouteMatch = (patterns: readonly string[]) => {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const possibleMatch = matchPath(patterns[i], pathname);

    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
};

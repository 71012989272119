// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductList_productName__DRX30 {\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n  word-wrap: break-word;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.ProductList_stockContainer__E\\+Jti {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 0.5rem;\n}\n\n.ProductList_stock__IlUD1 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 0.25rem;\n  width: 50px;\n}\n\n.ProductList_delimiter__Y8cbk::before {\n  content: '/';\n  margin-right: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ProductList/ProductList.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,4BAA4B;EAC5B,qBAAqB;EACrB,qBAAqB;EACrB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":[".productName {\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n  word-wrap: break-word;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.stockContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 0.5rem;\n}\n\n.stock {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 0.25rem;\n  width: 50px;\n}\n\n.delimiter::before {\n  content: '/';\n  margin-right: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productName": "ProductList_productName__DRX30",
	"stockContainer": "ProductList_stockContainer__E+Jti",
	"stock": "ProductList_stock__IlUD1",
	"delimiter": "ProductList_delimiter__Y8cbk"
};
export default ___CSS_LOADER_EXPORT___;

import { AxiosResponse } from 'axios';

import { OzonSellerRoutes } from '@common/constants';
import { $authApi } from '@common/http';
import {
  AttributeDto,
  AttributeValueDto,
  CategoryDto,
  FindCategoryRequestDto,
  FindCategoryResponseDto,
  FindCategoryResponseItemDto,
  GetAttributeValuesDto,
} from '@common/models/Ozon/Category';

export default class OzonCategoryService {
  static async getCategoryById(id: number) {
    return $authApi.get<CategoryDto>(
      `${OzonSellerRoutes.CATEGORIES_ROUTE}/${id}`
    );
  }

  static async getCategoryByIdWithParents(id: number) {
    return $authApi.get<FindCategoryResponseItemDto>(
      `${OzonSellerRoutes.CATEGORIES_ROUTE}/${id}/parents`
    );
  }

  static async getRootCategories() {
    return $authApi.get<CategoryDto[]>(`${OzonSellerRoutes.CATEGORIES_ROUTE}`);
  }

  static async findCategories(dto: FindCategoryRequestDto) {
    return $authApi.post<
      FindCategoryResponseDto,
      AxiosResponse<FindCategoryResponseDto>,
      FindCategoryRequestDto
    >(`${OzonSellerRoutes.CATEGORIES_ROUTE}/find`, dto);
  }

  static async getAttributes(categoryId: number) {
    return $authApi.get<AttributeDto[]>(
      `${OzonSellerRoutes.ATTRIBUTES_ROUTE}/${categoryId}`
    );
  }

  static async getAttributeById(id: number) {
    return $authApi.get<AttributeDto>(
      `${OzonSellerRoutes.INDEX_ROUTE}/attribute/${id}`
    );
  }

  static async getAttributeValues(dto: GetAttributeValuesDto) {
    return $authApi.post<
      AttributeValueDto[],
      AxiosResponse<AttributeValueDto[]>,
      GetAttributeValuesDto
    >(`${OzonSellerRoutes.ATTRIBUTES_ROUTE}/values`, dto);
  }
}

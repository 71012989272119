import React from 'react';

import LinkOffIcon from '@mui/icons-material/LinkOff';
import { IconButton, IconButtonProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/system';

export const UnlinkIcon = styled((props: IconButtonProps) => (
  <IconButton size='small' aria-label='edit' {...props}>
    <LinkOffIcon />
  </IconButton>
))({
  color: grey[600],
});

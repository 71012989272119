import { observer } from 'mobx-react';
import React, { useContext, useMemo } from 'react';

import { AddProductFormContext } from '@features/AddProductForm/context';

import { SmartInput } from '../../SmartInput';
import classes from './FeaturesStep.module.css';
import { AttributeDto } from '@common/models/Ozon/Category';

export const FeaturesStep = observer(() => {
  const store = useContext(AddProductFormContext);

  const groupedAttributes = useMemo(() => {
    const groups: { [key: string]: AttributeDto[] } = {};

    for (const attr of store.attributes.attributes) {
      const key = attr.groupName || '';

      if (groups[key]) {
        groups[key].push(attr);
      } else {
        groups[key] = [attr];
      }
    }

    return groups;
  }, [store.attributes.attributes]);

  return (
    <div className={classes.container}>
      <h2>Характеристики товара</h2>
      {Object.entries(groupedAttributes).map(([groupName, values]) => (
        <React.Fragment key={groupName}>
          {groupName ? <h4>{groupName}</h4> : null}
          {values.map((attr) => (
            <SmartInput fullWidth schema={attr} key={attr.id} />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
});

import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import {
  OrderByRows,
  ProductApiService,
  ProductListItemDto,
} from '@common/api/Shop/Product';
import { GLOBAL_TITLE } from '@common/constants';
import { ProductStatusEnum } from '@common/models/Shop/Product';
import FactoryIcon from '@mui/icons-material/Factory';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Pagination from '@mui/material/Pagination/Pagination';
import Paper from '@mui/material/Paper/Paper';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow/TableRow';

import { CategoryList } from './components/CategoryList';
import { IsAvailableSelect } from './components/IsAvailableSelect';
import { PriceFilter } from './components/PriceFilter';
import { SearchName } from './components/SearchName';
import { SearchSku } from './components/SearchSku';
import { SearchSupplierSku } from './components/SearchSupplierSku';
import { StatusFilter } from './components/StatusFilter';
import { SupplierList } from './components/SupplierList';
import { TableHeader, TableHeaderColumnProps } from './components/TableHeader';
import { ContextProvider, useProductListStore } from './context';
import { useFilterHistory, useOrder, usePagination } from './hooks';
import classes from './ProductList.module.css';
import ProductListService from './service';
import { Switch } from '@mui/material';

type ColumnData = TableHeaderColumnProps & {
  format: (product: ProductListItemDto) => string | React.ReactNode;
};

const renderStock = (data: ProductListItemDto) => (
  <div
    className={classes.stockContainer}
    title={`У поставщика: ${data.supplier_stock}, На складе: ${data.warehouse_stock}`}
  >
    <div className={classes.stock}>
      <span>{data.supplier_stock}</span>
      <FactoryIcon fontSize='small' />
    </div>
    <div className={classes.stock}>
      <span className={classes.delimiter}></span>
      <span>{data.warehouse_stock}</span>
      <WarehouseIcon fontSize='small' />
    </div>
  </div>
);

const RenderStatus = (data: ProductListItemDto) => {
  const store = useProductListStore();
  const onChange = async (checked: boolean) => {
    await ProductApiService.setStatus({
      configuration_id: data.configuration_id,
      status: checked
        ? ProductStatusEnum.READY_FOR_SALE
        : ProductStatusEnum.NOT_READY_FOR_SALE,
    });
    store.updateProduct({
      ...data,
      status: checked
        ? ProductStatusEnum.READY_FOR_SALE
        : ProductStatusEnum.NOT_READY_FOR_SALE,
    });
  };

  return (
    <div>
      {data.status === ProductStatusEnum.READY_FOR_SALE
        ? 'Готов к продаже'
        : 'Не продается'}
      <Switch
        checked={data.status === ProductStatusEnum.READY_FOR_SALE}
        onChange={(_, checked) => onChange(checked)}
      />
    </div>
  );
};

const columns: ColumnData[] = [
  {
    id: 'supplier',
    label: 'Поставщик',
    width: 150,
    filter: <SupplierList />,
    format: (value) => value.supplier_name,
  },
  {
    id: 'offer_id',
    label: 'Артикул',
    width: 50,
    filter: <SearchSku />,
    format: (value) => value.offer_id,
  },
  {
    id: 'supplier_offer_id',
    label: 'Артикул поставщика',
    width: 50,
    filter: <SearchSupplierSku />,
    format: (value) => value.supplier_offer_id,
  },
  {
    id: 'name',
    label: 'Название',
    minWidth: 100,
    filter: <SearchName />,
    format: (value) => (
      <a
        href={`/products/${value.id}`}
        target='_blank'
        rel='noreferrer noopener'
        className={classes.productName}
        title={value.name}
      >
        {value.name}
      </a>
    ),
  },
  {
    id: 'categoryId',
    label: 'Категория',
    width: 150,
    filter: <CategoryList />,
    format: (value) => (
      <div className={classes.productName} title={value.category_name}>
        {value.category_name}
      </div>
    ),
  },
  {
    id: 'status',
    label: 'Статус',
    width: 110,
    filter: <StatusFilter />,
    format: RenderStatus,
  },
  {
    id: 'supplier_stocks',
    label: 'Остаток',
    align: 'right',
    width: 110,
    filter: <IsAvailableSelect />,
    format: renderStock,
  },
  {
    id: 'price',
    label: 'Цена',
    align: 'right',
    width: 100,
    filter: <PriceFilter />,
    format: (value) => {
      if (!value.base_price) return 'Неизвестно';

      return <span> {value.base_price.toFixed(2)} &#8381;</span>;
    },
  },
];

const Container = observer(() => {
  const firstRenderRef = useRef(true);
  const store = useProductListStore();

  const location = useLocation();

  const filterHistory = useFilterHistory();
  const pagination = usePagination();
  const order = useOrder();

  const handleRequestSort = (_: React.MouseEvent, orderBy: OrderByRows) => {
    const isAsc = order.orderBy === orderBy && order.order === 'asc';
    const type = isAsc ? 'desc' : 'asc';

    order.setOrder(type);
    order.setOrderBy(orderBy);
  };

  const handlePaginationChange = (value: number) => {
    pagination.setPage(value);
  };

  const loadData = async () => {
    const params = filterHistory.parse();

    const { data } = await ProductListService.getProducts({
      ...params,
      page: params.page ? params.page - 1 : undefined,
      limit: 50,
      order: params.order?.toUpperCase() as any,
    });

    store.setProducts(data.products);
    handlePaginationChange(data.metadata.page + 1);
    pagination.setPagesCount(data.metadata.totalPages);
  };

  const pageTitle = useMemo(() => `Каталог товаров | ${GLOBAL_TITLE}`, []);

  useEffect(() => {
    const wrapper = async () => await store.suppliersStore.loadSuppliers();

    wrapper();
  }, []);

  useEffect(() => {
    const params = filterHistory.parse();

    if (params.order) order.setOrder(params.order.toLowerCase() as any);
    if (params.orderBy) order.setOrderBy(params.orderBy);
    if (params.page) pagination.setPage(params.page);
  }, []);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    handlePaginationChange(1);
    // eslint-disable-next-line
  }, [
    order.order,
    order.orderBy,
    store.suppliersStore.selectedSupplierId,
    store.categoriesStore.selectedCategoryIds,
    store.filterStore.sku,
    store.filterStore.name,
    store.filterStore.supplierSku,
    store.filterStore.isAvailable,
    store.filterStore.priceFrom,
    store.filterStore.priceTo,
    store.filterStore.status,
  ]);

  useEffect(() => {
    const debounced = setTimeout(() => {
      filterHistory.updateParams({
        page: pagination.page,
        order: order.order.toUpperCase() as any,
        orderBy: order.orderBy as any,
        supplierId: store.suppliersStore.selectedSupplierId ?? undefined,
        categoryIds: store.categoriesStore.selectedCategoryIds,
        isAvailable: store.filterStore.isAvailable,
        sku: store.filterStore.sku,
        name: store.filterStore.name,
        supplierSku: store.filterStore.supplierSku,
        priceFrom: store.filterStore.priceFrom ?? undefined,
        priceTo: store.filterStore.priceTo ?? undefined,
        status: store.filterStore.status ?? undefined,
      });
    }, 150);

    return () => clearTimeout(debounced);
    // eslint-disable-next-line
  }, [
    order.order,
    order.orderBy,
    pagination.page,
    store.suppliersStore.selectedSupplierId,
    store.categoriesStore.selectedCategoryIds,
    store.filterStore.sku,
    store.filterStore.name,
    store.filterStore.supplierSku,
    store.filterStore.isAvailable,
    store.filterStore.priceFrom,
    store.filterStore.priceTo,
    store.filterStore.status,
  ]);

  useEffect(() => {
    const debounced = setTimeout(loadData, 50);

    return () => clearTimeout(debounced);
    // eslint-disable-next-line
  }, [location.search]);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Pagination
        sx={{ py: 2 }}
        {...pagination.bind}
        onChange={(e, num) => handlePaginationChange(num)}
        showFirstButton
        showLastButton
      />
      <TableContainer>
        <Table stickyHeader size='small'>
          <TableHeader
            columns={columns}
            order={order.order}
            orderBy={order.orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody sx={{ overflowY: 'scroll' }}>
            {store.products.map((product) => (
              <React.Fragment key={product.id}>
                <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.format(product)}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={10}
                  ></TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{ py: 2 }}
        {...pagination.bind}
        onChange={(e, num) => handlePaginationChange(num)}
        showFirstButton
        showLastButton
      />
    </Paper>
  );
});

const ProductList = observer(() => (
  <ContextProvider>
    <Container />
  </ContextProvider>
));

export default ProductList;

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { observer } from 'mobx-react';
import React, { useContext, useMemo } from 'react';
import { Line } from 'react-chartjs-2';

import { PriceHistoryDto } from '@common/models/Shop/Product';
import { PriceListContext } from '@features/PriceList/context';

import classes from './ProductAnalytics.module.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const formatter = new Intl.DateTimeFormat('ru', {
  month: 'long',
  day: 'numeric',
});

const getPricesRange = (data: PriceHistoryDto[]) => {
  const copy = [...data];
  copy.sort((a, b) => a.value - b.value);

  return [copy[0].value, copy[copy.length - 1].value];
};

export const PriceHistory = observer(() => {
  const store = useContext(PriceListContext);

  const chartTitle = useMemo(
    () => `История цен ${store.selectedConfiguration}`,
    [store.selectedConfiguration]
  );

  if (!store.analytics.priceHistory.length) return null;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: chartTitle,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let value = context.parsed.y;

            return value + ' \u20BD';
          },
        },
      },
    },
  };

  const labels = store.analytics.priceHistory.map((x) =>
    formatter.format(new Date(x.timestamp))
  );

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        data: store.analytics.priceHistory.map((x) => x.value),
        borderColor: 'rgb(231, 195, 49)',
        backgroundColor: 'rgba(241, 217, 64, 0.5)',
      },
    ],
  };

  const [minPrice, maxPrice] = getPricesRange(store.analytics.priceHistory);

  return (
    <div className={classes.priceHistoryBlock}>
      <div className={classes.title}>
        <b>История цены</b>
        <span className={classes.range}>
          От {minPrice} &#8381; до {maxPrice} &#8381;
        </span>
      </div>
      <Line options={options} data={data} />
    </div>
  );
});

import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import {
  NAME_ATTRIBUTE_ID,
  SKU_ATTRIBUTE_ID,
} from '@features/AddProductForm/constants';
import { AddProductFormContext } from '@features/AddProductForm/context';

import { SmartInput } from '../../SmartInput';
import { CategoryDrawer } from './CategoryDrawer';
import classes from './ProductInfoStep.module.css';

export const ProductInfoStep = observer(() => {
  const store = useContext(AddProductFormContext);

  const handleCategorySelect = async (id: number) => {
    try {
      const category = await store.categories.fetchCategoryWithParents(id);
      store.productInfo.setSelectedCategory(category);
    } catch (error) {}
  };

  return (
    <div className={classes.container}>
      <h2>Информация о товаре</h2>
      <SmartInput
        fullWidth
        label='Название'
        schema={store.attributes.get(NAME_ATTRIBUTE_ID)}
      />
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <SmartInput
          fullWidth
          label='Категория'
          required
          value={store.productInfo.selectedCategoryTitle}
          aria-readonly='true'
          InputProps={{ readOnly: true }}
        />
        <CategoryDrawer onConfirmClick={handleCategorySelect} />
      </div>
      <SmartInput fullWidth label='Штрихкод' />
      <SmartInput
        fullWidth
        label='Артикул'
        required
        schema={store.attributes.get(SKU_ATTRIBUTE_ID)}
      />
      <SmartInput fullWidth label='Цена в RUB' required />
      <SmartInput fullWidth label='НДС' required />
      <h4>Габариты и вес в упаковке</h4>
      <SmartInput fullWidth label='Вес с упаковкой, г' required />
      <SmartInput fullWidth label='Длина упаковки, мм' required />
      <SmartInput fullWidth label='Ширина упаковки, мм' required />
      <SmartInput fullWidth label='Высота упаковки, мм' required />
    </div>
  );
});

import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';

import { CancelIcon, EditIcon, SaveIcon } from '@common/ui/icons';
import { useProductStore } from '@features/ProductView/context';
import { TextField } from '@mui/material';

import { Section } from '../Section';
import classes from './DescriptionBlock.module.css';
import { useDescriptionInput } from './hooks';

export const DescriptionBlock = observer(() => {
  const store = useProductStore();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const descInput = useDescriptionInput();

  const description = useMemo(() => {
    if (!store.product?.description) return 'Описание отсутствует.';

    return store.product.description;
  }, [store.product?.description]);

  const handleEdit = () => {
    if (isBlocked) return;

    setIsEditMode(true);
  };

  const handleSave = async () => {
    if (isBlocked) return;

    try {
      setIsBlocked(true);
      await store.saveChanges();
    } catch {
    } finally {
      setIsEditMode(false);
      setIsBlocked(false);
    }
  };

  const handleCancel = async () => {
    if (isBlocked) return;

    try {
      setIsBlocked(true);
      await store.revertChanges();
    } catch {
    } finally {
      setIsEditMode(false);
      setIsBlocked(false);
    }
  };

  if (!store.product) return null;

  return (
    <Section
      title='Описание'
      titleMenu={
        isEditMode ? (
          <React.Fragment>
            <SaveIcon
              title='Сохранить изменения'
              onClick={handleSave}
              disabled={isBlocked}
            />
            <CancelIcon
              title='Отменить изменения'
              onClick={handleCancel}
              disabled={isBlocked}
            />
          </React.Fragment>
        ) : (
          <EditIcon
            title='Изменить описание'
            onClick={handleEdit}
            disabled={isBlocked}
          />
        )
      }
    >
      {isEditMode ? (
        <TextField multiline fullWidth {...descInput} disabled={isBlocked} />
      ) : (
        <div className={classes.description}>{description}</div>
      )}
    </Section>
  );
});

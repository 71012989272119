import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { forwardRef, useContext, useEffect, useState } from 'react';

import {
  PickListItemDto,
  PickListItemProductDto,
} from '@common/api/ClientFeatures';
import {
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';

import { PickListContext } from '../context';
import classes from '../PickList.module.css';
import { PickListStatus } from '../store';

interface TabData {
  status: PickListStatus;
  label: string;
}

const values = { all: 0, today: 1, tomorrow: 2, later: 3 };

const tabs: TabData[] = [
  {
    status: 'all',
    label: 'Все',
  },
  {
    status: 'today',
    label: 'На сегодня',
  },
  {
    status: 'tomorrow',
    label: 'На завтра',
  },
  {
    status: 'later',
    label: 'Позже',
  },
];

export const DateTabBlock = observer(() => {
  const store = useContext(PickListContext);
  const [value, setValue] = useState(1);

  const handleChange = (_: any, newValue: any) => {
    setValue(newValue);

    store.yandex.setStatus(tabs[newValue].status);
  };

  useEffect(() => {
    setValue(values[store.yandex.status]);
  }, [store.yandex.status]);

  return (
    <Tabs value={value} onChange={handleChange}>
      {tabs.map((x) => (
        <Tab key={x.status} label={x.label} />
      ))}
    </Tabs>
  );
});

interface ColumnData {
  id: string;
  label: string;
  value: (
    posting: PickListItemDto,
    product: PickListItemProductDto
  ) => string | React.ReactNode;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
}

const renderDate = (date: string) => (
  <div>
    <div>{new Date(date).toLocaleDateString()}</div>
    <div className={classes.secondaryText}>
      {new Date(date).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}
    </div>
  </div>
);

const renderPostingNumber = (posting: PickListItemDto) => (
  <div className={clsx(posting.products.length > 1 && classes.importantCell)}>
    {posting.posting_number}
  </div>
);

const renderImage = (_: PickListItemDto, product: PickListItemProductDto) => (
  <img className={classes.image} src={product.image}></img>
);

const renderProductName = (
  posting: PickListItemDto,
  product: PickListItemProductDto
) => (
  <div className={clsx(posting.products.length > 1 && classes.importantCell)}>
    {product.name}
  </div>
);

const renderQuantity = (
  _: PickListItemDto,
  product: PickListItemProductDto
) => (
  <div>
    {product.quantity} {product.has_warehouse_stocks ? '(✓)' : null}
  </div>
);

const columns: ColumnData[] = [
  {
    id: 'posting_number',
    label: 'Номер отправления',
    value: renderPostingNumber,
    minWidth: 190,
  },
  {
    id: 'image',
    label: 'Изображение',
    value: renderImage,
  },
  {
    id: 'name',
    label: 'Название',
    value: renderProductName,
    minWidth: 200,
  },
  {
    id: 'name',
    label: 'Поставщик',
    value: (_, product) => product.supplier_name,
  },
  {
    id: 'name',
    label: 'Артикул поставщика',
    value: (_, product) => product.supplier_sku,
  },
  {
    id: 'quantity',
    label: 'Количество',
    value: renderQuantity,
  },
  {
    id: 'shipment_date',
    label: 'Дата отгрузки',
    value: (posting) => renderDate(posting.shipment_date),
  },
];

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 12,
  fontWeight: 'bold',
  lineHeight: 1.5,
}));

type TableHeaderColumnProps = {
  label: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right';
};

const TableHeaderColumn: React.FC<TableHeaderColumnProps> = (props) => {
  return (
    <StyledTableCell
      align='left'
      style={{
        maxWidth: props.maxWidth,
        minWidth: props.minWidth,
        width: props.width,
      }}
    >
      {props.label}
    </StyledTableCell>
  );
};

const todayDeadline = new Date().setUTCHours(16, 0, 0, 0);
const tomorrowDeadline = todayDeadline + 24 * 60 * 60 * 1000;

export const OrderTable = observer(
  forwardRef<null>(({}, ref) => {
    const store = useContext(PickListContext);

    const postingsFilter = (order: PickListItemDto) => {
      const timestamp = new Date(order.shipment_date).getTime();

      switch (store.yandex.status) {
        case 'all':
          return true;
        case 'today':
          return timestamp <= todayDeadline;
        case 'tomorrow':
          return timestamp >= todayDeadline && timestamp <= tomorrowDeadline;
        case 'later':
          return timestamp >= tomorrowDeadline;
      }
    };

    const postingsSort = (a: PickListItemDto, b: PickListItemDto) => {
      return (
        new Date(a.shipment_date).getTime() -
        new Date(b.shipment_date).getTime()
      );
    };

    return (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <TableContainer>
          <Table stickyHeader size='small' ref={ref}>
            <TableHead>
              <TableRow style={{ height: 50 }}>
                {columns.map((column) => (
                  <TableHeaderColumn key={column.label} {...column} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflowY: 'scroll' }}>
              {store.yandex.postings.filter(postingsFilter).length ? (
                store.yandex.postings
                  .filter(postingsFilter)
                  .sort(postingsSort)
                  .map((posting) =>
                    posting.products.map((product) => (
                      <TableRow hover key={posting.order_id + product.offer_id}>
                        {columns.map((column) => (
                          <TableCell key={column.label} align='left'>
                            {column.value(posting, product)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  )
              ) : (
                <TableRow>
                  <TableCell colSpan={100} align='center'>
                    Нет записей
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  })
);

export const YandexPickList = observer(
  forwardRef<null>(({}, ref) => {
    const store = useContext(PickListContext);

    const fetchPickList = async () => {
      await store.yandex.fetchPickList();
    };

    useEffect(() => {
      fetchPickList();
    }, []);

    return (
      <>
        <DateTabBlock />
        <OrderTable ref={ref} />
      </>
    );
  })
);

import axios, { AxiosRequestConfig, isAxiosError } from 'axios';

import { AuthResponse } from '@common/models/Response';
import { getToken, setToken } from '@common/util';
import { AuthRoutes } from '@common/api/Auth';

const $api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 5 * 60 * 1000,
});

const $authApi = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 5 * 60 * 1000,
});

const addAuthorizationHeader = (config: AxiosRequestConfig, token: string) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  } as any;
};

// Добавление accessToken в запрос
const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const token = getToken();

  if (token) addAuthorizationHeader(config, token);

  return config;
};

$authApi.interceptors.request.use(authRequestInterceptor);

// Повторение реквеста в случае если токен просрочен
const authOnErrorInterceptor = async (error: any) => {
  if (!isAxiosError(error)) throw error;

  const originalRequest = error.config;

  if (
    error.response &&
    error.response.status === 401 &&
    originalRequest &&
    !(originalRequest as any)._isRetry
  ) {
    // Предотвращение циклического запроса
    (originalRequest as any)._isRetry = true;

    const { data } = await $api.get<AuthResponse>(AuthRoutes.REFRESH_ROUTE);

    setToken(data.accessToken);

    // Добавление нового токена в запрос
    addAuthorizationHeader(originalRequest, data.accessToken);

    return $api.request(originalRequest);
  }

  throw error;
};

$authApi.interceptors.response.use((config) => config, authOnErrorInterceptor);

export { $api, $authApi };

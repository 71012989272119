import React, { useContext, useEffect, useState } from 'react';

import { PriceListContext } from '@features/PriceList/context';
import { Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react';
import { MarketplaceEnum } from '@common/constants';

interface TabData {
  id: MarketplaceEnum;
  label: string;
}

const tabs: TabData[] = [
  {
    id: MarketplaceEnum.OZON,
    label: 'Ozon Seller',
  },
  {
    id: MarketplaceEnum.YANDEX,
    label: 'Yandex Market',
  },
  {
    id: MarketplaceEnum.WILDBERRIES,
    label: 'Wildberries',
  },
];

export const MarketplaceTabs = observer(() => {
  const store = useContext(PriceListContext);

  const [value, setValue] = useState(0);

  const handleChange = (_: any, newValue: any) => {
    setValue(newValue);

    store.setFilter({
      ...store.filter,
      marketplace_id: tabs[newValue].id,
    });
  };

  useEffect(() => {
    setValue(tabs.findIndex((x) => x.id === store.filter.marketplace_id));
  }, [store.filter.marketplace_id]);

  return (
    <Tabs value={value} onChange={handleChange}>
      {tabs.map((x) => (
        <Tab key={x.label} label={x.label} />
      ))}
    </Tabs>
  );
});

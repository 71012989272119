import React from 'react';

import { useInput } from '@common/hooks';

export const AuthContext = React.createContext<{
  email: ReturnType<typeof useInput>;
  password: ReturnType<typeof useInput>;
}>({} as any);

type Props = {
  children?: React.ReactNode;
};

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const email = useInput();
  const password = useInput();

  return (
    <AuthContext.Provider value={{ email, password }}>
      {children}
    </AuthContext.Provider>
  );
};

import { observer } from 'mobx-react';
import React, { FC, useContext, useState } from 'react';

import { ShopViewContext } from '@features/ShopView/context';
import { ShopViewTabData } from '@features/ShopView/ShopView';
import { Tab, Tabs } from '@mui/material';

import { ShopViewTabEnum } from '../../store';

interface TabBlockProps {
  defaultTab?: ShopViewTabEnum;
  tabs: ShopViewTabData[];
}

export const TabBlock: FC<TabBlockProps> = observer(
  ({ tabs, defaultTab = ShopViewTabEnum.GENERAL }) => {
    const store = useContext(ShopViewContext);
    const [value, setValue] = useState(defaultTab);

    const handleChange = (_: any, newValue: any) => {
      setValue(newValue);

      store.setCurrentTab(tabs[newValue].id);
    };

    return (
      <Tabs value={value} onChange={handleChange}>
        {tabs.map((x) => (
          <Tab key={x.id} label={x.label} />
        ))}
      </Tabs>
    );
  }
);

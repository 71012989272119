import { observer } from 'mobx-react';
import React, { FC, useContext } from 'react';

import { MarketplaceIcons } from '@common/constants';
import { ShopViewContext } from '@features/ShopView/context';

import classes from './HeaderBlock.module.css';

export const HeaderBlock: FC = observer(() => {
  const store = useContext(ShopViewContext);

  if (!store.shop) return null;

  return (
    <div className={classes.container}>
      <div className={classes['item-container']}>
        <h2>{store.shop.name}</h2>
        {store.shop.marketplaces
          .filter((x) => x.is_enabled)
          .map((x) => MarketplaceIcons.find((y) => y.id === x.id)!)
          .filter((x) => x)
          .map((x) => (
            <img
              key={x.id}
              className={classes.badge}
              src={x.icon}
              title={x.label}
            />
          ))}
      </div>
    </div>
  );
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FeaturesBlock_container__6tB-y {\n  display: grid;\n  grid-template-columns: minmax(max-content, 35%) 1fr;\n  grid-auto-flow: row dense;\n\n  gap: 0.5rem 0.75rem;\n}\n\n.FeaturesBlock_blockTitle__KdPOm {\n  grid-column: 1 / 3;\n  padding: 0.5rem 0 0;\n\n  text-transform: capitalize;\n  font-weight: 500;\n}\n\n.FeaturesBlock_emptyFeature__JvZaG {\n  grid-column: 1 / 3;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ProductView/components/FeaturesBlock/FeaturesBlock.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mDAAmD;EACnD,yBAAyB;;EAEzB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;;EAEnB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: minmax(max-content, 35%) 1fr;\n  grid-auto-flow: row dense;\n\n  gap: 0.5rem 0.75rem;\n}\n\n.blockTitle {\n  grid-column: 1 / 3;\n  padding: 0.5rem 0 0;\n\n  text-transform: capitalize;\n  font-weight: 500;\n}\n\n.emptyFeature {\n  grid-column: 1 / 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FeaturesBlock_container__6tB-y",
	"blockTitle": "FeaturesBlock_blockTitle__KdPOm",
	"emptyFeature": "FeaturesBlock_emptyFeature__JvZaG"
};
export default ___CSS_LOADER_EXPORT___;

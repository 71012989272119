import AuthStore from '@features/Auth/store';
import { ShopStore } from './ShopStore';

export default class RootStore {
  readonly auth: AuthStore;
  readonly shops: ShopStore;

  constructor() {
    this.auth = new AuthStore();
    this.shops = new ShopStore();
  }
}

import { $authApi } from '@common/http';

import { WBPickListItemDto, WBSupplyDto } from './dto';

export class WBOrdersApiService {
  static async getSupplies(
    shopId: number,
    params: {
      page: number;
      done?: boolean;
    }
  ) {
    return $authApi.get<WBSupplyDto[]>(`wb/supplies/shop/${shopId}`, {
      params,
    });
  }

  static async getPickList(supplyId: string, shopId: number) {
    return $authApi.get<WBPickListItemDto[]>(
      `wb/supplies/${supplyId}/shop/${shopId}/orders`
    );
  }
}

import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { ShopViewContext } from '@features/ShopView/context';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, Switch } from '@mui/material';

import { KeyValue } from '../KeyValue';
import { Section } from '../Section';
import classes from './GeneralOptionsBlock.module.css';

export const GeneralOptionsBlock = observer(() => {
  const store = useContext(ShopViewContext);

  const handleDeleteShop = async () => {
    const isConfirmed = window.confirm(
      `Вы уверены, что хотите удалить магазин '${store.shop!.name}'?`
    );

    if (!isConfirmed) return;

    await store.deleteShop();
  };

  if (!store.shop) return null;

  return (
    <Section title='Общие Настройки'>
      <div className={classes.container}>
        <KeyValue name={'Обновлять цены товаров'}>
          <Switch
            checked={store.shop.updatePrices}
            onChange={(_, checked) => store.updatePrices(checked)}
          />
        </KeyValue>
        <KeyValue name={'Обновлять остатки товаров'}>
          <Switch
            checked={store.shop.updateStocks}
            onChange={(_, checked) => store.updateStocks(checked)}
          />
        </KeyValue>
        <div className={classes['danger-settings']}>Опасные настройки</div>
        <Button
          endIcon={<DeleteForeverIcon />}
          variant='contained'
          onClick={handleDeleteShop}
        >
          Удалить магазин
        </Button>
      </div>
    </Section>
  );
});

import { observer } from 'mobx-react';
import React from 'react';

import Checkbox from '@mui/material/Checkbox';

import { useFilterStore } from '../context';

export const IsAvailableSelect = observer(() => {
  const store = useFilterStore();

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Checkbox
        checked={store.isAvailable}
        onClick={() => store.setIsAvailable(!store.isAvailable)}
      />
    </div>
  );
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainPage_container__Lm-lH {\n  position: relative;\n  height: 100vh;\n  display: grid;\n  grid-template:\n    'header header' 50px\n    'nav content' 1fr\n    / 200px 1fr;\n}\n\n.MainPage_header__8fIjF {\n  position: sticky;\n  top: 0;\n  z-index: 1000;\n  grid-area: header;\n  background-color: #2196f3;\n  text-align: center;\n}\n\n.MainPage_navbar__3rJy0 {\n  position: sticky;\n  top: 50px;\n  z-index: 1000;\n  grid-area: nav;\n  background-color: #616161;\n  max-height: calc(100vh - 50px);\n\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  padding-top: 3rem;\n}\n\n.MainPage_content__TXWn4 {\n  background-color: #eaeaea;\n\n  position: relative;\n  grid-area: content;\n  overflow-x: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/MainPage/MainPage.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb;;;eAGa;AACf;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,aAAa;EACb,cAAc;EACd,yBAAyB;EACzB,8BAA8B;;EAE9B,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;;EAEzB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".container {\n  position: relative;\n  height: 100vh;\n  display: grid;\n  grid-template:\n    'header header' 50px\n    'nav content' 1fr\n    / 200px 1fr;\n}\n\n.header {\n  position: sticky;\n  top: 0;\n  z-index: 1000;\n  grid-area: header;\n  background-color: #2196f3;\n  text-align: center;\n}\n\n.navbar {\n  position: sticky;\n  top: 50px;\n  z-index: 1000;\n  grid-area: nav;\n  background-color: #616161;\n  max-height: calc(100vh - 50px);\n\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  padding-top: 3rem;\n}\n\n.content {\n  background-color: #eaeaea;\n\n  position: relative;\n  grid-area: content;\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MainPage_container__Lm-lH",
	"header": "MainPage_header__8fIjF",
	"navbar": "MainPage_navbar__3rJy0",
	"content": "MainPage_content__TXWn4"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import { ShopViewStore } from '../store';

const store = new ShopViewStore();

export const ShopViewContext = React.createContext<ShopViewStore>(store);

type Props = {
  children?: React.ReactNode;
};

export const ContextProvider: React.FC<Props> = ({ children }) => (
  <ShopViewContext.Provider value={store}>{children}</ShopViewContext.Provider>
);

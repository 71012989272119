// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotFound_container__seMkv {\n  display: flex;\n  padding: 2rem;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n\n.NotFound_message__NkMhS {\n  padding-top: 10%;\n}\n", "",{"version":3,"sources":["webpack://./src/common/components/NotFound/NotFound.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  padding: 2rem;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n\n.message {\n  padding-top: 10%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NotFound_container__seMkv",
	"message": "NotFound_message__NkMhS"
};
export default ___CSS_LOADER_EXPORT___;

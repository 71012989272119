import { observer } from 'mobx-react';
import React, { FC, useContext, useEffect } from 'react';

import { ShopViewContext } from '@features/ShopView/context';
import { useYandexCreds } from '@features/ShopView/hooks';
import { Button, Switch, TextField } from '@mui/material';

import { KeyValue } from '../KeyValue';
import { Section } from '../Section';
import classes from './YandexBlock.module.css';

interface YandexBlockProps {
  shopId: number;
}

const apiKeySkeleton = '11111111-2222-3333-4444-555555555555';

export const YandexBlock: FC<YandexBlockProps> = observer(({ shopId }) => {
  const store = useContext(ShopViewContext);
  const creds = useYandexCreds(shopId);

  useEffect(() => {
    store.yandex.fetchShopData(shopId);
  }, []);

  if (!store.shop) return null;
  if (!store.yandex.data) return null;

  return (
    <Section title='Yandex Market'>
      <div className={classes.container}>
        <KeyValue name={'Включить Yandex Market'}>
          <Switch
            checked={store.yandex.data.isEnabled}
            onChange={(_, checked) =>
              store.yandex.changeStatus(shopId, checked)
            }
          />
        </KeyValue>
        <div className={classes['block-title']}>API Ключ</div>
        <KeyValue name={'ID кампании'}>
          <TextField
            value={
              creds.isEdit
                ? creds.campaignId.value
                : store.yandex.data?.campaignId ?? ''
            }
            onChange={creds.campaignId.bind.onChange}
            InputProps={{
              readOnly: !creds.isEdit,
              autoComplete: 'off',
            }}
          />
        </KeyValue>
        <KeyValue name={'Токен'}>
          <TextField
            value={
              creds.isEdit
                ? creds.token.value
                : store.yandex.data?.campaignId
                ? apiKeySkeleton
                : ''
            }
            onChange={creds.token.bind.onChange}
            type={creds.isEdit ? 'text' : 'password'}
            InputProps={{
              readOnly: !creds.isEdit,
              autoComplete: 'off',
            }}
          />
        </KeyValue>
        <div className={classes['block-title']}>Склад</div>
        <KeyValue name={'Идентификатор склада'}>
          <TextField
            value={store.yandex.data.warehouses.at(0) ?? ''}
            onChange={creds.campaignId.bind.onChange}
            InputProps={{
              readOnly: true,
              autoComplete: 'off',
            }}
          />
        </KeyValue>
      </div>
    </Section>
  );
});

import { useContext, useState } from 'react';

import { useInput } from '@common/hooks';

import { ShopViewContext } from '../context';

export const useOzonCreds = (shopId: number) => {
  const store = useContext(ShopViewContext);

  const [isEdit, setEdit] = useState(false);
  const [isBlocked, setBlocked] = useState(false);

  const clientId = useInput();
  const apiKey = useInput();

  const handleEdit = () => {
    setEdit(true);
  };

  const handleSave = async () => {
    setBlocked(true);

    try {
      await store.ozon.linkToShop({
        shopId,
        clientId: clientId.value,
        apiKey: apiKey.value,
      });

      clientId.setValue('');
      apiKey.setValue('');
    } catch {
    } finally {
      setBlocked(false);
    }
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleUnlink = async () => {
    const isConfirmed = window.confirm(
      `Вы уверены, что хотите отвязать магазин от Ozon?`
    );

    if (!isConfirmed) return;

    await store.ozon.unlinkFromShop({ shopId });
  };

  return {
    isEdit,
    setEdit,
    isBlocked,
    clientId,
    apiKey,
    handleEdit,
    handleSave,
    handleCancel,
    handleUnlink,
  };
};

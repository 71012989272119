import { makeAutoObservable } from 'mobx';

import { SupplierDto } from '@common/models/Response/Supplier';

import ProductListService from '../service';

export class SuppliersStore {
  private _suppliers: SupplierDto[] = [];
  private _selectedSupplierId: number | null = null;

  get suppliers(): SupplierDto[] {
    return this._suppliers;
  }

  get selectedSupplierId(): number | null {
    return this._selectedSupplierId;
  }

  constructor() {
    makeAutoObservable(this);
  }

  setSuppliers(value: SupplierDto[]) {
    this._suppliers = value;
  }

  selectSupplierId(value: number | null) {
    this._selectedSupplierId = value;
  }

  async loadSuppliers() {
    try {
      const { data } = await ProductListService.getSuppliers();

      this.setSuppliers(data.suppliers);
    } catch (error) {}
  }
}

import React from 'react';

import classes from './NotFound.module.css';

const NotFound = () => {
  return (
    <div className={classes.container}>
      <h3 className={classes.message}>404 Страница не найдена</h3>
    </div>
  );
};

export default NotFound;

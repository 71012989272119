export const enum MarketplaceEnum {
  SHOP = 0,
  OZON = 1,
  YANDEX = 2,
  WILDBERRIES = 3,
}

export const MarketplaceIcons = [
  {
    id: MarketplaceEnum.OZON,
    icon: '/ozon-icon.png',
    label: 'Ozon Seller',
  },
  {
    id: MarketplaceEnum.YANDEX,
    icon: '/yandex-icon.png',
    label: 'Yandex Market',
  },
  {
    id: MarketplaceEnum.WILDBERRIES,
    icon: '/wb-icon.png',
    label: 'Wildberries',
  },
];

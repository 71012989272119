import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';

import { Tab, Tabs } from '@mui/material';

import { PostingListContext } from '../context';
import { PostingListFilterStatus } from '../dto';

interface TabData {
  status: PostingListFilterStatus | null;
  label: string;
}

const tabs: TabData[] = [
  {
    status: null,
    label: 'Все отправления',
  },
  {
    status: 'awaiting_packaging',
    label: 'Ожидают сборки',
  },
  {
    status: 'awaiting_deliver',
    label: 'Ожидают отгрузки',
  },
  {
    status: 'delivering',
    label: 'Доставляются',
  },
  {
    status: 'delivered',
    label: 'Доставлены',
  },
  {
    status: 'cancelled',
    label: 'Отменены',
  },
];

export const TabBlock = observer(() => {
  const store = useContext(PostingListContext);
  const [value, setValue] = useState(0);

  const handleChange = (_: any, newValue: any) => {
    setValue(newValue);
    store.setCurrentStatus(tabs[newValue].status);
  };

  return (
    <Tabs value={value} onChange={handleChange}>
      {tabs.map((x) => (
        <Tab key={x.status} label={x.label} />
      ))}
    </Tabs>
  );
});

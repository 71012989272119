import { observer } from 'mobx-react';
import React from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';

import { useCategoriesStore } from '../context';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

interface OptionsProps {
  parentId?: number | null;
  depth?: number;
}

const Options: React.FC<OptionsProps> = observer(
  ({ parentId = null, depth = 0 }) => {
    const store = useCategoriesStore();

    const isChecked = (categoryId: number) =>
      store.selectedCategoryIds.includes(categoryId);

    const checkCategory = (categoryId: number) => {
      store.selectCategory(categoryId);
    };

    const isOptional = (categoryId: number): boolean => {
      const deps = store.categories.filter((x) => x.parentId === categoryId);

      if (!deps.length) return false;

      if (deps.every((x) => isChecked(x.id))) return false;

      return deps.some((x) => isChecked(x.id) || isOptional(x.id));
    };

    const children = store.categories.filter((c) => c.parentId === parentId);

    if (!children.length) return null;

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children.map((category) => (
          <React.Fragment key={category.id}>
            <MenuItem
              disabled={category.productsCount === 0}
              value={JSON.stringify(category.name)}
              sx={{ height: ITEM_HEIGHT, pl: 1 + depth * 2 }}
              onClick={() => checkCategory(category.id)}
            >
              <Checkbox
                checked={isChecked(category.id)}
                indeterminate={isOptional(category.id)}
              />
              <ListItemText
                primary={`${category.name} (${category.productsCount})`}
              />
            </MenuItem>
            <Options parentId={category.id} depth={depth + 1} />
          </React.Fragment>
        ))}
      </Box>
    );
  }
);

export const CategoryList = observer(() => {
  const store = useCategoriesStore();

  const renderSelected = () => {
    const categories = store.categories.filter((x) =>
      store.selectedCategoryIds.includes(x.id)
    );

    return categories
      .map((c) => c.name)
      .join(', ')
      .slice(0, 50);
  };

  return (
    <FormControl sx={{ width: 220 }}>
      <Select
        id='categories-input'
        multiple
        value={store.selectedCategoryIds}
        MenuProps={MenuProps}
        sx={{ overflowWrap: 'break-word', height: ITEM_HEIGHT }}
        renderValue={() => renderSelected()}
        title={renderSelected()}
      >
        <MenuItem onClick={() => store.clearSelectedCategories()}>
          <ListItemText primary='Очистить' />
        </MenuItem>
        <Options />
      </Select>
    </FormControl>
  );
});

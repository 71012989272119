// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotActivatedPage_container__GTTPR {\n  position: relative;\n  height: 100vh;\n  display: grid;\n  grid-template:\n    'header' 50px\n    'content' 1fr\n    / 1fr;\n}\n\n.NotActivatedPage_header__nZxmN {\n  position: sticky;\n  top: 0;\n  z-index: 1000;\n  grid-area: header;\n  background-color: #2196f3;\n  text-align: center;\n}\n\n.NotActivatedPage_content__2eSV0 {\n  flex: 1 1;\n  grid-area: content;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/NotActivatedPage/NotActivatedPage.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb;;;SAGO;AACT;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,SAAO;EACP,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".container {\n  position: relative;\n  height: 100vh;\n  display: grid;\n  grid-template:\n    'header' 50px\n    'content' 1fr\n    / 1fr;\n}\n\n.header {\n  position: sticky;\n  top: 0;\n  z-index: 1000;\n  grid-area: header;\n  background-color: #2196f3;\n  text-align: center;\n}\n\n.content {\n  flex: 1;\n  grid-area: content;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NotActivatedPage_container__GTTPR",
	"header": "NotActivatedPage_header__nZxmN",
	"content": "NotActivatedPage_content__2eSV0"
};
export default ___CSS_LOADER_EXPORT___;

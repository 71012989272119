import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { styled, Tab, Tabs, TabTypeMap, Typography } from '@mui/material';

import { useRouteMatch } from './hooks';

const a11yProps = (index: number) => {
  return {
    id: `navbar-tab-${index}`,
    'aria-controls': `navbar-tabpanel-${index}`,
  };
};

export type LinkTabProps = {
  label: string;
  href: string;
  icon?: TabTypeMap['props']['icon'];
};
const LinkTab = styled((props: LinkTabProps) => (
  <Tab
    disableRipple
    component={Link}
    {...props}
    to={props.href}
    iconPosition='start'
  />
))(({ theme }) => ({
  borderRadius: 8,
  color: theme.palette.primary.contrastText,
  textTransform: 'none',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: 40,
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
    backgroundColor: '#fff6',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export interface NavBarProps {
  title?: string;
  tabs: LinkTabProps[];
}

const NavBar: FC<NavBarProps> = ({ tabs, title }) => {
  const routeMatch = useRouteMatch(tabs.map((x) => x.href));
  const currentTab = routeMatch?.pattern?.path;
  const tabIndex = tabs.findIndex((x) => x.href === currentTab);

  return (
    <Tabs
      value={tabIndex >= 0 ? tabIndex + 1 : false}
      orientation='vertical'
      TabIndicatorProps={{ sx: { opacity: 0 } }}
    >
      {title ? (
        <Typography color={'#bcbcbc'} pl={2} pb={1} variant='body2'>
          {title}
        </Typography>
      ) : null}
      {tabs.map((props, index) => (
        <LinkTab {...props} {...a11yProps(index)} key={index} />
      ))}
    </Tabs>
  );
};

export default NavBar;

import { makeAutoObservable } from 'mobx';

import {
  ClientFeaturesApiService,
  ClientShopDto,
  OzonWarehouseDto,
} from '@common/api/ClientFeatures';

import { PickListStore } from './PickListStore';

function rostovFilter(warehouse: OzonWarehouseDto) {
  return warehouse.name.toLowerCase().includes('ростов');
}

export class ClientPickListsStore {
  private _shops: ClientShopDto[] = [];
  private _shopId: number | null = null;

  private _warehouses: OzonWarehouseDto[] = [];
  private _selectedWarehouses: number[] = [];

  private _list: PickListStore;

  private _loading: boolean = false;

  get shopId() {
    return this._shopId;
  }

  get shops() {
    return this._shops;
  }

  get loading() {
    return this._loading;
  }

  get warehouses() {
    return this._warehouses;
  }

  get selectedWarehouses() {
    return this._selectedWarehouses;
  }

  get pickList(): PickListStore {
    return this._list;
  }

  constructor() {
    this._list = new PickListStore();

    makeAutoObservable(this);

    this.fetchShops();
  }

  setShopId(value: number | null) {
    this._shopId = value;
  }

  setShops(value: ClientShopDto[]) {
    this._shops = value;
  }

  setWarehouses(value: OzonWarehouseDto[]) {
    this._warehouses = value;
  }

  setSelectedWarehouses(value: number[]) {
    this._selectedWarehouses = value;
  }

  setLoading(value: boolean) {
    this._loading = value;
  }

  async fetchShops() {
    try {
      const { data } = await ClientFeaturesApiService.getClientShops();

      this.setShops(data);

      if (data.length) {
        this.setShopId(data[0].id);
      }
    } catch (error) {}
  }

  async fetchWarehouses(id: number) {
    try {
      const { data } = await ClientFeaturesApiService.getWarehouses(id);

      this.setWarehouses(data);
    } catch (error) {}
  }

  async fetchData() {
    const shopId = this.shopId;

    if (shopId === null) return;

    this.setLoading(true);

    await this.fetchWarehouses(shopId);

    this.setSelectedWarehouses(
      this.warehouses.filter(rostovFilter).map((x) => x.id)
    );

    await this.pickList.fetchPickList(shopId, this.selectedWarehouses);

    this.setLoading(false);
  }
}

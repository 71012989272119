import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useProductStore } from '@features/ProductView/context';
import { TextField } from '@mui/material';

import { KeyValue } from '../KeyValue';
import { useDimensions } from './hooks';

interface ProductDimensionsProps {
  isEditMode: boolean;
}

export const ProductDimensions: FC<ProductDimensionsProps> = observer(
  ({ isEditMode }) => {
    const store = useProductStore();
    const dimensions = useDimensions();

    if (!store.product) return null;

    return (
      <React.Fragment>
        <KeyValue name='Длина, см'>
          {isEditMode ? (
            <TextField
              {...dimensions.length.bind}
              size='small'
              inputProps={{ inputMode: 'decimal', step: 'any' }}
            />
          ) : (
            store.product.dimensions?.depth ?? 'Неизвестно'
          )}
        </KeyValue>
        <KeyValue name='Ширина, см'>
          {isEditMode ? (
            <TextField
              {...dimensions.width.bind}
              size='small'
              inputProps={{ inputMode: 'decimal', step: 'any' }}
            />
          ) : (
            store.product.dimensions?.width ?? 'Неизвестно'
          )}
        </KeyValue>
        <KeyValue name='Высота, см'>
          {isEditMode ? (
            <TextField
              {...dimensions.height.bind}
              size='small'
              inputProps={{ inputMode: 'decimal', step: 'any' }}
            />
          ) : (
            store.product.dimensions?.height ?? 'Неизвестно'
          )}
        </KeyValue>
        <KeyValue name='Вес, г'>
          {isEditMode ? (
            <TextField
              {...dimensions.weight.bind}
              size='small'
              inputProps={{ inputMode: 'decimal', step: 'any' }}
            />
          ) : (
            store.product.dimensions?.weight ?? 'Неизвестно'
          )}
        </KeyValue>
      </React.Fragment>
    );
  }
);

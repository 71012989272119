import { makeAutoObservable } from 'mobx';

import { AttributeDto } from '@common/models/Ozon/Category';

import { IGNORE_ATTRIBUTES } from '../constants';
import OzonCategoryService from '../service';

export class AttributesStore {
  private _attributes: AttributeDto[] = [];

  get attributes() {
    return this._attributes.filter((x) => !IGNORE_ATTRIBUTES.includes(x.id));
  }

  constructor() {
    makeAutoObservable(this);
  }

  get(id: number) {
    return this._attributes.find((x) => x.id === id);
  }

  setAttributes(values: AttributeDto[]) {
    this._attributes = values;
  }

  clear() {
    this._attributes = [];
  }

  async fetchAttributes(id: number) {
    try {
      const { data } = await OzonCategoryService.getAttributes(id);

      return data;
    } catch (error) {
      return null;
    }
  }
}

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { OrderByRows } from '@common/api/Shop/Product';

export const useOrder = () => {
  const [searchParams] = useSearchParams();

  const [order, setOrder] = useState<'asc' | 'desc'>(() => {
    const param = searchParams.get('order');
    if (!param) return 'asc';

    if (param === 'desc') return 'desc';

    return 'asc';
  });
  const [orderBy, setOrderBy] = useState<OrderByRows>(() => {
    const param = searchParams.get('orderBy');
    if (!param) return OrderByRows.id;

    if (!Object.values<string>(OrderByRows).includes(param))
      return OrderByRows.id;

    return param as OrderByRows;
  });

  return {
    order,
    setOrder,
    orderBy,
    setOrderBy,
  };
};

import { useInput } from '@common/hooks';
import { useProductStore } from '@features/ProductView/context';
import { useEffect } from 'react';

export const useQuantity = () => {
  const store = useProductStore();
  const input = useInput();

  useEffect(() => {
    if (!store.copy) return;

    input.setValue(store.copy.quantity.toString());
  }, [store.copy]);

  useEffect(() => {
    if (!store.copy) return;
    if (isNaN(Number(input.value))) return;

    store.copy.quantity = Number(input.value);
  }, [input.value]);

  return {
    bind: input.bind,
  };
};

import { makeAutoObservable, reaction } from 'mobx';

import {
  OrderType,
  ProductApiService,
  ProductPriceFilterDto,
  ProductPriceListDto,
  ProductStatusCountDto,
} from '@common/api/Shop/Product';

import { ProductAnalyticsStore } from './ProductAnalyticsStore';

type Filter = ProductPriceFilterDto &
  Required<Pick<ProductPriceFilterDto, 'order'>>;

export class PriceListStore {
  private _filter: Filter;
  private _prices: ProductPriceListDto | null;
  private _productCount: ProductStatusCountDto | null;
  private _selectedConfiguration: number | null;

  private _analytics: ProductAnalyticsStore;

  get prices() {
    return this._prices;
  }

  get productCount() {
    return this._productCount;
  }

  get filter() {
    return this._filter;
  }

  get selectedConfiguration() {
    return this._selectedConfiguration;
  }

  get analytics() {
    return this._analytics;
  }

  constructor() {
    this._prices = null;
    this._productCount = null;
    this._selectedConfiguration = null;
    this._analytics = new ProductAnalyticsStore();

    this._filter = {
      shop_id: 0,
      marketplace_id: 1,
      page: 0,
      limit: 50,
      order: ['id', OrderType.ASC],
    };

    makeAutoObservable(this);

    const resetFilter = () => {
      this.setSelectedConfiguration(null);
      this.setFilter({
        ...this.filter,
        page: 0,
      });
    };

    reaction(
      () => this.filter.marketplace_id,
      async () => {
        resetFilter();
        await this.fetchProductCount();
      }
    );
    reaction(
      () => this.filter.shop_id,
      async () => {
        resetFilter();
        await this.fetchProductCount();
      }
    );
    reaction(() => this.filter.product_status, resetFilter);

    reaction(
      () => this.selectedConfiguration,
      async (configuration_id) => {
        if (configuration_id)
          await this.analytics.fetchAnalytics(configuration_id);
      }
    );
  }

  setPrices(value: ProductPriceListDto) {
    this._prices = value;
  }

  setProductCount(value: ProductStatusCountDto) {
    this._productCount = value;
  }

  setFilter(value: Filter) {
    this._filter = value;
  }

  setSelectedConfiguration(value: number | null) {
    if (this._selectedConfiguration === value)
      this._selectedConfiguration = null;
    else this._selectedConfiguration = value;
  }

  async fetchProductPrices() {
    try {
      const { data } = await ProductApiService.getProductPrices(this._filter);

      this.setPrices(data);
    } catch (error) {}
  }

  async fetchProductCount() {
    try {
      const { data } = await ProductApiService.getProductCountByStatus(
        this._filter
      );

      this.setProductCount(data);
    } catch (error) {}
  }

  async acceptPrices(items: number[]) {
    try {
      await ProductApiService.acceptPrices(items);
      await this.fetchProductPrices();
      await this.fetchProductCount();
    } catch (error) {}
  }

  async rejectPrices(items: number[]) {
    try {
      await ProductApiService.rejectPrices(items);
      await this.fetchProductPrices();
      await this.fetchProductCount();
    } catch (error) {}
  }
}

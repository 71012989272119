import { observer } from 'mobx-react';
import React, { ChangeEvent, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { GLOBAL_TITLE } from '@common/constants';
import { StoreContext } from '@common/context';
import DoneIcon from '@mui/icons-material/Done';
import UploadIcon from '@mui/icons-material/Upload';
import { CircularProgress, IconButton } from '@mui/material';

import { ContextProvider, YandexIntegrationContext } from './context';
import classes from './YandexIntegration.module.css';

const Container = observer(() => {
  const shopStore = useContext(StoreContext).shops;
  const store = useContext(YandexIntegrationContext);

  const pageTitle = useMemo(() => `Загрузить остатки | ${GLOBAL_TITLE}`, []);

  const handleChangePrices = (e: ChangeEvent<HTMLInputElement>) => {
    store.prices.setFile(e.target.files?.item(0) ?? null);
  };

  const handleChangeStocks = (e: ChangeEvent<HTMLInputElement>) => {
    store.stocks.setFile(e.target.files?.item(0) ?? null);
  };

  const handleClickPrices = async () => {
    if (shopStore.selectedShop === null) return;

    await store.prices.uploadPrices(shopStore.selectedShop);
  };

  const handleClickStocks = async () => {
    if (shopStore.selectedShop === null) return;

    await store.stocks.uploadStocks(shopStore.selectedShop);
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <h2>Yandex Market</h2>
      <div className={classes.category}>
        <div>Сгенерировать файл с ценами:</div>
        <input
          className={classes.input}
          accept='.xlsm'
          type='file'
          onChange={handleChangePrices}
          disabled={store.prices.loading}
        />
        {store.prices.loading ? (
          <CircularProgress />
        ) : store.prices.loaded ? (
          <DoneIcon />
        ) : (
          <IconButton onClick={handleClickPrices}>
            <UploadIcon />
          </IconButton>
        )}
        {store.prices.error ? (
          <div className={classes.error}>{store.prices.error}</div>
        ) : null}
      </div>
      <div className={classes.category}>
        <div>Сгенерировать файл с остатками:</div>
        <input
          className={classes.input}
          accept='.xlsx'
          type='file'
          onChange={handleChangeStocks}
          disabled={store.stocks.loading}
        />
        {store.stocks.loading ? (
          <CircularProgress />
        ) : store.stocks.loaded ? (
          <DoneIcon />
        ) : (
          <IconButton onClick={handleClickStocks}>
            <UploadIcon />
          </IconButton>
        )}
        {store.stocks.error ? (
          <div className={classes.error}>{store.stocks.error}</div>
        ) : null}
      </div>
    </div>
  );
});

export const YandexIntegration = observer(() => (
  <ContextProvider>
    <Container />
  </ContextProvider>
));

import { useEffect } from 'react';

import { useFloatInput } from '@common/hooks';
import { useProductStore } from '@features/ProductView/context';

export const useDimensions = () => {
  const store = useProductStore();

  const length = useFloatInput();
  const width = useFloatInput();
  const height = useFloatInput();
  const weight = useFloatInput();

  // Initialize dimensions
  useEffect(() => {
    if (!store.copy) return;
    if (store.copy.dimensions) {
      length.setValue(store.copy.dimensions.depth.toFixed(1));
      width.setValue(store.copy.dimensions.width.toFixed(1));
      height.setValue(store.copy.dimensions.height.toFixed(1));
      weight.setValue(store.copy.dimensions.weight.toFixed(1));
      return;
    }

    store.copy.dimensions = {
      height: 0,
      depth: 0,
      weight: 0,
      width: 0,
      dimensionUnit: 'cm',
      weightUnit: 'g',
    };
  }, [store.copy]);

  useEffect(() => {
    if (isNaN(Number(length.value))) return;
    if (!store.copy?.dimensions) return;

    store.copy.dimensions.depth = Number(length.value);
  }, [length.value]);

  useEffect(() => {
    if (isNaN(Number(width.value))) return;
    if (!store.copy?.dimensions) return;

    store.copy.dimensions.width = Number(width.value);
  }, [width.value]);

  useEffect(() => {
    if (isNaN(Number(height.value))) return;
    if (!store.copy?.dimensions) return;

    store.copy.dimensions.height = Number(height.value);
  }, [height.value]);

  useEffect(() => {
    if (isNaN(Number(weight.value))) return;
    if (!store.copy?.dimensions) return;

    store.copy.dimensions.weight = Number(weight.value);
  }, [weight.value]);

  return {
    length,
    width,
    height,
    weight,
  };
};

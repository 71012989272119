import { useContext, useEffect, useMemo } from 'react';
import { PriceListContext } from '../context';

export const usePagination = () => {
  const store = useContext(PriceListContext);

  const page = useMemo(() => store.filter.page ?? 0, [store.filter.page]);
  const limit = useMemo(() => store.filter.limit ?? 0, [store.filter.limit]);
  const totalCount = useMemo(
    () => store.prices?.pagination.total_count ?? 0,
    [store.prices?.pagination]
  );

  const changePage = (newPage: number) => {
    store.setFilter({
      ...store.filter,
      page: newPage - 1,
    });
  };

  return {
    bind: {
      count: Math.floor(totalCount / limit),
      page: page + 1,
      onChange: (e: any, newPage: number) => changePage(newPage),
    },
  };
};

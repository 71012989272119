import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { CategoryDto } from '@common/models/Shop/Category';

import { useProductStore } from '../../context';
import { Section } from '../Section';
import classes from './SupplierDataBlock.module.css';

type BlockProps = {
  title: string;
  children: React.ReactNode;
};

const Block: React.FC<BlockProps> = ({ title, children }) => {
  return (
    <div className={classes.block}>
      <div className={classes.title}>{title}</div>
      <div>{children}</div>
    </div>
  );
};

type CategoryProps = {
  category: CategoryDto;
};

const Category: React.FC<CategoryProps> = ({ category }) => {
  const child = category.description ? (
    <a
      href={category.description}
      className={classes.categoryLink}
      title='Перейти на сайт поставщика'
    >
      {category.name}
    </a>
  ) : (
    <span>{category.name}</span>
  );

  if (category.parent)
    return (
      <span>
        <Category category={category.parent} />
        <span className={classes.delimiter}>{' > '}</span>
        {child}
      </span>
    );

  return child;
};

export const SupplierDataBlock = observer(() => {
  const store = useProductStore();

  const supplierProduct = useMemo(
    () => store.product?.supplierData,
    [store.product]
  );

  const description = useMemo(() => {
    if (!supplierProduct?.description) return 'Описание отсутствует.';

    return supplierProduct.description;
  }, [supplierProduct]);

  const beautifyDate = (date: string | undefined) => {
    if (!date) return null;

    return new Date(date).toLocaleString();
  };

  if (!store.product || !supplierProduct || !store.category) return null;

  return (
    <Section title='Данные от поставщика'>
      <Block title='Название'>{supplierProduct.name}</Block>
      <Block title='Описание'>
        <div className={classes.description}>{description}</div>
      </Block>
      <Block title='Категория'>
        <Category category={store.category} />
      </Block>
      <Block title='Артикул'>{supplierProduct.sku}</Block>
      <Block title='Товар на сайте поставщика'>
        <a href={supplierProduct.url}>{supplierProduct.url}</a>
      </Block>

      <Block title='В наличии'>
        <span
          title={`Обновлено ${beautifyDate(store.product.stocks?.updatedAt)}`}
        >
          {store.product.stocks?.supplier_stocks} шт.
        </span>
      </Block>
      <Block title='Дата создания'>
        {store.product.createdAt && beautifyDate(store.product.createdAt)}
      </Block>
    </Section>
  );
});

import { useContext, useState } from 'react';

import { useInput } from '@common/hooks';

import { ShopViewContext } from '../context';

export const useYandexCreds = (shopId: number) => {
  const store = useContext(ShopViewContext);

  const [isEdit, setEdit] = useState(false);
  const [isBlocked, setBlocked] = useState(false);

  const campaignId = useInput();
  const token = useInput();

  const handleEdit = () => {
    setEdit(true);
  };

  const handleSave = async () => {
    setBlocked(true);

    try {
      await store.yandex.linkToShop({
        shopId,
        campaignId: parseInt(campaignId.value),
        token: token.value,
      });

      campaignId.setValue('');
      token.setValue('');
    } catch {
    } finally {
      setBlocked(false);
    }
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleUnlink = async () => {
    const isConfirmed = window.confirm(
      `Вы уверены, что хотите отвязать магазин от Yandex?`
    );

    if (!isConfirmed) return;

    await store.yandex.unlinkFromShop({ shopId });
  };

  return {
    isEdit,
    setEdit,
    isBlocked,
    campaignId,
    token,
    handleEdit,
    handleSave,
    handleCancel,
    handleUnlink,
  };
};

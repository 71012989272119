import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Outlet } from 'react-router-dom';

import { LinkTabProps, NavBar } from '@common/components/NavBar';
import { GLOBAL_TITLE } from '@common/constants';
import { StoreContext } from '@common/context';
import { useAuthStore } from '@common/hooks';
import { UserStatus } from '@common/models/User';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import { NotActivatedPage } from '@pages/NotActivatedPage';

import classes from './MainPage.module.css';

type HeaderProps = {
  onLogoutClick: () => void;
};

export const Header: React.FC<HeaderProps> = observer((props) => {
  const store = useContext(StoreContext);

  const loadShopList = async () => {
    await store.shops.loadShopList();

    const userId = store.auth.user!.id;
    const shopId = await store.shops.fetchLastSelectedShop(userId);

    if (shopId !== null && store.shops.shops.find((x) => x.id === shopId)) {
      store.shops.setSelectedShop(shopId);
    } else {
      const selected = store.shops.shops[0].id;
      store.shops.setSelectedShop(selected);
      await store.shops.setLastSelectedShop(userId, selected);
    }
  };

  const handleSelectShop = async (shopId: number) => {
    store.shops.setSelectedShop(shopId);

    const userId = store.auth.user!.id;
    await store.shops.setLastSelectedShop(userId, shopId);
  };

  useEffect(() => {
    loadShopList();
  }, []);

  return (
    <Grid container direction='row' alignItems='center' height='100%' gap={1}>
      <Grid item width={250}>
        <Typography
          variant='h6'
          color={(theme) => theme.palette.primary.contrastText}
          style={{ textDecoration: 'none' }}
          component={Link}
          to={'/'}
        >
          {GLOBAL_TITLE}
        </Typography>
      </Grid>
      <Grid item xs></Grid>
      <Grid item>
        <Select
          value={store.shops.selectedShop ?? ''}
          renderValue={(x) =>
            store.shops.shops.find((x) => x.id === store.shops.selectedShop)
              ?.name ?? ''
          }
          sx={{ height: 40, color: 'white', p: 1 }}
        >
          {store.shops.shops.map((x) => (
            <MenuItem
              key={x.id}
              value={x.id}
              selected={store.shops.selectedShop === x.id}
              onClick={() => handleSelectShop(x.id)}
            >
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item pr={2}>
        <Button
          fullWidth
          onClick={props.onLogoutClick}
          style={{ color: '#fff' }}
        >
          Выйти
        </Button>
      </Grid>
    </Grid>
  );
});

const tabs: LinkTabProps[] = [
  { label: 'Заказы', href: '/postings', icon: <FactCheckIcon /> },
  { label: 'Каталог товаров', href: '/products', icon: <ListAltIcon /> },
  { label: 'Лист подбора', href: '/postings/list', icon: <ReceiptLongIcon /> },
];

const adminTabs: LinkTabProps[] = [
  { label: 'Остатки', href: '/import', icon: <InventoryIcon /> },
  {
    label: 'Цены',
    href: '/prices',
    icon: <AttachMoneyIcon />,
  },
  { label: 'Магазины', href: '/shops', icon: <StoreIcon /> },
];

const newFeaturesTabs: LinkTabProps[] = [
  // {
  //   label: 'Создать карточку',
  //   href: '/wb/card/create',
  //   icon: <InventoryIcon />,
  // },
  {
    label: 'Листы подбора',
    href: '/client/postings',
    icon: <ReceiptLongIcon />,
  },
];

const MainPage = () => {
  const auth = useAuthStore();

  if (!auth.user) return null;

  if (auth.user.status !== UserStatus.ACTIVATED) return <NotActivatedPage />;

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{GLOBAL_TITLE}</title>
      </Helmet>
      <div className={classes.header}>
        <Header onLogoutClick={() => auth.logout()} />
      </div>
      <div className={classes.navbar}>
        <NavBar tabs={tabs} title='Основное' />
        <NavBar tabs={adminTabs} title='Администрирование' />
        <NavBar tabs={newFeaturesTabs} title='Клиенты' />
      </div>
      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default observer(MainPage);

import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';

import Paper from '@mui/material/Paper';

import classes from './Section.module.css';

type SectionProps = {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  titleMenu?: React.ReactNode;
};

export const Section: React.FC<SectionProps> = observer(
  ({ className, children, title, titleMenu }) => {
    return (
      <Paper>
        <div className={clsx(classes.container, className)}>
          {title ? <div className={classes.title}>{title}</div> : null}
          {titleMenu ? (
            <div className={classes.buttons}>{titleMenu}</div>
          ) : null}
          <div className={clsx(classes.content)}>{children}</div>
        </div>
      </Paper>
    );
  }
);

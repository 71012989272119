import { useState } from 'react';

export const useIntegerInput = () => {
  const [value, setValue] = useState(0);

  const handleChange = (e: any) => {
    setValue(parseInt(e.target.value));
  };

  return {
    value,
    setValue,
    bind: {
      value,
      onChange: handleChange,
    },
  };
};

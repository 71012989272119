import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { Tab, Tabs } from '@mui/material';

import { PickListContext } from '../context';
import { PickListStatus } from '../store';

interface TabData {
  status: PickListStatus;
  label: string;
}

const values = { all: 0, today: 1, tomorrow: 2, later: 3 };

const tabs: TabData[] = [
  {
    status: 'all',
    label: 'Все',
  },
  {
    status: 'today',
    label: 'На сегодня',
  },
  {
    status: 'tomorrow',
    label: 'На завтра',
  },
  {
    status: 'later',
    label: 'Позже',
  },
];

export const DateTabBlock = observer(() => {
  const store = useContext(PickListContext);
  const [value, setValue] = useState(1);

  const handleChange = (_: any, newValue: any) => {
    setValue(newValue);

    store.pickList.setStatus(tabs[newValue].status);
  };

  useEffect(() => {
    setValue(values[store.pickList.status]);
  }, [store.pickList.status]);

  return (
    <Tabs value={value} onChange={handleChange}>
      {tabs.map((x) => (
        <Tab key={x.status} label={x.label} />
      ))}
    </Tabs>
  );
});

import { makeAutoObservable, observable } from 'mobx';

import {
  AttributeValueDto,
  GetAttributeValuesDto,
} from '@common/models/Ozon/Category';

import OzonCategoryService from '../service';

export class AttributeValuesStore {
  private readonly _values = observable.map<number, AttributeValueDto[]>({});

  get values() {
    return this._values;
  }

  constructor() {
    makeAutoObservable(this);
  }

  set(attributeId: number, values: AttributeValueDto[]) {
    this._values.set(attributeId, values);
  }

  get(attributeId: number) {
    return this._values.get(attributeId) || [];
  }

  clear() {
    this._values.clear();
  }
}

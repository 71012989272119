import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { AuthRoutes } from '@common/api/Auth';
import { StoreContext } from '@common/context';
import { getToken } from '@common/util';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';

import { AuthContext, AuthContextProvider } from './context';

const Input: React.FC<TextFieldProps> = (props) => (
  <TextField margin='normal' required fullWidth {...props} />
);

const Auth = observer(() => {
  const { auth } = useContext(StoreContext);
  const { email, password } = useContext(AuthContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const isLoginRoute = location.pathname === AuthRoutes.LOGIN_ROUTE;

  const onClick = async () => {
    if (isLoginRoute) {
      await auth.login({ email: email.value, password: password.value });
    } else {
      await auth.registration({ email: email.value, password: password.value });
    }

    if (auth.isAuth || auth.user) navigate(searchParams.get('redirect') || '/');
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') return;

    onClick();
  };

  useEffect(() => {
    if (!getToken()) return;

    auth.validateToken().then(() => {
      if (auth.isAuth || auth.user) {
        navigate(searchParams.get('redirect') || '/');
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    auth.setError('');
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (location.pathname === AuthRoutes.LOGOUT_ROUTE) {
      auth.logout().then(() => {
        navigate(searchParams.get('redirect') || '/');
      });
    }

    if (auth.isAuth || auth.user) {
      navigate(searchParams.get('redirect') || '/');
    }
    // eslint-disable-next-line
  }, [auth.isAuth, auth.user]);

  if (auth.isAuth || auth.user) return null;

  return (
    <Container maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <HttpsOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {isLoginRoute ? 'Войти в аккаунт' : 'Создать аккаунт'}
        </Typography>
        {auth.error ? (
          <Typography variant='body2' color='red' sx={{ mt: 1 }}>
            {auth.error}
          </Typography>
        ) : null}
        <Box component='form' noValidate sx={{ mt: 1 }}>
          <Input
            label='Email Address'
            autoComplete='email'
            autoFocus
            {...email.bind}
          />
          <Input
            label='Password'
            type='password'
            autoComplete='current-password'
            {...password.bind}
            onKeyDown={handleEnter}
          />
          <Button
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            onClick={onClick}
          >
            {isLoginRoute ? 'Войти' : 'Зарегистрироваться'}
          </Button>
          <Grid container flexDirection={'row-reverse'}>
            <Grid item>
              {isLoginRoute ? (
                <NavLink to={AuthRoutes.REGISTRATION_ROUTE}>
                  {'Нет аккаунта? Зарегистрируйтесь!'}
                </NavLink>
              ) : (
                <NavLink to={AuthRoutes.LOGIN_ROUTE}>
                  {'Уже есть аккаунт? Войдите!'}
                </NavLink>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
});

const AuthWithContext = () => (
  <AuthContextProvider>
    <Auth />
  </AuthContextProvider>
);

export default AuthWithContext;

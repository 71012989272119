// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShopList_container__0HvgD {\n  grid-area: content;\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ShopList/components/ShopList/ShopList.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf","sourcesContent":[".container {\n  grid-area: content;\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ShopList_container__0HvgD"
};
export default ___CSS_LOADER_EXPORT___;

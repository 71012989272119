import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useReactToPrint } from 'react-to-print';

import { GLOBAL_TITLE } from '@common/constants';

import classes from './ClientPickLists.module.css';
import { DateTabBlock } from './components/DateTabBlock';
import { OrderTable } from './components/OrderTable';
import { ShopList } from './components/ShopList';
import { WarehouseList } from './components/WarehouseList';
import { ContextProvider, PickListContext } from './context';

const Container = observer(() => {
  const store = useContext(PickListContext);
  const tableRef = useRef(null);

  const pageTitle = useMemo(() => `Лист подбора | ${GLOBAL_TITLE}`, []);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  useEffect(() => {
    const interval = setTimeout(async () => {
      await store.fetchData();
    }, 100);

    return () => clearInterval(interval);
  }, [store.shopId]);

  useEffect(() => {
    const interval = setTimeout(async () => {
      if (store.shopId === null) return;
      if (store.loading) return;

      await store.pickList.fetchPickList(
        store.shopId,
        store.selectedWarehouses
      );
    }, 100);

    return () => clearInterval(interval);
  }, [store.selectedWarehouses]);

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className={classes.container}>
        <h2>Листы подбора</h2>
        <div className={classes.tabs}>
          <DateTabBlock />
          <WarehouseList />
        </div>
        <div className={classes.data}>
          <OrderTable ref={tableRef} />
        </div>
        <div className={classes.print} onClick={handlePrint}>
          Распечатать
        </div>
        <div className={classes.side}>
          <ShopList />
        </div>
      </div>
    </div>
  );
});

export const ClientPickLists = observer(() => (
  <ContextProvider>
    <Container />
  </ContextProvider>
));

import { $authApi } from '@common/http';
import { PriceHistoryDto } from '@common/models/Shop/Product';

import {
  CommissionDetailsDto,
  GetCommissionDetailsBody,
  GetPriceHistoryBody,
} from './dto';

export class AnalyticsApiService {
  static async getPriceHistory(body: GetPriceHistoryBody) {
    return $authApi.post<PriceHistoryDto[]>(`analytics/prices/history`, body);
  }

  static async getCommissionDetails(body: GetCommissionDetailsBody) {
    return $authApi.post<CommissionDetailsDto>(
      `analytics/prices/commission`,
      body
    );
  }
}

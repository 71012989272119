import { $authApi } from '@common/http';

export class ShopImportApiService {
  static async uploadIgrotradeStocks(file: File) {
    return $authApi.post(
      `/shop/import/igrotrade`,
      {
        file: file,
        fileName: file.name,
      },
      {
        headers: { 'content-type': 'multipart/form-data' },
      }
    );
  }

  static async uploadPrices(shop_id: number, file: File) {
    return $authApi.post(
      `/shop/import/yandex/prices`,
      {
        shop_id,
        file: file,
        fileName: file.name,
      },
      {
        headers: { 'content-type': 'multipart/form-data' },
        responseType: 'blob',
      }
    );
  }

  static async uploadStocks(shop_id: number, file: File) {
    return $authApi.post(
      `/shop/import/yandex/stocks`,
      {
        shop_id,
        file: file,
        fileName: file.name,
      },
      {
        headers: { 'content-type': 'multipart/form-data' },
        responseType: 'blob',
      }
    );
  }
}

import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Paper, Tab, Tabs } from '@mui/material';

import classes from './TabsBlock.module.css';
import { styled } from '@mui/system';

const StyledTab = styled(Tab)(() => ({
  textAlign: 'start',
  alignItems: 'flex-start',
  textTransform: 'none',
  fontSize: 16,
  paddingTop: '1rem',
  paddingBottom: '1rem',
  color: 'black',
  fontFamily: 'inherit',
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`content-tabpanel-${index}`}
      aria-labelledby={`content-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `content-tab-${index}`,
    'aria-controls': `content-tabpanel-${index}`,
  };
}

export interface TabData {
  label: string;
  component: React.ReactNode;
}

interface TabsBlockProps {
  value: number;
  onChange: (newValue: number) => void;
  tabs: TabData[];
}

export const TabsBlock: FC<TabsBlockProps> = observer(
  ({ value, onChange, tabs }) => (
    <Paper>
      <Tabs
        value={value}
        onChange={(_, v) => onChange(v)}
        orientation='vertical'
        scrollButtons={false}
      >
        {tabs.map(({ label }, index) => (
          <StyledTab label={label} key={index} {...a11yProps(index)} />
        ))}
      </Tabs>
    </Paper>
  )
);

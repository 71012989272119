import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { GLOBAL_TITLE } from '@common/constants';

import { GeneralOptionsBlock } from './components/GeneralOptionsBlock/GeneralOptionsBlock';
import { HeaderBlock } from './components/HeaderBlock';
import { OzonBlock } from './components/OzonBlock';
import { TabBlock } from './components/TabBlock';
import { WildberriesBlock } from './components/WildberriesBlock';
import { YandexBlock } from './components/YandexBlock';
import { ContextProvider, ShopViewContext } from './context';
import classes from './ShopView.module.css';
import { ShopViewTabEnum } from './store';

export interface ShopViewTabData {
  id: ShopViewTabEnum;
  label: string;
  component: (shopId: number) => React.ReactNode | null;
}

const tabs: ShopViewTabData[] = [
  {
    id: ShopViewTabEnum.GENERAL,
    label: 'Общие настройки',
    component: () => <GeneralOptionsBlock />,
  },
  {
    id: ShopViewTabEnum.OZON,
    label: 'Ozon Seller',
    component: (shopId) => <OzonBlock shopId={shopId} />,
  },
  {
    id: ShopViewTabEnum.YANDEX,
    label: 'Yandex Market',
    component: (shopId) => <YandexBlock shopId={shopId} />,
  },
  {
    id: ShopViewTabEnum.WILDBERRIES,
    label: 'Wildberries',
    component: (shopId) => <WildberriesBlock shopId={shopId} />,
  },
];

const Container = observer(() => {
  const store = useContext(ShopViewContext);

  const params = useParams();

  const pageTitle = useMemo(() => {
    if (!store.shop) return `${GLOBAL_TITLE}`;

    return `${store.shop.name} | ${GLOBAL_TITLE}`;
  }, [store.shop?.name]);

  const fetchShopData = async () => {
    const shopId = Number(params.shopId);

    if (isNaN(shopId)) return;

    await store.fetchShopData(shopId);
  };

  useEffect(() => {
    fetchShopData();
  }, []);

  if (!store.shop) return null;

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className={classes.container}>
        <HeaderBlock />
        <TabBlock tabs={tabs} />
        {tabs[store.currentTab].component(store.shop.id)}
      </div>
    </div>
  );
});

export const ShopView = observer(() => (
  <ContextProvider>
    <Container />
  </ContextProvider>
));

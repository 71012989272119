// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YandexIntegration_container__eus16 {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n  gap: 1rem;\n}\n\n.YandexIntegration_container__eus16 h2 {\n  margin: 0;\n  padding-bottom: 1rem;\n}\n\n.YandexIntegration_category__BK99v {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  align-items: center;\n}\n\n.YandexIntegration_error__iGLDv {\n  color: red;\n}\n\n.YandexIntegration_input__L2wfF {\n  width: 100%;\n  max-width: -moz-fit-content;\n  max-width: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./src/features/YandexIntegration/YandexIntegration.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,2BAAsB;EAAtB,sBAAsB;AACxB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n  gap: 1rem;\n}\n\n.container h2 {\n  margin: 0;\n  padding-bottom: 1rem;\n}\n\n.category {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  align-items: center;\n}\n\n.error {\n  color: red;\n}\n\n.input {\n  width: 100%;\n  max-width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "YandexIntegration_container__eus16",
	"category": "YandexIntegration_category__BK99v",
	"error": "YandexIntegration_error__iGLDv",
	"input": "YandexIntegration_input__L2wfF"
};
export default ___CSS_LOADER_EXPORT___;

import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { useInput } from '@common/hooks';
import { Button, Paper, TextField } from '@mui/material';

import classes from './CreateShop.module.css';
import { ShopListContext } from '@features/ShopList/context';

export const CreateShop = observer(() => {
  const store = useContext(ShopListContext);
  const input = useInput();

  const handleClick = async () => {
    if (!input.value.trim()) return;

    await store.createShop(input.value);

    input.setValue('');
  };

  return (
    <Paper className={classes.container}>
      <div className={classes.title}>Создать магазин</div>
      <TextField
        className={classes.input}
        placeholder='Название магазина'
        {...input.bind}
      />
      <Button className={classes.button} onClick={handleClick}>
        Создать
      </Button>
    </Paper>
  );
});

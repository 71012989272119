// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Section_container__9h2sH {\n  display: grid;\n  grid-template:\n    'title buttons' min-content\n    'content content' 1fr\n    / 1fr max-content;\n\n  padding: 1rem 1.5rem;\n  gap: 0.5rem;\n  font-size: 16px;\n}\n\n.Section_title__9m68H {\n  grid-area: title;\n  padding-bottom: 0.5rem;\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.Section_buttons__STd52 {\n  grid-area: buttons;\n  justify-self: flex-end;\n}\n\n.Section_content__f6T0k {\n  grid-area: content;\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ShopView/components/Section/Section.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb;;;qBAGmB;;EAEnB,oBAAoB;EACpB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb","sourcesContent":[".container {\n  display: grid;\n  grid-template:\n    'title buttons' min-content\n    'content content' 1fr\n    / 1fr max-content;\n\n  padding: 1rem 1.5rem;\n  gap: 0.5rem;\n  font-size: 16px;\n}\n\n.title {\n  grid-area: title;\n  padding-bottom: 0.5rem;\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.buttons {\n  grid-area: buttons;\n  justify-self: flex-end;\n}\n\n.content {\n  grid-area: content;\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Section_container__9h2sH",
	"title": "Section_title__9m68H",
	"buttons": "Section_buttons__STd52",
	"content": "Section_content__f6T0k"
};
export default ___CSS_LOADER_EXPORT___;

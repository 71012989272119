import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';

import { ProductPriceListItemDto } from '@common/api/Shop/Product';
import { StoreContext } from '@common/context';
import { ProductStatusEnum } from '@common/models/Shop/Product';
import { PriceListContext } from '@features/PriceList/context';
import { usePagination } from '@features/PriceList/hooks';
import { TableData } from '@features/PriceList/interface';
import FactoryIcon from '@mui/icons-material/Factory';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import {
  Collapse,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { ProductAnalytics } from '../ProductAnalytics';
import classes from './PriceListTable.module.css';
import { TableHeader } from './TableHeader';

const renderStock = (data: ProductPriceListItemDto) => (
  <div
    className={classes.stockContainer}
    title={`У поставщика: ${data.supplier_stock}, На складе: ${data.warehouse_stock}`}
  >
    <div className={classes.stock}>
      <span>{data.supplier_stock}</span>
      <FactoryIcon fontSize='small' />
    </div>
    <div className={classes.stock}>
      <span className={classes.delimiter}></span>
      <span>{data.warehouse_stock}</span>
      <WarehouseIcon fontSize='small' />
    </div>
  </div>
);

const renderPrice = (value: number) => (
  <div className={classes.price}>{value}</div>
);

const trendingIcon = (percent: number) => {
  return (
    <div className={classes.trend}>
      {percent > 0 ? (
        <TrendingUpIcon color='success'></TrendingUpIcon>
      ) : (
        <TrendingDownIcon color='error'></TrendingDownIcon>
      )}
      <span>{Math.round(percent * 100)}</span>
    </div>
  );
};

const renderQuarantinePrice = (old_price: number, new_price: number) => {
  return (
    <div className={classes['quarantine-price']}>
      <div className={classes.price}>{old_price}</div>
      <TrendingFlatIcon />
      <div className={classes.price}>{new_price}</div>
      {trendingIcon(new_price / old_price - 1)}
    </div>
  );
};

const tableData: TableData<ProductPriceListItemDto> = {
  columns: [
    {
      id: 'id',
      label: 'Id',
      align: 'center',
      render: (_, index) => index + 1,
    },
    {
      id: 'offer_id',
      label: 'Артикул',
      align: 'center',
      render: (data) => (
        <a
          href={`/products/${data.product_id}`}
          target='_blank'
          rel='noreferrer noopener'
        >
          {data.offer_id}
        </a>
      ),
    },
    {
      id: 'supplier',
      label: 'Поставщик',
      align: 'left',
      render: (data) => data.supplier,
    },
    {
      id: 'name',
      label: 'Название',
      align: 'left',
      maxWidth: 250,
      render: (data) => (
        <div className={classes.productName} title={data.name}>
          {data.name}
        </div>
      ),
    },
    {
      id: 'basePrice',
      label: 'Базовая цена',
      align: 'right',
      render: (data) => renderPrice(data.base_price),
    },
    {
      id: 'marketplacePrice',
      label: 'Цена на маркетплейсе',
      align: 'right',
      render: (data) =>
        data.status !== ProductStatusEnum.QUARANTINE
          ? renderPrice(data.old_price)
          : renderQuarantinePrice(data.old_price, data.new_price),
    },
    {
      id: 'stock',
      label: 'Остаток',
      align: 'right',
      render: renderStock,
    },
    {
      id: 'quantity',
      label: 'Количество',
      align: 'center',
      render: (data) => data.quantity,
    },
  ],
  sortFields: ['offer_id', 'name'],
};

export const PriceListTable = observer(() => {
  const shopStore = useContext(StoreContext).shops;

  const store = useContext(PriceListContext);
  const pagination = usePagination();

  useEffect(() => {
    store.setFilter({
      ...store.filter,
      shop_id: shopStore.selectedShop ?? 0,
    });
  }, [shopStore.selectedShop]);

  useEffect(() => {
    store.fetchProductPrices().then();
  }, [store.filter]);

  if (!store.prices) return null;

  return (
    <Paper className={classes.container}>
      <TableContainer>
        <Table stickyHeader size='small'>
          <TableHeader {...tableData} />
          <TableBody sx={{ overflowY: 'scroll' }}>
            {store.prices.items.map((product, index) => (
              <React.Fragment key={product.configuration_id}>
                <TableRow
                  hover
                  selected={
                    store.selectedConfiguration === product.configuration_id
                  }
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                  onClick={() =>
                    store.setSelectedConfiguration(product.configuration_id)
                  }
                >
                  {tableData.columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        maxWidth: column.maxWidth,
                        minWidth: column.minWidth,
                        width: column.width,
                      }}
                    >
                      {column.render(product, index)}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={10}
                  >
                    <Collapse
                      in={
                        store.selectedConfiguration === product.configuration_id
                      }
                      unmountOnExit
                    >
                      <ProductAnalytics />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{ py: 2 }}
        {...pagination.bind}
        showFirstButton
        showLastButton
      />
    </Paper>
  );
});

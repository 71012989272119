import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useReactToPrint } from 'react-to-print';

import { GLOBAL_TITLE } from '@common/constants';
import { StoreContext } from '@common/context';
import { Button } from '@mui/material';

import { MarketPlaceTabBlock } from './components/MarketPlaceTabBlock';
import { OzonPickList } from './components/OzonPickList';
import { WBPickList } from './components/WBPickList';
import { YandexPickList } from './components/YandexPickList';
import { ContextProvider, PickListContext } from './context';
import classes from './PickList.module.css';

const Container = observer(() => {
  const shopStore = useContext(StoreContext).shops;
  const store = useContext(PickListContext);
  const tableRef = useRef(null);

  const pageTitle = useMemo(() => `Лист подбора | ${GLOBAL_TITLE}`, []);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  useEffect(() => {
    store.setShopId(shopStore.selectedShop);
  }, [shopStore.selectedShop, store.selectedMarketPlace]);

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className={classes.container}>
        <h2>Листы подбора</h2>
        <Button onClick={handlePrint}>Распечатать</Button>
      </div>
      <MarketPlaceTabBlock />
      {store.selectedMarketPlace === 'ozon' ? (
        <OzonPickList ref={tableRef} />
      ) : store.selectedMarketPlace === 'yandex' ? (
        <YandexPickList ref={tableRef} />
      ) : (
        <WBPickList ref={tableRef} />
      )}
    </div>
  );
});

export const PickList = observer(() => (
  <ContextProvider>
    <Container />
  </ContextProvider>
));

import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AttributeDto } from '@common/models/Ozon/Category';
import InfoIcon from '@mui/icons-material/Info';
import {
  IconButton,
  styled,
  TextField,
  TextFieldProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';

import { AutocompleteInput } from './components/AutocompleteInput';
import classes from './SmartInput.module.css';
import { ValidationInput } from './components/ValidationInput';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(13),
    border: '1px solid #dadde9',
  },
}));

export interface SmartInputProps {
  schema?: AttributeDto;
}

export const SmartInput: FC<TextFieldProps & SmartInputProps> = observer(
  ({ schema, ...props }) => {
    const InputElement = () =>
      schema?.dictionaryId ? (
        <AutocompleteInput
          label={schema.name}
          required={schema.isRequired}
          schema={schema}
          {...props}
        />
      ) : schema ? (
        <ValidationInput schema={schema} />
      ) : (
        <TextField fullWidth {...props} />
      );

    return (
      <div className={classes.container}>
        <InputElement />
        {schema ? (
          <HtmlTooltip
            title={
              <div className={classes.tooltip}>
                {schema.description}
                <p>
                  Type: {schema.type}
                  {schema.isCollection ? '[]' : ''}
                </p>
              </div>
            }
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </HtmlTooltip>
        ) : null}
      </div>
    );
  }
);

import { ProductStatusEnum } from '@common/models/Shop/Product';

export enum OrderByRows {
  id = 'id',
  offer_id = 'offer_id',
  name = 'name',
  price = 'price',
  supplier_offer_id = 'supplier_offer_id',
}

export enum OrderType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ProductFilterDto {
  supplierId?: number;
  categoryIds?: number[];
  vendorIds?: number[];

  sku?: string;
  name?: string;
  supplierSku?: string;

  priceFrom?: number;
  priceTo?: number;

  status?: ProductStatusEnum;
  isAvailable?: boolean;

  orderBy?: OrderByRows;
  order?: OrderType;

  page?: number;
  limit?: number;
}

import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';

import { useInput } from '@common/hooks';
import { PriceListContext } from '@features/PriceList/context';
import { TextField } from '@mui/material';

import classes from './SearchComponent.module.css';

export const SearchComponent = observer(() => {
  const store = useContext(PriceListContext);
  const input = useInput();

  useEffect(() => {
    const debounced = setTimeout(() => {
      store.setFilter({
        ...store.filter,
        offer_id: input.value,
      });
    }, 200);

    return () => clearTimeout(debounced);
  }, [input.value]);

  useEffect(() => {
    input.setValue(store.filter.offer_id ?? '');
  }, [store.filter.offer_id]);

  return (
    <TextField
      className={classes.root}
      sx={{ my: 1 }}
      placeholder='Введите артикул...'
      {...input.bind}
    />
  );
});

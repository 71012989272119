// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateShop_container__r\\+aKT {\n  display: grid;\n  grid-template:\n    'title title' max-content\n    'input button' 1fr\n    / max-content max-content;\n\n  padding: 1rem;\n  gap: 1rem;\n}\n\n.CreateShop_title__Zg5W1 {\n  grid-area: title;\n  font-size: 20px;\n  padding-bottom: 0.5rem;\n  font-weight: 500;\n}\n\n.CreateShop_input__gK-56 {\n  grid-area: input;\n}\n\n.CreateShop_button__9OpCG {\n  grid-area: button;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ShopList/components/CreateShop/CreateShop.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb;;;6BAG2B;;EAE3B,aAAa;EACb,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".container {\n  display: grid;\n  grid-template:\n    'title title' max-content\n    'input button' 1fr\n    / max-content max-content;\n\n  padding: 1rem;\n  gap: 1rem;\n}\n\n.title {\n  grid-area: title;\n  font-size: 20px;\n  padding-bottom: 0.5rem;\n  font-weight: 500;\n}\n\n.input {\n  grid-area: input;\n}\n\n.button {\n  grid-area: button;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreateShop_container__r+aKT",
	"title": "CreateShop_title__Zg5W1",
	"input": "CreateShop_input__gK-56",
	"button": "CreateShop_button__9OpCG"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';

export const useInput = (defaultValue: string = '') => {
  const [value, setValue] = useState(defaultValue);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    bind: { value, onChange },
  };
};

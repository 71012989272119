import { $authApi } from '@common/http';
import { CategoryDto, CategoryListDto } from '@common/models/Response/Category';

import { CategoryRoutes } from './routes';

export class CategoryApiService {
  static async getCategoryById(categoryId: number) {
    return $authApi.get<CategoryDto>(
      `${CategoryRoutes.CATEGORIES_ROUTE}/${categoryId}`
    );
  }

  static async getCategoryList(supplierId: number) {
    return $authApi.get<CategoryListDto>(
      `${CategoryRoutes.CATEGORIES_ROUTE}/supplier/${supplierId}`
    );
  }
}

import { observer } from 'mobx-react';
import React, { FC, useContext } from 'react';

import { CommissionDto } from '@common/api/Shop/Analytics';
import { ProductStatusEnum } from '@common/models/Shop/Product';
import { PriceListContext } from '@features/PriceList/context';
import { Button, ButtonGroup, Switch } from '@mui/material';

import { PriceHistory } from './PriceHistory';
import classes from './ProductAnalytics.module.css';

interface CommissionProps {
  title: string;
  commission: CommissionDto;
}

const Commission: FC<CommissionProps> = observer((props) => {
  return (
    <div className={classes.commission}>
      <div>{props.title}</div>
      <div className={classes.percent}>{props.commission.percent * 100}</div>
      <div className={classes.value}>{props.commission.value} </div>
    </div>
  );
});

export const ProductAnalytics = observer(() => {
  const store = useContext(PriceListContext);

  const acceptPrice = (items: number[]) => {
    if (!store.selectedConfiguration) return;

    store.acceptPrices(items);
  };

  const rejectPrice = (items: number[]) => {
    if (!store.selectedConfiguration) return;

    store.rejectPrices(items);
  };

  const changeQuantity = async (step: number) => {
    store.analytics.setStatus({
      ...store.analytics.status,
      quantity: store.analytics.status.quantity + step,
    });

    await store.analytics.updateProductQuantity(
      store.analytics.status.product_id,
      store.analytics.status.quantity
    );
  };

  const changeStatus = async (configuration_id: number, status: boolean) => {
    await store.analytics.updateProductStatus(configuration_id, status);

    if (store.selectedConfiguration === configuration_id) {
      store.analytics.setStatus({
        ...store.analytics.status,
        is_enabled_marketplace: status,
      });
    }

    if (store.analytics.status.default_configuration_id === configuration_id) {
      store.analytics.setStatus({
        ...store.analytics.status,
        is_enabled_global: status,
      });
    }
  };

  return (
    <div className={classes.container}>
      <PriceHistory />
      <div className={classes.statusBlock}>
        <b>Статус товара</b>
        <div>
          Включить продажу (глобально){' '}
          <Switch
            checked={store.analytics.status.is_enabled_global}
            onChange={(_, checked) =>
              changeStatus(
                store.analytics.status.default_configuration_id,
                checked
              )
            }
          />
        </div>
        <div>
          Включить продажу (маркетплейс){' '}
          <Switch
            checked={store.analytics.status.is_enabled_marketplace}
            onChange={(_, checked) =>
              changeStatus(store.selectedConfiguration!, checked)
            }
          />
        </div>
        <div>
          Количество товара{' '}
          <ButtonGroup aria-label='small outlined button group'>
            <Button
              variant='contained'
              disabled={!store.analytics.status.quantity}
              onClick={() => changeQuantity(-1)}
            >
              -
            </Button>
            <Button>{store.analytics.status.quantity}</Button>
            <Button variant='contained' onClick={() => changeQuantity(1)}>
              +
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {store.analytics.commissions ? (
        <div className={classes.commissionBlock}>
          <b>Комиссии</b>
          <div className={classes.commissionDetails}>
            <Commission
              title='Маркетплейс'
              commission={store.analytics.commissions.sale}
            />
            <Commission
              title='Банк'
              commission={store.analytics.commissions.bank}
            />
            <Commission
              title='Логистика'
              commission={store.analytics.commissions.logistics}
            />
            <Commission
              title='Последняя миля'
              commission={store.analytics.commissions.last_mile}
            />
            <Commission
              title='Возвраты'
              commission={store.analytics.commissions.refunds}
            />
          </div>
          <b>Доходность</b>
          <div>
            Желаемый доход -{' '}
            <span className={classes.percent}>
              {store.analytics.commissions.disired_income_percent * 100}
            </span>
          </div>
          <div>
            Реальный доход -{' '}
            <span className={classes.value}>
              {store.analytics.commissions.real_income}
            </span>
          </div>
        </div>
      ) : null}
      {store.filter.product_status === ProductStatusEnum.QUARANTINE ? (
        <div className={classes.footerBlock}>
          <Button
            color='success'
            variant='outlined'
            onClick={() => acceptPrice([store.selectedConfiguration!])}
          >
            Принять
          </Button>
          <Button
            color='error'
            variant='outlined'
            onClick={() => rejectPrice([store.selectedConfiguration!])}
          >
            Отклонить
          </Button>
        </div>
      ) : null}
    </div>
  );
});

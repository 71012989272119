import { observer } from 'mobx-react';
import React, { FC, useContext, useMemo } from 'react';

import { OrderType, ProductPriceListItemDto } from '@common/api/Shop/Product';
import { PriceListContext } from '@features/PriceList/context';
import { ColumnData, TableData } from '@features/PriceList/interface';
import {
  Box,
  styled,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  fontWeight: 'bold',
}));

const convertOrder = (order: OrderType): 'asc' | 'desc' => {
  return order === OrderType.ASC ? 'asc' : 'desc';
};

type TableHeaderColumnProps = ColumnData<ProductPriceListItemDto> &
  Pick<TableData<ProductPriceListItemDto>, 'sortFields'>;

const TableHeaderColumn: React.FC<TableHeaderColumnProps> = observer(
  (props) => {
    const store = useContext(PriceListContext);

    const changeOrder = (id: string) => {
      let [orderBy, order] = store.filter.order;

      if (id !== orderBy) order = OrderType.ASC;
      else if (order === OrderType.ASC) order = OrderType.DESC;
      else order = OrderType.ASC;

      store.setFilter({
        ...store.filter,
        order: [id, order],
      });
    };

    const isSortable = (key: string) => {
      return props.sortFields.includes(key);
    };

    const order = useMemo(
      () => convertOrder(store.filter.order[1]),
      [store.filter.order]
    );
    const orderBy = useMemo(() => store.filter.order[0], [store.filter.order]);

    return (
      <StyledTableCell
        key={props.id}
        align='left'
        style={{
          maxWidth: props.maxWidth,
          minWidth: props.minWidth,
          width: props.width,
        }}
        sortDirection={orderBy === props.id ? order : false}
      >
        {isSortable(props.id) ? (
          <TableSortLabel
            active={orderBy === props.id}
            direction={orderBy === props.id ? order : 'asc'}
            onClick={() => changeOrder(props.id)}
          >
            {props.label}
            {orderBy === props.id ? (
              <Box component='span' sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        ) : (
          props.label
        )}
      </StyledTableCell>
    );
  }
);

type TableHeaderProps = TableData<ProductPriceListItemDto>;

export const TableHeader: FC<TableHeaderProps> = observer(
  ({ columns, sortFields }) => {
    return (
      <TableHead style={{ position: 'sticky', top: 0, zIndex: 100 }}>
        <TableRow style={{ height: 50 }}>
          {columns.map((column) => (
            <TableHeaderColumn
              key={column.id}
              {...column}
              sortFields={sortFields}
            />
          ))}
        </TableRow>
      </TableHead>
    );
  }
);

import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppRouter } from '@features/AppRouter';
import { ThemeProvider } from '@mui/material';
import { theme } from '@common/theme';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;


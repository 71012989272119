import React from 'react';

import ModeIcon from '@mui/icons-material/Mode';
import { IconButton, IconButtonProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/system';

export const EditIcon = styled((props: IconButtonProps) => (
  <IconButton size='small' aria-label='edit' {...props}>
    <ModeIcon />
  </IconButton>
))({
  color: grey[600],
});

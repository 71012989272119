import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

import { ProductConfigurationPriceHistoryDto } from '@common/models/Shop/Product';
import { useProductStore } from '@features/ProductView/context';

import { Section } from '../Section';
import classes from './PriceHistoryBlock.module.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const formatter = new Intl.DateTimeFormat('ru', { month: 'long' });

const getPricesRange = (data: ProductConfigurationPriceHistoryDto[]) => {
  if (!data.length) return [0, 0];

  let min = data[0].value;
  let max = data[0].value;

  for (let i = 1; i < data.length; ++i) {
    if (data[i].value < min) min = data[i].value;
    if (data[i].value > max) max = data[i].value;
  }

  return [min, max];
};

export const PriceHistoryBlock = observer(() => {
  const store = useProductStore();

  const chartTitle = useMemo(
    () => `История цен ${store.product?.name}`,
    [store.product?.name]
  );

  if (!store.defaultConfiguration?.price_history) return null;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: chartTitle,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let value = context.parsed.y;

            return value + ' \u20BD';
          },
        },
      },
    },
  };

  const labels = store.defaultConfiguration.price_history.map((x) =>
    formatter.format(new Date(x.created_at).getMonth())
  );

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        data: store.defaultConfiguration.price_history.map((x) => x.value),
        borderColor: 'rgb(231, 195, 49)',
        backgroundColor: 'rgba(241, 217, 64, 0.5)',
      },
    ],
  };

  const [minPrice, maxPrice] = getPricesRange(
    store.defaultConfiguration.price_history
  );

  return (
    <Section>
      <div className={classes.title}>
        <span>История цены</span>
        <span className={classes.range}>
          От {minPrice} &#8381; до {maxPrice} &#8381;
        </span>
      </div>
      <Line options={options} data={data} />
    </Section>
  );
});

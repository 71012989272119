// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderBlock_container__ggb1Q {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.HeaderBlock_item-container__oD0Bc {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  align-items: center;\n}\n\n.HeaderBlock_item-container__oD0Bc h2 {\n  margin: 0;\n  padding: 0;\n}\n\n.HeaderBlock_badge__iJacL {\n  width: 25px;\n  height: 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ShopView/components/HeaderBlock/HeaderBlock.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.item-container {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  align-items: center;\n}\n\n.item-container h2 {\n  margin: 0;\n  padding: 0;\n}\n\n.badge {\n  width: 25px;\n  height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HeaderBlock_container__ggb1Q",
	"item-container": "HeaderBlock_item-container__oD0Bc",
	"badge": "HeaderBlock_badge__iJacL"
};
export default ___CSS_LOADER_EXPORT___;

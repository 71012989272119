import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { GLOBAL_TITLE } from '@common/constants';

import { PostingTable } from './components/PostingTable';
import { TabBlock } from './components/TabBlock';
import { ContextProvider, PostingListContext } from './context';
import { StoreContext } from '@common/context';

export const Container = observer(() => {
  const shopStore = useContext(StoreContext).shops;
  const store = useContext(PostingListContext);

  const pageTitle = useMemo(() => `Заказы | ${GLOBAL_TITLE}`, []);

  const fetchPostings = async () => {
    store.setPostings([]);
    await store.fetchPostings();
  };

  useEffect(() => {
    store.setShopId(shopStore.selectedShop);
    fetchPostings();
  }, [shopStore.selectedShop]);

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <TabBlock />
      <PostingTable />
    </div>
  );
});

export const PostingList = observer(() => (
  <ContextProvider>
    <Container />
  </ContextProvider>
));

import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Checkbox, TextField, TextFieldProps, makeStyles } from '@mui/material';

import { SmartInputProps } from '../../SmartInput';
import { useValidationInput } from './hooks';

const DefaultTextField: FC<TextFieldProps & Required<SmartInputProps>> = ({
  schema,
  ...props
}) => (
  <TextField
    fullWidth
    label={schema.name}
    required={schema.isRequired}
    {...props}
  />
);

export const ValidationInput: FC<Required<SmartInputProps>> = observer(
  ({ schema }) => {
    const validationInput = useValidationInput(schema);

    if (schema.type === 'Boolean') {
      return (
        <React.Fragment>
          <DefaultTextField schema={schema} {...validationInput.bind} />
          <Checkbox
            value={validationInput.bind.value === '1'}
            onChange={(e) => {
              validationInput.updateValue(e.target.checked ? '1' : '0');
            }}
          />
        </React.Fragment>
      );
    }

    return <DefaultTextField schema={schema} {...validationInput.bind} />;
  }
);

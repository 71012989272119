import { useEffect, useState } from 'react';

import { useProductStore } from '@features/ProductView/context';

export const useWarehouseStocks = () => {
  const store = useProductStore();

  const [value, setValue] = useState(0);

  const increment = () => {
    setValue((prev) => prev + 1);
  };

  const decrement = () => {
    setValue((prev) => prev - 1);
  };

  const saveChanges = async () => {
    try {
      await store.saveChanges();
    } catch {}
  };

  useEffect(() => {
    if (!store.copy?.stocks) return;

    setValue(store.copy.stocks.warehouse_stocks);
  }, [store.copy]);

  useEffect(() => {
    if (!store.copy?.stocks) return;

    store.copy.stocks.warehouse_stocks = value;

    const debounced = setTimeout(saveChanges, 300);

    return () => clearTimeout(debounced);
  }, [value]);

  return {
    value,
    increment,
    decrement,
  };
};

import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { PostingDto } from '@common/models/Ozon/Fbs';
import {
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { PostingListContext } from '../context';
import { PostingListFilterStatus } from '../dto';
import classes from '../PostingList.module.css';
import { localizeStatus } from '../util';

interface ColumnData {
  id: keyof PostingDto | 'price' | 'time_until_ship';
  label: string;
  value: (posting: PostingDto) => string | React.ReactNode;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
}

const renderDate = (date: string) => {
  return (
    <div>
      <div>{new Date(date).toLocaleDateString()}</div>
      <div className={classes.secondaryText}>
        {new Date(date).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </div>
    </div>
  );
};

const calculateOrderPrice = (posting: PostingDto) =>
  posting.products.reduce((cur, x) => cur + Number(x.price) * x.quantity, 0);

const renderProducts = (posting: PostingDto) => (
  <div>
    {posting.products.map((product) => (
      <div key={product.offer_id}>
        <div>
          <span>{product.offer_id}</span>
          {product.shop_product_id ? (
            <span>
              {' ('}
              <a
                href={`/products/${product.shop_product_id}`}
                target='_blank'
                rel='noreferrer noopener'
              >
                {product.shop_supplier_sku}
              </a>
              {')'}
            </span>
          ) : null}
          , {product.quantity} шт.
        </div>
        <div className={classes.secondaryText}>{product.name}</div>
      </div>
    ))}
  </div>
);

const renderDeliveryMethod = (posting: PostingDto) => (
  <div>
    <div>{posting.delivery_method_tpl_provider}</div>
    <div className={classes.secondaryText}>{posting.delivery_method_name}</div>
  </div>
);

const renderTimeUntilShip = (posting: PostingDto) => {
  if (posting.status !== 'awaiting_packaging') return null;

  const now = new Date().getTime();
  const shipment_date = new Date(posting.shipment_date).getTime();

  const diffInMinutes = ~~((shipment_date - now) / 1000 / 60);

  let str = '';
  if (diffInMinutes % 60) str = `${diffInMinutes % 60} мин`;
  if (~~(diffInMinutes / 60) % 24)
    str = `${~~(diffInMinutes / 60) % 24} ч ${str}`;
  if (~~(diffInMinutes / 60 / 24))
    str = `${~~(diffInMinutes / 60 / 24)} д ${str}`;

  return str;
};

const renderPostingNumber = (posting: PostingDto) => (
  <div className={classes.importantCell}>{posting.posting_number}</div>
);

const columns: ColumnData[] = [
  {
    id: 'in_process_at',
    label: 'Принят в обработку',
    value: (posting) => renderDate(posting.in_process_at),
  },
  {
    id: 'posting_number',
    label: 'Номер отправления',
    value: renderPostingNumber,
  },
  {
    id: 'status',
    label: 'Статус',
    value: (posting) =>
      localizeStatus(posting.status as PostingListFilterStatus),
  },
  {
    id: 'delivering_date',
    label: 'Передан в доставку',
    value: (posting) =>
      posting.delivering_date ? renderDate(posting.delivering_date) : null,
  },
  {
    id: 'products',
    label: 'Состав отправления',
    value: renderProducts,
    minWidth: 250,
  },
  {
    id: 'price',
    label: 'Стоимость',
    value: (posting) => <>{calculateOrderPrice(posting)} &#8381;</>,
  },
  {
    id: 'tracking_number',
    label: 'Трек-номер',
    value: (posting) => posting.tracking_number,
  },
  {
    id: 'delivery_method_warehouse',
    label: 'Склад',
    value: (posting) => posting.delivery_method_warehouse,
  },
  {
    id: 'delivery_method_tpl_provider',
    label: 'Служба доставки и метод',
    value: renderDeliveryMethod,
  },
  {
    id: 'shipment_date',
    label: 'Дата отгрузки',
    value: (posting) => renderDate(posting.shipment_date),
  },
  {
    id: 'time_until_ship',
    label: 'Время на сборку',
    value: renderTimeUntilShip,
    minWidth: 90,
  },
];

const excludeTable: { [K in PostingListFilterStatus]?: ColumnData['id'][] } = {
  awaiting_packaging: ['delivering_date', 'tracking_number'],
  awaiting_deliver: ['delivering_date', 'time_until_ship'],
};

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 12,
  fontWeight: 'bold',
  lineHeight: 1.5,
}));

type TableHeaderColumnProps = {
  label: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right';
};

const TableHeaderColumn: React.FC<TableHeaderColumnProps> = (props) => {
  return (
    <StyledTableCell
      align='left'
      style={{
        maxWidth: props.maxWidth,
        minWidth: props.minWidth,
        width: props.width,
      }}
    >
      {props.label}
    </StyledTableCell>
  );
};

export const PostingTable = observer(() => {
  const store = useContext(PostingListContext);

  const handleLoadMore = async () => {
    await store.loadMore();
  };

  const filterColumns = (column: ColumnData) => {
    if (!store.currentStatus || !excludeTable[store.currentStatus]) return true;

    return !excludeTable[store.currentStatus]?.includes(column.id);
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <TableContainer>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow style={{ height: 50 }}>
              {columns.filter(filterColumns).map((column) => (
                <TableHeaderColumn key={column.label} {...column} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowY: 'scroll' }}>
            {store.postings.length ? (
              store.postings.map((posting) => (
                <TableRow
                  key={posting.posting_number}
                  hover
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                >
                  {columns.filter(filterColumns).map((column) => (
                    <TableCell
                      key={posting.posting_number + column.label}
                      align='left'
                    >
                      {column.value(posting)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100} align='center'>
                  Нет записей
                </TableCell>
              </TableRow>
            )}
            {store.hasMore ? (
              <TableRow>
                <TableCell colSpan={100} align='center'>
                  <Button onClick={handleLoadMore}>Загрузить ещё</Button>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { FC, useContext, useState } from 'react';

import { CategoryDto } from '@common/models/Ozon/Category';
import MenuIcon from '@mui/icons-material/Menu';
import { Autocomplete, Button, Drawer, IconButton } from '@mui/material';

import { SmartInput } from '../../../SmartInput';
import classes from './CategoryDrawer.module.css';
import { AddProductFormContext } from '@features/AddProductForm/context';

interface CategoryItemProps {
  item: CategoryDto;
  current: number | null;
  useDecorator?: boolean;
  onClick: () => {};
}

const CategoryItem: FC<CategoryItemProps> = ({
  item,
  current,
  onClick,
  useDecorator = false,
}) => (
  <div
    className={clsx(
      classes.categoryListItem,
      current === item.id && classes.selected
    )}
    onClick={() => onClick()}
  >
    {item.title}
    {useDecorator ? <div className={classes.decorator}></div> : null}
  </div>
);

interface CategoryDrawerProps {
  onConfirmClick: (id: number) => void;
}

export const CategoryDrawer: FC<CategoryDrawerProps> = observer(
  ({ onConfirmClick }) => {
    const [isOpen, setIsOpen] = useState(false);

    const store = useContext(AddProductFormContext);

    const onClickFirstLevel = async (id: number) => {
      if (store.categories.selectedFirstLevel === id) return;
      store.categories.setSelectedFirstLevel(id);
      store.categories.setSelectedSecondLevel(null);
      store.categories.setSelectedThirdLevel(null);

      store.categories.setSecondLevel([]);
      store.categories.setThirdLevel([]);

      const data = await store.categories.fetchCategory(id);

      if (data && data.children) {
        store.categories.setSecondLevel(data.children);
      }
    };

    const onClickSecondLevel = async (id: number) => {
      if (store.categories.selectedSecondLevel === id) return;

      store.categories.setSelectedSecondLevel(id);
      store.categories.setSelectedThirdLevel(null);

      store.categories.setThirdLevel([]);

      const data = await store.categories.fetchCategory(id);

      if (data && data.children) {
        store.categories.setThirdLevel(data.children);
      }
    };

    const onClickThirdLevel = async (id: number) => {
      if (store.categories.selectedThirdLevel === id) return;

      store.categories.setSelectedThirdLevel(id);
    };

    return (
      <React.Fragment>
        <IconButton onClick={() => setIsOpen((prev) => !prev)}>
          <MenuIcon />
        </IconButton>
        <Drawer anchor='right' open={isOpen} onClose={() => setIsOpen(false)}>
          <div className={classes.container}>
            <div className={classes.titleArea}>
              <h2>Выберите категорию товара</h2>
            </div>
            <div className={classes.searchArea}>
              <SmartInput
                fullWidth
                label='Поиск по категории'
                aria-label='ozon-category-search'
                value={store.categories.fullTitle}
              />
            </div>
            <div className={classes.contentArea}>
              <div className={classes.categoryList}>
                {store.categories.firstLevel.map((item) => (
                  <CategoryItem
                    key={item.id}
                    item={item}
                    current={store.categories.selectedFirstLevel}
                    onClick={() => onClickFirstLevel(item.id)}
                    useDecorator
                  />
                ))}
              </div>
              <div className={classes.categoryList}>
                {store.categories.secondLevel.map((item) => (
                  <CategoryItem
                    key={item.id}
                    item={item}
                    current={store.categories.selectedSecondLevel}
                    onClick={() => onClickSecondLevel(item.id)}
                    useDecorator
                  />
                ))}
              </div>
              <div className={classes.categoryList}>
                {store.categories.thirdLevel.map((item) => (
                  <CategoryItem
                    key={item.id}
                    item={item}
                    current={store.categories.selectedThirdLevel}
                    onClick={() => onClickThirdLevel(item.id)}
                  />
                ))}
              </div>
            </div>
            <div className={classes.confirmationArea}>
              <Button
                variant='contained'
                onClick={() => {
                  onConfirmClick(store.categories.selectedThirdLevel!);
                  setIsOpen(false);
                }}
                disabled={store.categories.selectedThirdLevel === null}
              >
                Подтвердить
              </Button>
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
);

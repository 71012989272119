import { makeAutoObservable } from 'mobx';

import { ClientFeaturesApiService } from '@common/api/ClientFeatures';

import { PickListItemDto } from '../dto';

export type PickListStatus = 'all' | 'today' | 'tomorrow' | 'later';

export class PickListStore {
  private _postings: PickListItemDto[] = [];
  private _status: PickListStatus = 'today';

  get postings(): PickListItemDto[] {
    return this._postings;
  }
  get status(): PickListStatus {
    return this._status;
  }

  setPostings(values: PickListItemDto[]): void {
    this._postings = values;
  }
  setStatus(status: PickListStatus): void {
    this._status = status;
  }

  constructor() {
    makeAutoObservable(this);
  }

  async fetchPickList(shopId: number, warehouse_id?: number[]) {
    let hasNext = false;
    let page = 1;
    this.setPostings([]);

    try {
      do {
        const { data } = await ClientFeaturesApiService.getPickList({
          shopId,
          page,
          warehouse_id,
        });

        hasNext = data.hasMore;
        page += 1;
        this.setPostings([...this.postings, ...data.items]);
      } while (hasNext);
    } catch (error) {}
  }
}

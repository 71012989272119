import React from 'react';

import { ClientPickListsStore } from '../store';

const store = new ClientPickListsStore();

export const PickListContext = React.createContext<ClientPickListsStore>(store);

type Props = {
  children?: React.ReactNode;
};

export const ContextProvider: React.FC<Props> = ({ children }) => (
  <PickListContext.Provider value={store}>{children}</PickListContext.Provider>
);

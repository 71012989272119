import { PostingDto } from '@common/models/Ozon/Fbs';
import { makeAutoObservable, reaction } from 'mobx';
import { PostingListFilterStatus } from '../dto';
import PostingListService from '../service';

export class PostingListStore {
  private _postings: PostingDto[] = [];
  private _currentStatus: PostingListFilterStatus | null = null;
  private _hasMore: boolean = false;
  private _offset: number = 0;

  private _shopId: number | null = null;

  get postings() {
    return this._postings;
  }

  get currentStatus() {
    return this._currentStatus;
  }

  get hasMore() {
    return this._hasMore;
  }

  private get shopId() {
    return this._shopId;
  }

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.currentStatus,
      async () => {
        this.setHasMore(false);
        this.setOffset(0);
        this.setPostings([]);
        await this.fetchPostings();
      }
    );
  }

  setPostings(values: PostingDto[]) {
    this._postings = values;
  }

  setCurrentStatus(value: PostingListFilterStatus | null) {
    this._currentStatus = value;
  }

  setShopId(value: number | null) {
    this._shopId = value;
  }

  async loadMore() {
    this.setOffset(this._offset + 10);
    this.setHasMore(false);

    await this.fetchPostings();
  }

  private setHasMore(value: boolean) {
    this._hasMore = value;
  }

  private setOffset(value: number) {
    this._offset = value;
  }

  async fetchPostings() {
    if (this.shopId === null) return;

    try {
      const { data } = await PostingListService.getPostingList({
        shopId: this.shopId,
        since: new Date(Date.now() - 1000 * 60 * 60 * 24 * 180),
        to: new Date(),
        status: this.currentStatus ?? undefined,
        offset: this._offset,
      });

      this.setPostings([...this.postings, ...data.items]);
      this.setHasMore(data.has_next);
    } catch (error) {}
  }
}

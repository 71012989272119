import { observer } from 'mobx-react';
import React, { useContext, useMemo } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { PickListContext } from '../../context';

export const WarehouseList = observer(() => {
  const store = useContext(PickListContext);

  const warehouses = useMemo(
    () => store.warehouses.filter((x) => x.status === 'created'),
    [store.warehouses]
  );

  const handleClear = () => {
    store.setSelectedWarehouses([]);
  };

  const handleChange = (e: SelectChangeEvent<number[]>) => {
    const value = e.target.value;

    store.setSelectedWarehouses(
      typeof value === 'string'
        ? value.split(',').map((x) => parseInt(x))
        : value
    );
  };

  return (
    <FormControl>
      <InputLabel id='select-warehouse-list-label'>Склады</InputLabel>
      <Select
        id='select-warehouse-list-label'
        input={<OutlinedInput label='Склады' />}
        multiple
        value={store.selectedWarehouses}
        onChange={handleChange}
      >
        <MenuItem onClick={handleClear}>Очистить</MenuItem>
        {warehouses.map((x) => (
          <MenuItem key={x.id} value={x.id}>
            {x.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

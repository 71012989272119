import { makeAutoObservable, reaction } from 'mobx';

import { NAME_ATTRIBUTE_ID } from '../constants';
import OzonCategoryService from '../service';
import { AttributeDictionaryStore } from './AttributeDictionaryStore';
import { AttributesStore } from './AttributesStore';
import { AttributeValuesStore } from './AttributeValuesStore';
import { CategoryStore } from './CategoryStore';
import { ProductInfoStore } from './ProductInfoStore';

export class AddProductStore {
  private _step = 0;

  private readonly _categoryStore: CategoryStore;
  private readonly _attributesStore: AttributesStore;
  private readonly _attributeDictionaryStore: AttributeDictionaryStore;
  private readonly _attributeValuesStore: AttributeValuesStore;
  private readonly _productInfoStore: ProductInfoStore;

  get step() {
    return this._step;
  }

  get categories() {
    return this._categoryStore;
  }

  get attributes() {
    return this._attributesStore;
  }

  get attributesDictionary() {
    return this._attributeDictionaryStore;
  }

  get attributeValues() {
    return this._attributeValuesStore;
  }

  get productInfo() {
    return this._productInfoStore;
  }

  constructor() {
    this._categoryStore = new CategoryStore();
    this._attributesStore = new AttributesStore();
    this._attributeDictionaryStore = new AttributeDictionaryStore();
    this._attributeValuesStore = new AttributeValuesStore();
    this._productInfoStore = new ProductInfoStore();

    makeAutoObservable(this);

    reaction(
      () => this.productInfo.selectedCategory,
      async (category) => {
        if (!category) return;

        this.attributes.clear();
        this.attributesDictionary.values.clear();

        const attributes = await this.attributes.fetchAttributes(category.id);

        if (!attributes) return;

        this.attributes.setAttributes(attributes);
      }
    );
  }

  setStep(value: number) {
    this._step = value;
  }

  async init() {
    try {
      const { data } = await OzonCategoryService.getAttributeById(
        NAME_ATTRIBUTE_ID
      );

      this.attributes.setAttributes([data]);
    } catch (error) {}
  }
}

import { useContext, useState } from 'react';

import { AttributeDto, AttributeValueDto } from '@common/models/Ozon/Category';
import { AddProductFormContext } from '@features/AddProductForm/context';
import { AutocompleteChangeReason } from '@mui/material';

export const useOptions = (schema: AttributeDto) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  const { productInfo, attributesDictionary, attributeValues } = useContext(
    AddProductFormContext
  );

  const options = attributesDictionary.values.get(schema.id) || [];

  const fetchAttributeValues = async () => {
    if (!productInfo.selectedCategory) return;

    setLoading(true);

    const dict = await attributesDictionary.fetchDictionary({
      categoryId: productInfo.selectedCategory.id,
      attributeId: schema.id,
    });

    setLoading(false);
    setLoaded(true);

    if (!dict) return;

    attributesDictionary.values.set(schema.id, dict);
  };

  const onOpen = async () => {
    if (isLoading) return;

    if (isLoaded) {
      setOpen(true);
      return;
    }

    await fetchAttributeValues();
    setOpen(true);
  };

  const onClose = async () => {
    setOpen(false);
  };

  const value = schema.isCollection
    ? attributeValues.get(schema.id)
    : attributeValues.get(schema.id).at(0) || null;

  const onChange = (
    _: any,
    newValues: AttributeValueDto | AttributeValueDto[] | null,
    reason: AutocompleteChangeReason
  ) => {
    if (['clear'].includes(reason)) {
      attributeValues.set(schema.id, []);
    }

    if (newValues === null) return;

    if (['selectOption'].includes(reason)) {
      attributeValues.set(
        schema.id,
        Array.isArray(newValues) ? newValues : [newValues]
      );
    }

    if (['removeOption'].includes(reason)) {
      attributeValues.set(
        schema.id,
        Array.isArray(newValues) ? newValues : [newValues]
      );
    }
  };

  return {
    isLoading,
    bind: {
      open,
      onOpen,
      onClose,
      options,
      value,
      onChange,
      inputValue,
      onInputChange: (event: any, newInputValue: string) =>
        setInputValue(newInputValue),
      isOptionEqualToValue: (
        option: AttributeValueDto,
        value: AttributeValueDto
      ) => option.id === value.id,
    },
  };
};

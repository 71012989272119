import React from 'react';

import { PriceListStore } from '../store';

const store = new PriceListStore();

export const PriceListContext = React.createContext<PriceListStore>(store);

type Props = {
  children?: React.ReactNode;
};

export const ContextProvider: React.FC<Props> = ({ children }) => (
  <PriceListContext.Provider value={store}>{children}</PriceListContext.Provider>
);

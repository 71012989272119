// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TitleElement_container__mdYzt {\n  display: grid;\n  grid-template:\n    'title buttons' 1fr\n    'content content' 1fr\n    / 1fr max-content;\n  gap: 0.5rem;\n}\n\n.TitleElement_title__37vpm {\n  grid-area: title;\n  font-weight: bold;\n  align-self: center;\n  font-size: 20px;\n}\n\n.TitleElement_buttons__mt4I7 {\n  grid-area: buttons;\n  justify-self: flex-end;\n}\n\n.TitleElement_content__yJOdP {\n  display: flex;\n  flex-direction: column;\n  grid-area: content;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ProductView/components/TitleElement/TitleElement.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb;;;qBAGmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".container {\n  display: grid;\n  grid-template:\n    'title buttons' 1fr\n    'content content' 1fr\n    / 1fr max-content;\n  gap: 0.5rem;\n}\n\n.title {\n  grid-area: title;\n  font-weight: bold;\n  align-self: center;\n  font-size: 20px;\n}\n\n.buttons {\n  grid-area: buttons;\n  justify-self: flex-end;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  grid-area: content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TitleElement_container__mdYzt",
	"title": "TitleElement_title__37vpm",
	"buttons": "TitleElement_buttons__mt4I7",
	"content": "TitleElement_content__yJOdP"
};
export default ___CSS_LOADER_EXPORT___;

import { useContext } from 'react';

import { AttributeDto } from '@common/models/Ozon/Category';
import { AddProductFormContext } from '@features/AddProductForm/context';
import { TextFieldProps } from '@mui/material';

interface ReturnType {
  updateValue: (value: string) => void;
  bind: TextFieldProps;
}

export const useValidationInput = (schema: AttributeDto): ReturnType => {
  const { attributeValues } = useContext(AddProductFormContext);

  const value = attributeValues.get(schema.id).at(0)?.value || '';

  const updateValue = (value: string) => {
    attributeValues.set(schema.id, [{ id: 0, value, info: '', picture: '' }]);
  };

  const handleNumeric = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      updateValue(e.target.value);
    }
  };

  let bind: TextFieldProps = {};

  if (schema.type === 'Integer') {
    bind = { value, onChange: handleNumeric };
  }

  if (schema.type === 'Decimal') {
    bind = {
      type: 'number',
      value,
      onChange: (e) => updateValue(e.target.value),
    };
  }

  if (schema.type === 'multiline') {
    bind = {
      multiline: true,
      value,
      onChange: (e) => updateValue(e.target.value),
    };
  }

  if (schema.type === 'Boolean') {
    bind = {
      value: value ? (value === '0' ? 'Нет' : 'Да') : '',
      InputProps: { readOnly: true },
    };
  }

  return { updateValue, bind };
};

import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';

import { useSuppliersStore } from '../context';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Options: React.FC = observer(() => {
  const store = useSuppliersStore();

  const isChecked = (supplierId: number) => {
    return store.selectedSupplierId === supplierId;
  };

  const selectSupplier = (supplierId: number) => {
    if (store.selectedSupplierId === supplierId) {
      store.selectSupplierId(null);
    } else {
      store.selectSupplierId(supplierId);
    }
  };

  return (
    <React.Fragment>
      {store.suppliers.map((supplier) => (
        <MenuItem
          key={supplier.id}
          value={supplier.id}
          sx={{ height: ITEM_HEIGHT }}
          onClick={() => selectSupplier(supplier.id)}
        >
          <Checkbox checked={isChecked(supplier.id)} />
          <ListItemText primary={supplier.name} />
        </MenuItem>
      ))}
    </React.Fragment>
  );
});

export const SupplierList = observer(() => {
  const store = useSuppliersStore();

  const renderSelected = (value: unknown) => {
    const supplier = store.suppliers.find(
      (x) => x.id === store.selectedSupplierId
    );

    if (supplier) return supplier.name;
  };

  return (
    <FormControl sx={{ width: 120 }}>
      <Select
        id='supplier-input'
        value={store.selectedSupplierId ?? ''}
        MenuProps={MenuProps}
        sx={{ overflowWrap: 'break-word', height: ITEM_HEIGHT }}
        renderValue={renderSelected}
      >
        <Options />
      </Select>
    </FormControl>
  );
});

import React, { FC } from 'react';

import classes from './KeyValue.module.css';

type KeyValueProps = {
  name: string;
  children?: React.ReactNode;
};

export const KeyValue: FC<KeyValueProps> = ({ name, children }) => (
  <React.Fragment>
    <div className={classes.key}>{name}</div>
    <div className={classes.value}>{children}</div>
  </React.Fragment>
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShopList_root__x7Kq0 {\n  display: flex;\n  flex-direction: column;\n  padding: 0.5rem 0;\n  background-color: white;\n  border-radius: 0.25rem;\n}\n\n.ShopList_item__9hlZT {\n  padding: 0.25rem 1rem;\n  transition: all 100ms;\n  cursor: pointer;\n  -webkit-user-select: none;\n          user-select: none;\n\n  display: flex;\n  flex-direction: column;\n}\n\n.ShopList_item__9hlZT:hover {\n  background-color: #0002;\n}\n\n.ShopList_item__9hlZT sub {\n  font-size: 10px;\n}\n\n.ShopList_selected__O\\+Evp {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/features/ClientPickLists/components/ShopList/ShopList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;;EAEjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  padding: 0.5rem 0;\n  background-color: white;\n  border-radius: 0.25rem;\n}\n\n.item {\n  padding: 0.25rem 1rem;\n  transition: all 100ms;\n  cursor: pointer;\n  user-select: none;\n\n  display: flex;\n  flex-direction: column;\n}\n\n.item:hover {\n  background-color: #0002;\n}\n\n.item sub {\n  font-size: 10px;\n}\n\n.selected {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ShopList_root__x7Kq0",
	"item": "ShopList_item__9hlZT",
	"selected": "ShopList_selected__O+Evp"
};
export default ___CSS_LOADER_EXPORT___;

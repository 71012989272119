export const getToken = () => {
  return localStorage.getItem('accessToken');
};

export const setToken = (accessToken: string) => {
  localStorage.setItem('accessToken', accessToken);
};

export const resetToken = () => {
  localStorage.removeItem('accessToken');
};

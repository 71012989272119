import { makeAutoObservable, reaction } from 'mobx';

import { CategoryDto } from '@common/models/Response/Category';

import ProductListService from '../service';
import { SuppliersStore } from './SuppliersStore';

export class CategoriesStore {
  private _categories: CategoryDto[] = [];
  private _selectedCategoryIds: number[] = [];

  private _suppliersStore: SuppliersStore;

  get categories(): CategoryDto[] {
    return this._categories;
  }

  get selectedCategoryIds(): number[] {
    return this._selectedCategoryIds;
  }

  constructor(suppliersStore: SuppliersStore) {
    this._suppliersStore = suppliersStore;

    makeAutoObservable(this);

    reaction(
      () => this._suppliersStore.selectedSupplierId,
      async (supplierId) => {
        if (supplierId === null) {
          this.setCategories([]);
          return;
        }

        await this.getCategories(supplierId);
      }
    );
  }

  setSelectedCategoryIds(value: number[]) {
    this._selectedCategoryIds = value;
  }

  setCategories(value: CategoryDto[]) {
    this._categories = value;
  }

  selectCategory(categoryId: number) {
    const categories = this.getCategoryWithChildren(categoryId);

    if (!this._selectedCategoryIds.includes(categoryId)) {
      this._selectedCategoryIds = [
        ...new Set([...this._selectedCategoryIds, ...categories]),
      ];
    } else {
      const exclude = this.getCategoryWithParents(categoryId);

      this._selectedCategoryIds = this._selectedCategoryIds.filter(
        (x) => !categories.includes(x) && !exclude.includes(x)
      );
    }
  }

  clearSelectedCategories() {
    this._selectedCategoryIds = [];
  }

  private async getCategories(supplierId: number) {
    try {
      const { data } = await ProductListService.getCategories(supplierId);

      this.setCategories(data.categories);
    } catch (error) {}
  }

  private getCategoryWithChildren(categoryId: number): number[] {
    const children = this.categories.filter((x) => x.parentId === categoryId);

    if (!children) return [categoryId];

    return [
      categoryId,
      ...children.flatMap((c) => this.getCategoryWithChildren(c.id)),
    ];
  }

  private getCategoryWithParents(categoryId: number): number[] {
    const category = this.categories.find((x) => x.id === categoryId);

    if (!category) return [];

    if (!category.parentId) return [categoryId];

    return [...this.getCategoryWithParents(category.parentId), categoryId];
  }
}

import React from 'react';
import SwipeableViews from 'react-swipeable-views';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, MobileStepper } from '@mui/material';

interface PicturesGalleryProps {
  urls?: string[];
  maxWidth?: number | string;
}

export const PicturesGallery: React.FC<PicturesGalleryProps> = ({
  urls,
  maxWidth,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);

  if (!urls || !urls.length) return null;

  const maxSteps = urls.length;

  const handleNext = () => {
    setActiveStep((prev) => {
      if (prev + 1 >= maxSteps) return 0;
      return prev + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prev) => {
      if (prev - 1 < 0) return maxSteps - 1;
      return prev - 1;
    });
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: maxWidth || 200, flexGrow: 1 }}>
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {urls.map((url, index) => (
          <div key={url}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component='img'
                sx={{
                  height: 'auto',
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={url}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        nextButton={
          <Button size='small' onClick={handleNext}>
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size='small' onClick={handleBack}>
            <KeyboardArrowLeft />
          </Button>
        }
      />
    </Box>
  );
};

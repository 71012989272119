import { AxiosResponse } from 'axios';

import { ProductFilterDto, ProductListDto } from '@common/api/Shop/Product';
import { ProductListRoutes } from '@common/constants';
import { $authApi } from '@common/http';
import { CategoryListDto } from '@common/models/Response/Category';
import { SupplierListDto } from '@common/models/Response/Supplier';

export default class ProductListService {
  static async getProducts(params: ProductFilterDto) {
    return $authApi.get<
      ProductListDto,
      AxiosResponse<ProductListDto, ProductFilterDto>,
      ProductFilterDto
    >(`${ProductListRoutes.PRODUCT_ROUTE}`, {
      params: {
        ...params,
        categoryIds:
          params.categoryIds && params.categoryIds.length
            ? params.categoryIds?.join(',')
            : undefined,
      },
    });
  }

  static async getCategories(supplierId: number) {
    return $authApi.get<CategoryListDto>(
      `${ProductListRoutes.CATEGORIES_ROUTE}/supplier/${supplierId}`
    );
  }

  static async getSuppliers() {
    return $authApi.get<SupplierListDto>(`${ProductListRoutes.SUPPLIER_ROUTE}`);
  }
}

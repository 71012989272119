import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { useAuthStore } from '@common/hooks';
import { UserStatus } from '@common/models/User';
import { Header } from '@pages/MainPage/MainPage';

import classes from './NotActivatedPage.module.css';

export const NotActivatedPage = observer(() => {
  const auth = useAuthStore();

  const status = useMemo(() => {
    switch (auth.user?.status) {
      case UserStatus.DEACTIVATED:
        return 'Ваш аккаунт деактивирован.';
      case UserStatus.NOT_ACTIVATED:
        return 'Ваш аккаунт ещё не активирован.';
    }

    return '';
  }, [auth.user?.status]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header onLogoutClick={() => auth.logout()} />
      </div>
      <div className={classes.content}>
        <h2>{status}</h2>
      </div>
    </div>
  );
});

import { makeAutoObservable } from 'mobx';

import {
  AnalyticsApiService,
  CommissionDetailsDto,
} from '@common/api/Shop/Analytics';
import { ProductApiService } from '@common/api/Shop/Product';
import {
  PriceHistoryDto,
  ProductStatusEnum,
} from '@common/models/Shop/Product';

interface ProductStatus {
  product_id: number;
  default_configuration_id: number;
  is_enabled_global: boolean;
  is_enabled_marketplace: boolean;
  quantity: number;
}

export class ProductAnalyticsStore {
  private _priceHistory: PriceHistoryDto[] = [];
  private _commissions: CommissionDetailsDto | null = null;
  private _status: ProductStatus;

  get priceHistory() {
    return this._priceHistory;
  }

  get commissions() {
    return this._commissions;
  }

  get status() {
    return this._status;
  }

  constructor() {
    this._status = {
      product_id: 0,
      default_configuration_id: 0,
      is_enabled_global: false,
      is_enabled_marketplace: false,
      quantity: 0,
    };

    makeAutoObservable(this);
  }

  setPriceHistory(value: PriceHistoryDto[]) {
    this._priceHistory = value;
  }

  setCommissions(value: CommissionDetailsDto) {
    this._commissions = value;
  }

  setStatus(value: ProductStatus) {
    this._status = value;
  }

  async fetchAnalytics(configuration_id: number) {
    await this.fetchPriceHistory(configuration_id);
    await this.fetchCommissionDetails(configuration_id);
    await this.fetchStatus(configuration_id);
  }

  async updateProductQuantity(productId: number, quantity: number) {
    try {
      await ProductApiService.setQuantity({ productId, quantity });
    } catch (error) {}
  }

  async updateProductStatus(configuration_id: number, is_enabled: boolean) {
    try {
      await ProductApiService.setStatus({
        configuration_id,
        status: is_enabled
          ? ProductStatusEnum.RECALCULATION_REQUIRED
          : ProductStatusEnum.NOT_READY_FOR_SALE,
      });
    } catch (error) {}
  }

  private async fetchPriceHistory(configuration_id: number) {
    try {
      const { data } = await AnalyticsApiService.getPriceHistory({
        configuration_id,
      });

      this.setPriceHistory(data);
    } catch (error) {}
  }

  private async fetchCommissionDetails(configuration_id: number) {
    try {
      const { data } = await AnalyticsApiService.getCommissionDetails({
        configuration_id,
      });

      this.setCommissions(data);
    } catch (error) {}
  }

  private async fetchStatus(configuration_id: number) {
    try {
      const { data: configuration } = await ProductApiService.getConfiguration(
        configuration_id
      );

      const product_id = configuration.product_id;

      const { data: defaultConfiguration } =
        await ProductApiService.getDefaultConfiguration(product_id);

      const { data: globalStatus } = await ProductApiService.getStatus(
        defaultConfiguration.id
      );

      const { data: status } = await ProductApiService.getStatus(
        configuration_id
      );

      const { data: quantity } = await ProductApiService.getQuantity(
        product_id
      );

      this.setStatus({
        product_id,
        default_configuration_id: defaultConfiguration.id,
        is_enabled_global:
          globalStatus !== ProductStatusEnum.NOT_READY_FOR_SALE,
        is_enabled_marketplace: status !== ProductStatusEnum.NOT_READY_FOR_SALE,
        quantity,
      });
    } catch (error) {}
  }
}

import { makeAutoObservable, reaction } from 'mobx';

import { ShopApiService, ShopDto } from '@common/api/Shop';

import { OzonStore } from './OzonStore';
import { YandexStore } from './YandexStore';

export enum ShopViewTabEnum {
  GENERAL,
  OZON,
  YANDEX,
  WILDBERRIES,
}

export class ShopViewStore {
  private _shop: ShopDto | null = null;
  private _currentTab: ShopViewTabEnum = ShopViewTabEnum.GENERAL;

  readonly yandex: YandexStore;
  readonly ozon: OzonStore;

  get shop() {
    return this._shop;
  }

  get currentTab() {
    return this._currentTab;
  }

  constructor() {
    this.yandex = new YandexStore();
    this.ozon = new OzonStore();

    makeAutoObservable(this);

    const marketplaceStatusUpdatedReaction = async (
      current?: boolean,
      prev?: boolean
    ) => {
      if (!this.shop || prev === undefined || current === undefined) return;

      await this.fetchShopData(this.shop.id);
    };

    reaction(
      () => this.yandex.data?.isEnabled,
      marketplaceStatusUpdatedReaction
    );

    reaction(() => this.ozon.data?.isEnabled, marketplaceStatusUpdatedReaction);
  }

  setShop(value: ShopDto) {
    this._shop = value;
  }

  setCurrentTab(value: ShopViewTabEnum) {
    this._currentTab = value;
  }

  async fetchShopData(shopId: number) {
    try {
      const { data } = await ShopApiService.getShopById(shopId);

      this.setShop(data);
    } catch (error) {}
  }

  async updateStocks(value: boolean) {
    if (!this.shop) return;

    try {
      await ShopApiService.setUpdateStocksState(this.shop.id, value);

      this.setShop({ ...this.shop, updateStocks: value });
    } catch (error) {}
  }

  async updatePrices(value: boolean) {
    if (!this.shop) return;

    try {
      await ShopApiService.setUpdatePricesState(this.shop.id, value);

      this.setShop({ ...this.shop, updatePrices: value });
    } catch (error) {}
  }

  async deleteShop() {
    if (!this.shop) return;

    try {
      await ShopApiService.deleteShop(this.shop.id);
    } catch (error) {}
  }
}

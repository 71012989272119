import React from 'react';

import { ShopListStore } from '../store';

const store = new ShopListStore();

export const ShopListContext = React.createContext<ShopListStore>(store);

type Props = {
  children?: React.ReactNode;
};

export const ContextProvider: React.FC<Props> = ({ children }) => (
  <ShopListContext.Provider value={store}>{children}</ShopListContext.Provider>
);

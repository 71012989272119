import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';

import {
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';

import classes from './AddProductForm.module.css';
import { FeaturesStep } from './components/FeaturesStep';
import { MediaStep } from './components/MediaStep';
import { ProductInfoStep } from './components/ProductInfoStep';
import { ContextProvider, AddProductFormContext } from './context';

interface StepData {
  title: string;
  render: React.ReactNode;
}

const steps: StepData[] = [
  { title: 'Информация о товаре', render: <ProductInfoStep /> },
  { title: 'Характеристики', render: <FeaturesStep /> },
  { title: 'Медиа', render: <MediaStep /> },
  { title: 'Предварительный просмотр', render: null },
];

const Container = observer(() => {
  const store = useContext(AddProductFormContext);

  const handleBackClick = () => {
    if (store.step > 0) store.setStep(store.step - 1);
  };

  const handleNextClick = () => {
    if (store.step < steps.length) store.setStep(store.step + 1);
  };

  useEffect(() => {
    const wrapper = async () => {
      await store.categories.init();
      await store.init();
    };

    wrapper();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.titleBlock}>
        <h1>Добавление товара</h1>
      </div>
      <Paper className={classes.contentBlock}>
        <div className={classes.stepperContainer}>
          <Stepper orientation='vertical' activeStep={store.step}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.title}</StepLabel>
                <StepContent></StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
        <div style={{ gridArea: 'content' }}>{steps[store.step].render}</div>
        <div className={classes.navArea}>
          {store.step !== 0 ? (
            <Button sx={{ px: 4 }} onClick={handleBackClick}>
              Назад
            </Button>
          ) : (
            <div></div>
          )}
          <Button
            sx={{ px: 4 }}
            disabled={store.step >= steps.length - 1}
            onClick={handleNextClick}
          >
            Далее
          </Button>
        </div>
      </Paper>
    </div>
  );
});

export const AddProductForm = observer(() => (
  <ContextProvider>
    <Container />
  </ContextProvider>
));

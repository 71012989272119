import { PostingListFilterStatus } from '../dto';

export const localizeStatus = (status: PostingListFilterStatus) => {
  switch (status) {
    case 'awaiting_registration':
      return 'Ожидает регистрации';
    case 'acceptance_in_progress':
      return 'Идёт приёмка';
    case 'awaiting_approve':
      return 'Ожидает подтверждения';
    case 'awaiting_packaging':
      return 'Ожидает сборки';
    case 'awaiting_deliver':
      return 'Ожидает отгрузки';
    case 'arbitration':
      return 'Арбитраж';
    case 'client_arbitration':
      return 'Клиентский арбитраж доставки';
    case 'delivering':
      return 'Доставляется';
    case 'driver_pickup':
      return 'У водителя';
    case 'delivered':
      return 'Доставлено';
    case 'cancelled':
      return 'Отменено';
    case 'not_accepted':
      return 'Не принят на сортировочном центре';
    case 'sent_by_seller':
      return 'Отправлено продавцом';
    default:
      return '';
  }
};

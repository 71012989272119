import { $authApi } from '@common/http';

import {
  YandexCredentialsDto,
  YandexShopDto,
  YandexUnlinkDto,
  YandexWarehouseDto,
} from './dto';

export class YandexShopApiService {
  static async getShopInfo(shopId: number) {
    return $authApi.get<YandexShopDto>(`/yandex/shop/${shopId}`);
  }

  static async linkToShop(dto: YandexCredentialsDto) {
    return $authApi.post(`/yandex/shop/link`, dto);
  }

  static async unlinkFromShop(dto: YandexUnlinkDto) {
    return $authApi.post(`/yandex/shop/unlink`, dto);
  }

  static async addWarehouse(dto: YandexWarehouseDto) {
    return $authApi.post(`/yandex/warehouse/add`, dto);
  }

  static async removeWarehouse(dto: YandexWarehouseDto) {
    return $authApi.post(`/yandex/warehouse/remove`, dto);
  }
}
